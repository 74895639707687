var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "map" } },
    [
      _c(
        "gmap-map",
        {
          staticClass: "gMap",
          attrs: {
            center: {
              lat: Number("" + _vm.center.lat),
              lng: Number("" + _vm.center.lng),
            },
            zoom: 15,
            options: {
              mapTypeControl: true,
              mapTypeControlOptions: { style: 3, position: 6 },
            },
          },
        },
        [
          _c("gmap-marker", {
            staticClass: "gMap__marker",
            attrs: {
              position: {
                lat: Number("" + _vm.center.lat),
                lng: Number("" + _vm.center.lng),
              },
            },
          }),
          _vm._v(" "),
          _c(
            "gmap-info-window",
            {
              staticClass: "gMap__info-window",
              attrs: {
                position: {
                  lat: Number("" + _vm.center.lat),
                  lng: Number("" + _vm.center.lng),
                },
                opened: true,
              },
            },
            [
              _c("img", {
                staticClass: "gMap__logo",
                attrs: {
                  src: _vm.$prefixLinks(_vm.program.promoterInfo.logo.main),
                  alt: "logo",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "gMap__info" }, [
                _vm._v("\n        " + _vm._s(_vm.program.title) + "\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }