var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass: "loading-container",
          attrs: { "aria-label": "loading" },
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "cube1" }),
      _vm._v(" "),
      _c("div", { staticClass: "cube2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }