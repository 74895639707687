<template>
  <div v-if="project" class="project-card">
    <h3 class="project-card__heading">
      {{ $t('projects.project') }} <span v-if="project.changeName" class=" project-card__heading project-card__heading--bold"> {{ project.changeName }}</span>
    </h3>
    <img :src="$prefixLinks(programData.background)" alt="" class="project-card__img">
    <div class="project-card__infos">
      <p v-if="project.formatted_date" class="project-card__date">
        {{ project.formatted_date }}
      </p>
    </div>
    <p v-if="project.flatId" class="project-card__lot">
      {{ $t('flat.designation') }} {{ project.flatId }}
    </p>
    <div v-if="projectLotInfos" class="project-card__desc">
      <p v-if="lotAndLayoutPrice(projectLotInfos.flatLayout) && lotAndLayoutPrice(projectLotInfos.flatLayout) > 0" class="project-card__desc__price">
        {{ lotAndLayoutPrice(projectLotInfos.flatLayout) }} {{ projectLotInfos.priceMeasurement }}
      </p>
      <p v-if="projectLotInfos.surface && projectLotInfos.surface.total && projectLotInfos.surface.total > 0" class="project-card__desc__surface">
        - {{ projectLotInfos.surface.total }} {{ projectLotInfos.surfaceMeasurement }}
      </p>
      <p v-if="projectLotInfos.flatLayout" class="project-card__desc__layout-name">
        - {{ $t('plan') }} {{ layoutName(projectLotInfos.flatLayout) }}
      </p>
    </div>
    <div class="project-card__actions">
      <button class="project-card__cta" :disabled="clientParams" :class="{'project-card__cta--disabled': clientParams, 'project-card__cta--ghost': !clientParams}" @click="deleteProject(project.subProgramId, project.flatId, project.creationDate, project.changeName)">
        <img src="/icon/icon-trash.png" alt="">
      </button>
      <button class="project-card__cta" @click="openProjectStreaming(programId, project.subProgramId, project.flatId, project.creationDate)">
        {{ $t('projects.open') }}
      </button>
    </div>
    <ProjectsDelete v-if="UI.deleteProject" :checksum="projectChecksum" :project-name="projectName" @closeWindowRequest="closeDeleteProjectUI()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import ProjectsDelete from '~/components/Projects/ProjectsDelete'
import { CUSTOMERAREA } from '~/plugins/constants'
import { getStreamingLink } from '~/plugins/streaming'
import { getLotInfosByNumber } from '~/plugins/lots'

export default {
  components: {
    ProjectsDelete,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      projectLotInfos: null,
      UI: {
        deleteProject: false,
      },
      projectChecksum: null,
      projectName: null,
    }
  },
  computed: {
    ...mapGetters({
      programData: 'program/getProgramData',
      programId: 'program/programId',
      lot: 'lot/getLot',
      linkTarget: 'options/getLinkTargetOption',
    }),
  },
  async created () {
    if (!this.project.flatId) { return }
    const { lot } = await getLotInfosByNumber(this.project.flatId, this.$store.state.lot.subPrograms)
    if (lot) {
      this.projectLotInfos = lot
    }
  },
  methods: {
    layoutName (layoutId) {
      if (!this.projectLotInfos || !layoutId) { return '' }

      for (const item in this.projectLotInfos.layouts) {
        if (layoutId === this.projectLotInfos.layouts[item].layoutId) {
          return this.projectLotInfos.layouts[item].name
        }
      }
    },
    lotAndLayoutPrice (layoutId) {
      let price = this.projectLotInfos?.price
      for (const item in this.projectLotInfos.layouts) {
        if (this.projectLotInfos.layouts[item].layoutId === layoutId) {
          price = (parseFloat(this.projectLotInfos.layouts[item].price) || 0) + parseFloat(this.projectLotInfos.price)
        }
      }
      return price
    },
    async openProjectStreaming (programId, subProgramId, lotId, creationDate) {
      const token = Cookies.get('temp-usr')
      let checksum = ''
      if (creationDate) {
        checksum = this.createHash(subProgramId, lotId, creationDate)
      }
      const { link } = await getStreamingLink({ programId, flatId: lotId, token })
      const url = new URL(link)
      url.searchParams.append('checksum', checksum)
      window.open(url, this.linkTarget)
    },
    createHash (subProgramId, lotId, date) {
      return this.$md5(subProgramId, lotId, date)
    },
    deleteProject (subProgramId, lotId, date, name) {
      this.projectChecksum = this.createHash(subProgramId, lotId, date)
      this.projectName = name
      this.UI.deleteProject = true
      document.body.classList.add('modal-open')
    },
    closeDeleteProjectUI () {
      document.body.classList.remove('modal-open')
      this.UI.deleteProject = false
    },
  },
}
</script>

<style lang="scss" scoped>
.project-card {
  border: solid $border-color 1px;
  padding: 1.4rem;
  border-radius: $border-radius;
  width: 92%;
  margin: auto;
  background-color: $bg-color;

  @media (min-width: $breakpoint-small) {
    margin: 0;
    width: 22rem;
  }

  &__date,
  &__lot,
  &__infos,
  &__desc p,
  &__heading {
    font-size: 0.8rem;
    color: $main-medium-color;
  }

  &__lot,
  &__heading {
    text-transform: uppercase;
  }

  &__heading--bold {
    font-weight: 700;
  }

  &__infos,
  &__desc {
    margin-bottom: 0.5rem;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
  }

  &__lot {
    font-weight: 700;
    color: $main-dark-color;
  }

  &__desc,
  &__img {
    margin-bottom: 0.5rem;
  }

  &__desc {
    display: flex;
    flex-wrap: wrap;

    p {
      padding-right: 4px;
    }
  }

  &__img {
    width: 100%;
    height: 9rem;
    margin: 0.4rem auto 0.7rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__cta {
    font-size: 0.7rem;
    border: solid black 1px;
    border-radius: $border-radius;
    padding: 0.3rem 0.4rem;
    background-color: var(--main-option-color);
    color: $main-light-color;
    text-transform: uppercase;
    font-weight: 700;
    transition: background-color 200ms, border 200ms, color 200ms;

    &--ghost {
      background-color: $bg-color;
      color: $main-dark-color;
      &:hover {
        background-color: var(--second-option-color);
        border: solid var(--second-option-color) 1px;
        color: $main-light-color;
        cursor: pointer;
      }
    }

    &--disabled {
      background-color: $bg-color;
      color: $main-dark-color;

      :hover {
        background-color: none;
        border: solid none 1px;
        color: $main-light-color;
        cursor: default;
      }
    }

    img {
      width: 1rem;
    }
  }
}
</style>
