<template>
  <form class="form" @submit.prevent="handleSubmit">
    <div class="gender form__field subWrapper">
      <div class="form__field-radio">
        <input id="woman" v-model="form.gender" type="radio" value="Woman" name="gender">
        <label class="label__woman" for="woman"> Madame</label>
        <input id="man" v-model="form.gender" type="radio" value="Man" name="gender">
        <label class="label__man" for="man"> Monsieur</label>
      </div>
      <p v-if="!$v.form.gender.required && $v.form.gender.$dirty" class="error-alert">
        Champ requis
      </p>
    </div>
    <div class="wrapper subWrapper">
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.lastname" for="lastname">Nom *</label>
          <input v-model="form.lastname" type="text" placeholder="Nom*" name="lastname">
        </div>
        <p v-if="!$v.form.lastname.required && $v.form.lastname.$dirty" class="error-alert">
          Nom requis
        </p>
        <p v-if="!$v.form.lastname.forbiddenCharactersAndDigit && $v.form.lastname.$dirty" class="error-alert">
          Nom valide requis
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.firstname" for="firstname">Prénom *</label>
          <input v-model="form.firstname" type="text" placeholder="Prénom*" name="firstname">
        </div>
        <p v-if="!$v.form.firstname.required && $v.form.firstname.$dirty" class="error-alert">
          Prénom requis
        </p>
        <p v-if="!$v.form.firstname.forbiddenCharactersAndDigit && $v.form.firstname.$dirty" class="error-alert">
          Prénom valide requis
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.email" for="email">Email *</label>
          <input v-model="form.email" type="email" placeholder="Email*" name="email">
        </div>
        <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error-alert">
          Email requis
        </p>
        <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error-alert">
          Email valide requis
        </p>
      </div>
    </div>
    <div class="subWrapper">
      <div class="form__field form__field-text">
        <label v-if="form.address" for="address">Adresse *</label>
        <input v-model="form.address" type="text" placeholder="Adresse*" name="address">
      </div>
      <p v-if="!$v.form.address.required && $v.form.address.$dirty" class="error-alert">
        Adresse requise
      </p>
      <p v-if="!$v.form.address.forbiddenCharacters && $v.form.address.$dirty" class="error-alert">
        Adresse valide requise
      </p>
    </div>
    <div class="wrapper subWrapper">
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.postalCode" for="postalCode">Code Postal *</label>
          <input v-model="form.postalCode" type="text" placeholder="Code Postal*" name="postalCode">
        </div>
        <p v-if="!$v.form.postalCode.required && $v.form.postalCode.$dirty" class="error-alert">
          Code postal requis
        </p>
        <p v-if="(!$v.form.postalCode.numeric || !$v.form.postalCode.minLength) && $v.form.postalCode.$dirty" class="error-alert">
          Code postal valide requis
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.city" for="city">Ville *</label>
          <input v-model="form.city" type="text" placeholder="Ville*" name="city">
        </div>
        <p v-if="!$v.form.city.required && $v.form.city.$dirty" class="error-alert">
          Ville requise
        </p>
        <p v-if="!$v.form.city.forbiddenCharactersAndDigit && $v.form.city.$dirty" class="error-alert">
          Ville valide requise
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.mobilePhone" for="phone">Téléphone mobile *</label>
          <input v-model="form.mobilePhone" type="text" placeholder="Téléphone*" name="phone">
        </div>
        <p v-if="!$v.form.mobilePhone.required && $v.form.mobilePhone.$dirty" class="error-alert">
          Téléphone requis
        </p>
        <p v-if="!$v.form.mobilePhone.numeric && $v.form.mobilePhone.$dirty" class="error-alert">
          Numéro de télephone valide requis
        </p>
        <p v-if="!$v.form.mobilePhone.minLength && $v.form.mobilePhone.numeric && $v.form.mobilePhone.$dirty" class="error-alert">
          Numéro de télephone valide requis
        </p>
      </div>
    </div>
    <div class="project form__field">
      <p class="project__title">
        Votre projet ? *
      </p>
      <div class="form__field-radio">
        <input id="live" v-model="form.projectType" type="radio" value="Inhabitant" name="projectType">
        <label class="label__live" for="live">Habiter </label>
        <input id="invest" v-model="form.projectType" type="radio" value="Investor" name="projectType">
        <label class="label__invest" for="invest">Investir</label>
      </div>
      <p v-if="!$v.form.projectType.required && $v.form.projectType.$dirty" class="error-alert">
        Champ requis
      </p>
    </div>
    <div class="terms">
      <input id="conditions" v-model="form.optin" type="checkbox" value="!form.optin" name="conditions">
      <label for="conditions">J’accepte que QUARTUS Résidentiel collecte et utilise les données
        personnelles que je viens de renseigner dans ce formulaire dans le but
        de recevoir des offres marketing personnalisées en accord avec la
        politique de protection des données. Vous pourrez, à tout moment,
        utiliser le lien de désabonnement intégré dans l’email.</label>
    </div>
    <div class="validation">
      <section class="captcha">
        <div>
          <label>{{ captcha.randomNbr1 }} + {{ captcha.randomNbr2 }} = </label>
          <input v-model="captcha.result" type="text" class="captcha__result"> ?
        </div>
        <div v-if="!$v.captcha.result.required && $v.form.mobilePhone.$dirty" class="error-alert">
          Résultat requis
        </div>
        <div v-if="!$v.captcha.result.numeric && $v.form.mobilePhone.$dirty" class="error-alert">
          Résultat valide requis
        </div>
        <div v-if="captcha.error === true" class="error-alert">
          Veuillez réessayer
        </div>
      </section>
      <p v-if="status === 'PENDING'" class="cta cta-alert--inprogress">
        Envoi en cours...
      </p>
      <p v-else-if="status === 'ERROR'" class="error-alert--center">
        {{ errorMsg }}
      </p>
      <button v-else class="cta" type="submit">
        ENVOYER
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { required, email, minLength, numeric } from 'vuelidate/lib/validators'
const forbiddenCharacters = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}"]{0,}$/.test(value)
}
const forbiddenCharactersAndDigit = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}\d"]{0,}$/.test(value)
}

export default {
  props: {
    userChoiceLocation: {
      type: String,
      default: '',
    },
    userChoiceStartDate: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      form: {
        gender: '',
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        postalCode: '',
        city: '',
        mobilePhone: '',
        projectType: '',
        optin: false,
        startDate: this.userChoiceStartDate,
        location: this.userChoiceLocation,
      },
      captcha: {
        randomNbr1: '',
        randomNbr2: '',
        result: '',
        error: false,
      },
      status: '',
      errorMsg: 'UNE ERREUR EST SURVENUE',
    }
  },
  validations: {
    form: {
      gender: { required },
      firstname: { required, forbiddenCharactersAndDigit },
      lastname: { required, forbiddenCharactersAndDigit },
      email: { required, email },
      address: { required, forbiddenCharacters },
      postalCode: { required, numeric, minLength: minLength(5) },
      city: { required, forbiddenCharactersAndDigit },
      mobilePhone: { required, numeric, minLength: minLength(10) },
      projectType: { required },
    },
    captcha: {
      result: { required, numeric },
    },
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    ...mapGetters({
      programIdQrStock: 'programQrStock/getProgramIdQrStock',
      officeIdQrStock: 'programQrStock/getofficeIdQrStock',
      programId: 'program/programId',
    }),
  },
  created () {
    this.generateCaptcha()
  },
  methods: {
    handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.isCaptchaVerificationSuccess()) {
          this.form.startDate && this.form.location ? this.sendFormToRdvApi() : this.sendFormToInfoApi()
        }
      }
    },
    sendFormToRdvApi () {
      const headers = {
        'content-type': 'application/json',
        Authorization: process.env.ANTARES_API_KEY,
      }

      const body = {
        genre: this.form.gender,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        address: this.form.address,
        postalCode: this.form.postalCode,
        city: this.form.city,
        mobilePhone: this.form.mobilePhone,
        projectType: this.form.projectType,
        optin: this.form.optin,
        startDate: this.form.startDate,
        location: this.form.location,
        programCrmId: this.programIdQrStock,
        lotCrmId: '',
        officeId: this.officeIdQrStock,
      }
      this.status = 'PENDING'

      axios
        .post('/antares/contact/rendezvous', body, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.statusOK()
            this.sendInternNotificationOfQrFormSubmission()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.errors.MobilePhone) {
              this.errorMsg = 'Le champ téléphone mobile  est invalide'
            } else {
              this.errorMsg = 'Une oun plusieurs erreurs de validation se sont produites'
            }
          }
          if (error.response.status === 404) {
            this.errorMsg = 'Une erreur est survenue'
          }
          this.status = 'Erreur'
        })
    },
    sendFormToInfoApi () {
      const headers = {
        'content-type': 'application/json',
        Authorization: process.env.ANTARES_API_KEY,
      }

      const body = {
        genre: this.form.gender,
        lastname: this.form.lastname,
        firstname: this.form.firstname,
        postalCode: this.form.postalCode,
        mobilePhone: this.form.mobilePhone,
        email: this.form.email,
        address: this.form.address,
        city: this.form.city,
        programCrmId: this.programIdQrStock,
        lotCrmId: '',
        optin: this.form.optin,
        projectType: this.form.projectType,
      }
      this.status = 'PENDING'
      axios
        .post('/antares/contact/information', body, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.statusOK()
            this.sendInternNotificationOfQrFormSubmission()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorMsg = 'Une ou plusieurs erreurs de validation se sont produites'
          }
          if (error.response.status === 404) {
            this.errorMsg = 'Une erreur est survenue'
          }
          this.status = 'Une erreur est survenue'
        })
    },
    isCaptchaVerificationSuccess () {
      const resultDone = this.captcha.randomNbr1 + this.captcha.randomNbr2
      if (parseInt(this.captcha.result) === resultDone) {
        this.captcha.error = false
        return true
      } else {
        this.captcha.error = true
        this.generateCaptcha()
        return false
      }
    },
    generateCaptcha () {
      const randomNbr1 = Math.floor(Math.random() * 10)
      const randomNbr2 = Math.floor(Math.random() * 10)
      this.captcha.randomNbr1 = randomNbr1
      this.captcha.randomNbr2 = randomNbr2
    },
    statusOK () {
      this.$emit('statusOK')
    },
    sendInternNotificationOfQrFormSubmission () {
      const body = {
        programId: this.programId,
      }
      axios.get('/moder_1_5/promoter-form-posted', body)
    },
  },
}
</script>

<style lang="scss" scoped>
.validation {
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: $breakpoint-small) {
    padding-top: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.project {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-small) {
    max-width: 33%;
  }

  &__title {
    color: $main-dark-color;
    font-weight: bolder;
  }
}

input {
  border: none;
  border-bottom: 1px solid $main-medium-color;
  color: $main-dark-color;
  font-size: $fs-text;
  padding: 2px 0;

  &[type="text"]::placeholder,
  &[type="email"]::placeholder {
    border: solid $main-dark-color 1px;
    color: $main-medium-color;
    border: 0;
    font-weight: lighter;
  }

  &[type="radio"] + label {
    color: $main-medium-color;
  }

  &[type="radio"]:checked + label {
    font-weight: 600;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

textarea {
  font-weight: bold;
  outline: none;
}
.subWrapper {
  padding-bottom: 1.6rem;
  margin-bottom: 4px;

  @media screen and (max-width: 890px) {
    padding-bottom: 1.2rem;
  }
}

.wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper > * {
  width: 27%;
  @media screen and (max-width: 890px) {
    margin: 0 0;
  }
}

.wrapper,
.form__field-text {
  display: flex;
}

.form {
  margin-top: 2rem;
  width: 100%;
  animation: revealSlidelight 400ms;

  &__field-radio {
    width: 250px;
  }

  &__field-location {
    width: auto;
  }

  &__field-location > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-radio > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-text {
    flex-direction: column;
    position: relative;
  }

  &__field-text > label {
    color: $main-medium-color;
    font-size: $fs-text;
    position: absolute;
    top: -1rem;
  }
}

@media screen and (max-width: 892px) and (orientation: portrait) {
  .wrapper {
    display: block;
  }

  .wrapper > * {
    width: 100%;
    margin: 1em 0;
  }
}

.form__field-text > label {
  animation: revealSlidelight 400ms;
}
</style>
