var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "news",
      class: { "news--read": _vm.news.read },
      on: {
        click: function ($event) {
          return _vm.$emit("newsClicked")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "news__title",
          class: { news__notification: !_vm.news.read },
        },
        [_vm._v("\n    " + _vm._s(_vm.news.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "news__date" }, [
        _vm._v("\n    " + _vm._s(_vm.news.created_at) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }