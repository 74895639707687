<template>
  <div class="visit-link" :style="{ backgroundImage: 'url(' + programBackground + ')' }">
    <div class="circle-ext" />
    <div v-if="(userFlats && userFlats.length > 1) || (userFlats && userFlats.length === 0)" type="button" class="circle-int" @click="openStreamingLinkProgram()">
      <p class="circle-int__text">
        {{ $t('cta.visitProgram') }}
      </p>
    </div>
    <div v-if="userFlats && userFlats.length === 1" class="circle-int" @click="openStreamingLinkLot()">
      <p v-if="getLotConfigurationsStatus({ programData: program, lotVisitedInfos: $store.state.lot.lots[0] }).isOneConfigEnabled" class="circle-int__text">
        {{ $t('cta.visitLotPioActivated') }}
      </p>
      <p v-else class="circle-int__text">
        {{ $t('cta.visitLot') }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { getLotConfigurationsStatus } from '~/plugins/settings'
import { getStreamingLink } from '~/plugins/streaming'

export default {
  props: {
    programBackground: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      userStatus: 'user/getUserStatus',
      programId: 'program/programId',
      linkTarget: 'options/getLinkTargetOption',
      userFlats: 'getUserFlats',
    }),
  },
  methods: {
    getLotConfigurationsStatus ({ programData, lotVisitedInfos }) {
      return getLotConfigurationsStatus({ programData, lotVisitedInfos })
    },
    async openStreamingLinkProgram () {
      const token = Cookies.get('temp-usr')
      const { link } = await getStreamingLink({ programId: this.programId, token })

      window.location.href = link
    },
    async openStreamingLinkLot () {
      const token = Cookies.get('temp-usr')
      let flatId = ''

      if (this.userFlats && this.userFlats.length > 0) {
        flatId = this.userFlats[0]
      }

      const { link } = await getStreamingLink({ programId: this.programId, flatId, token })
      window.location.href = link
    },
  },
}
</script>

<style lang="scss" scoped>
.visit-link {
  position: relative;
  background-position: center;
  background-size: cover;
  font-weight: 700;
  border-radius: 0;
  transition: font-size 699ms;
  width: 100%;

  @media (min-width: $breakpoint-large) {
    background-color: #ffffff00;
  }
}

.circle-int,
.circle-ext {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.circle-ext {
  background-color: #ffffff63;
  width: 130px;
  height: 130px;

  @media (min-width: $breakpoint-medium) {
    width: 18vw;
    height: 18vw;
    min-height: 12.9rem;
    min-width: 12.9rem;
    max-width: 28vmin;
    max-height: 28vmin;
  }
}

.circle-int {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  background-color: #ffffffb7;
  color: $main-dark-color;
  border: solid #ffffffb7 0px;
  transition: background-color 200ms 50ms, box-shadow 200ms 50ms, transform 400ms;

  @media (min-width: $breakpoint-medium) {
    width: 16vw;
    height: 16vw;
    min-height: 11rem;
    min-width: 11rem;
    max-width: 24vmin;
    max-height: 24vmin;
  }

  &__text {
    max-width: 10rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;

    @media (min-width: $breakpoint-large) {
      font-size: $fs-text;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.06);

    @media (min-width: $breakpoint-large) {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: none;
      transform: scale(1.06);
    }
  }
}
</style>
