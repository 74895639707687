var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "error-page" }, [
    _c(
      "div",
      { staticClass: "error-page__container" },
      [
        _vm.error.statusCode && _vm.error.statusCode !== "false"
          ? _c("p", { staticClass: "error-page__subtitle" }, [
              _vm._v("\n      " + _vm._s(_vm.error.statusCode) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error.message
          ? _c("h1", { staticClass: "error-page__title" }, [
              _vm._v("\n      " + _vm._s(_vm.error.message) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error.customPath
          ? _c(
              "nuxt-link",
              {
                staticClass: "error-page__link",
                attrs: { to: _vm.error.path },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("error.returnToHome")) + "\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }