var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messages" }, [
    _vm.UI.messages
      ? _c("div", { staticClass: "message-container" }, [
          _c("h2", { staticClass: "messages__title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("messages.title")) + "\n    "),
          ]),
          _vm._v(" "),
          _vm.conversationsLotIdKeys && _vm.conversationsLotIdKeys.length > 0
            ? _c("div", { staticClass: "lot-selector" }, [
                _c(
                  "span",
                  {
                    staticClass: "lot-selector__label",
                    attrs: { for: "lotnumber" },
                  },
                  [_vm._v(" LOT : ")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lotSelected,
                        expression: "lotSelected",
                      },
                    ],
                    staticClass: "lot-selector__select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.lotSelected = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.conversationsLotIdKeys, function (item, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        staticClass: "lot-selector__options",
                        domProps: { value: item },
                      },
                      [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.userMessages && Object.keys(_vm.userMessages).length
            ? _c(
                "section",
                { staticClass: "messages__box" },
                _vm._l(_vm.userMessages, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "message-item",
                      class: {
                        "message-item--unread": !item[item.length - 1].read,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.loadMessageInbox(
                            index,
                            item[item.length - 1].id,
                            item[item.length - 1].read
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "message-item__date",
                          class: {
                            "message-item__notification":
                              !item[item.length - 1].read,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item[item.length - 1].time) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "message-item__heading",
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item[item.length - 1].subject) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "messages__box messages__box--empty" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("messages.noMessages")) + "\n    "
                ),
              ]),
          _vm._v(" "),
          _c("section", { staticClass: "messages__contact" }, [
            _c("h2", { staticClass: "messages__subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("messages.form.title")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "contact",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.StartConversation()
                  },
                },
              },
              [
                _c("div", { staticClass: "contact__heading" }, [
                  _c(
                    "label",
                    { staticClass: "contact__label", attrs: { for: "" } },
                    [_vm._v(_vm._s(_vm.$t("messages.form.subject")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.subject,
                        expression: "form.subject",
                      },
                    ],
                    staticClass: "contact__input",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("messages.form.placeholderObject"),
                    },
                    domProps: { value: _vm.form.subject },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "subject", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                !_vm.$v.form.subject.textAreaForbiddenCharacters &&
                _vm.$v.form.subject.$dirty
                  ? _c("p", { staticClass: "error-alert" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("errors.forbiddenCharacters")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.subject.required && _vm.$v.form.subject.$dirty
                  ? _c("p", { staticClass: "error-alert" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("messages.form.required")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.message,
                      expression: "form.message",
                    },
                  ],
                  staticClass: "contact__textarea",
                  attrs: {
                    placeholder: _vm.$t("messages.form.placeholderInput"),
                    rows: "10",
                  },
                  domProps: { value: _vm.form.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "message", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                !_vm.$v.form.message.textAreaForbiddenCharacters &&
                _vm.$v.form.message.$dirty
                  ? _c("p", { staticClass: "error-alert" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("errors.forbiddenCharacters")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.message.required && _vm.$v.form.message.$dirty
                  ? _c("p", { staticClass: "error-alert" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("messages.form.required")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.submit.inprogress
                  ? _c(
                      "div",
                      {
                        staticClass: "cta cta-alert--inprogress",
                        attrs: { disabled: "" },
                      },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("messages.form.inProgress"))),
                        ]),
                      ]
                    )
                  : _vm.submit.success
                  ? _c("div", { staticClass: "cta cta-alert--succes" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("messages.form.succes")) +
                          "\n        "
                      ),
                    ])
                  : _c("div", [
                      _vm.UsersInfosExists && _vm.userStatus === "acquirer"
                        ? _c(
                            "button",
                            {
                              staticClass: "cta",
                              class: { "cta--disabled": _vm.clientParams },
                              attrs: { disabled: _vm.clientParams },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("messages.form.action")) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("messages.form.noUserInfos")) +
                                "\n          "
                            ),
                          ]),
                    ]),
                _vm._v(" "),
                _vm.submit.error
                  ? _c("p", { staticClass: "error-alert" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.submit.error) + "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.UI.inbox
      ? _c("div", { staticClass: "message-container" }, [
          _c("h2", { staticClass: "messages__title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("messages.title")) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "messages__return",
              on: {
                click: function ($event) {
                  _vm.resetUI()
                  _vm.UI.messages = true
                },
              },
            },
            [
              _c("img", {
                attrs: { src: "/icon/icon-return.png", alt: "icon" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "conversation__heading" }, [
            _c("p", { staticClass: "conversation__title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.messageInbox[0].subject) + "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "conversation" }, [
            _c(
              "div",
              { staticClass: "conversation__content" },
              _vm._l(_vm.messageInbox, function (msg, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "conversation-msg",
                    class: {
                      "conversation-msg--right": _vm.buyer.includes(
                        msg.sender_id
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "conversation-msg__heading",
                        class: {
                          "conversation-msg__heading--right":
                            _vm.buyer.includes(msg.sender_id),
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "conversation-msg__icon",
                          class: {
                            "conversation-msg__icon--right": _vm.buyer.includes(
                              msg.sender_id
                            ),
                          },
                          attrs: {
                            src: "/icon/icon-user-black.png",
                            alt: "user icon",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "conversation-msg__timestamp" },
                          [
                            _c("p", [_vm._v(_vm._s(msg.sender_name))]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(msg.time))]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "conversation-msg__content",
                      class: {
                        "conversation-msg__content--right": _vm.buyer.includes(
                          msg.sender_id
                        ),
                      },
                      domProps: { innerHTML: _vm._s(msg.content) },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "conversation-answer",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.replyToAMessage()
                },
              },
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formReply,
                    expression: "formReply",
                  },
                ],
                staticClass: "contact__textarea",
                attrs: {
                  placeholder: _vm.$t("messages.form.placeholderInput"),
                  rows: "3",
                },
                domProps: { value: _vm.formReply },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.formReply = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              !_vm.$v.formReply.textAreaForbiddenCharacters &&
              _vm.$v.formReply.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("errors.forbiddenCharacters")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.formReply.required && _vm.$v.formReply.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("messages.form.required")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.submit.inprogress
                ? _c(
                    "div",
                    {
                      staticClass: "cta cta-alert--inprogress",
                      attrs: { disabled: "" },
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("messages.form.inProgress"))),
                      ]),
                    ]
                  )
                : _vm.submit.success
                ? _c("div", { staticClass: "cta cta-alert--succes" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("messages.form.succes")) +
                        "\n      "
                    ),
                  ])
                : _c("div", [
                    _vm.UsersInfosExists && _vm.userStatus === "acquirer"
                      ? _c(
                          "button",
                          {
                            staticClass: "cta",
                            class: { "cta--disabled": _vm.clientParams },
                            attrs: { disabled: _vm.clientParams },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("messages.form.action")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c("p", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("messages.form.noUserInfos")) +
                              "\n        "
                          ),
                        ]),
                  ]),
              _vm._v(" "),
              _vm.submit.error
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.submit.error) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }