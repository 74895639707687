import { render, staticRenderFns } from "./UserInfos.vue?vue&type=template&id=e7de0b9e&scoped=true"
import script from "./UserInfos.vue?vue&type=script&lang=js"
export * from "./UserInfos.vue?vue&type=script&lang=js"
import style0 from "./UserInfos.vue?vue&type=style&index=0&id=e7de0b9e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7de0b9e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e7de0b9e')) {
      api.createRecord('e7de0b9e', component.options)
    } else {
      api.reload('e7de0b9e', component.options)
    }
    module.hot.accept("./UserInfos.vue?vue&type=template&id=e7de0b9e&scoped=true", function () {
      api.rerender('e7de0b9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/User/UserInfos.vue"
export default component.exports