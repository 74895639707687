<template>
  <section class="cookie-banner-panel">
    <UiBtnClose class="cookie-banner-panel__close-cta" @BtnCloseClicked="emitClosePanel()" />
    <h2 class="cookie-banner-panel__title">
      {{ $t('cookie.panel.title') }}
    </h2>
    <p class="cookie-banner-panel__subtitle">
      {{ $t('cookie.panel.subtitle') }}
    </p>
    <div class="cookie-banner-panel-settings">
      <div class="cookie-banner-panel-settings__card">
        <h3 class="cookie-banner-panel-settings__title">
          {{ $t('cookie.panel.card1Title') }}
        </h3>
        <div class="cookie-banner-panel-settings__content">
          <p class="cookie-banner-panel-settings__text">
            {{ $t('cookie.panel.card1Text') }}
          </p>
        </div>
      </div>
      <div class="cookie-banner-panel-settings__card">
        <h3 class="cookie-banner-panel-settings__title">
          {{ $t('cookie.panel.card2Title') }}
        </h3>
        <div class="cookie-banner-panel-settings__content">
          <p class="cookie-banner-panel-settings__text">
            {{ $t('cookie.panel.card2Text') }}
          </p>
          <div>
            <button class="cookie-banner-panel-settings__cta" :class="{'cookie-banner-panel-settings__cta--authorised-active': cookies.settings === '&gtag=true'}" @click="setGtagCookie('&gtag=true')">
              {{ $t('cookie.accept') }}
            </button>
            <button class="cookie-banner-panel-settings__cta" :class="{'cookie-banner-panel-settings__cta--refused-active': cookies.settings === '&gtag=false'}" @click="setGtagCookie('&gtag=false')">
              {{ $t('cookie.refuse') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from 'js-cookie'
import UiBtnClose from '@/components/UI/UiBtnClose.vue'

export default {
  components: {
    UiBtnClose,
  },
  data () {
    return {
      cookies: {
        settings: null,
      },
    }
  },
  mounted () {
    this.getCookieschoices()
  },
  methods: {
    emitClosePanel () {
      this.$emit('emittedClosePanel')
    },
    setGtagCookie (value) {
      Cookies.set('moderapp_settings', value, { expires: 360 })
      this.getCookieschoices()
    },
    getCookieschoices () {
      this.cookies.settings = Cookies.get('moderapp_settings') || null
    },
  },
}
</script>

<style lang="scss" scoped>
.cookie-banner-panel {
  background-color: $main-light-color;
  max-width: 1200px;
  max-height: 100%;
  margin: auto;
  overflow-y: auto;
  position: relative;

  &__close-cta {
    text-align: right;
    padding: 1rem;
    font-weight: 700;

    &:hover {
      cursor: pointer;
      color: $main-light-color;
      background-color: var(--second-option-color);
    }
  }

  &__title {
    text-align: center;
    color: var(--second-option-color);
    font-weight: 700;
    font-size: $fs-title;
    margin: 1rem auto;
  }

  &__subtitle {
    max-width: 900px;
    text-align: center;
    color: $main-dark-color;
    font-size: $fs-text;
    margin: 1rem auto;
  }
}

.cookie-banner-panel-settings {
  background-color: var(--main-option-color);
  color: white;

  &__card {
    padding: 2rem;
    border-bottom: solid $main-medium-color 1px;
  }

  &__title {
    font-weight: 700;
    font-size: 1.2rem;
  }

  &__cta {
    background-color: $main-light-color;
    color: $main-dark-color;
    border-radius: $border-radius;
    padding: 0.4rem 1rem;
    min-width: 8rem;
    text-align: center;

    &--refused-active {
      background-color: red;
      color: $main-light-color;
    }

    &--authorised-active {
      background-color: green;
      color: $main-light-color;
    }

    @media (min-width: $breakpoint-large) {
      margin-left: 0.4rem;
    }
  }

  &__content {
    @media (min-width: $breakpoint-large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
</style>
