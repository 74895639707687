var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _vm.submitStatus === "SENT"
        ? _c("p", { staticClass: "shareProject shareProject--confirmation" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("shareProject.success")) + "\n  "),
          ])
        : _c(
            "div",
            { staticClass: "shareProject" },
            [
              _c("UiBtnClose", {
                on: {
                  BtnCloseClicked: function ($event) {
                    return _vm.$emit("closeWindowRequest")
                  },
                },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "sharepPoject__title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("shareProject.title")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "shareProject__form",
                  attrs: { action: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", [
                    _vm.form.email
                      ? _c("label", { attrs: { for: "email" } }, [
                          _vm._v(_vm._s(_vm.$t("shareProject.email"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email,
                          expression: "form.email",
                        },
                      ],
                      attrs: {
                        type: "email",
                        placeholder: _vm.$t("shareProject.email"),
                        name: "email",
                      },
                      domProps: { value: _vm.form.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  !_vm.$v.form.email.required && _vm.$v.form.email.$dirty
                    ? _c("p", { staticClass: "error" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("shareProject.error.emailRequired")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.form.email.email && _vm.$v.form.email.$dirty
                    ? _c("p", { staticClass: "error" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("shareProject.error.emailInvalid")) +
                            ">\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Cta", {
                    staticClass: "shareProject__cta",
                    attrs: { text: _vm.$t("shareProject.cta") },
                  }),
                  _vm._v(" "),
                  _vm.submitStatus === "PENDING"
                    ? _c("p", { staticClass: "msg--pending" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("shareProject.sending")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.submitStatus === "ERROR"
                    ? _c("p", { staticClass: "msg--error" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("shareProject.error.msg")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }