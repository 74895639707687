export default (app, inject) => {
  const prefixLinks = (link) => {
    if (process.client) {
      const ftp = (app.store.getters.isOnline ? process.env.FTP : process.env.LOCAL_IMAGES) || ''
      const prefixes = [
        'furniture/',
        'pdf/',
        'products/',
        'program/',
        'promoters/',
        'screenshot/',
        'streaming/',
        'users/',
      ]
      link = app.store.getters.isOnline ? link : `${ftp}/${link.replace('//re7ftp.moderlab.com/v2/', '')}`
      return link && prefixes.includes(link.split('/')[0] + '/') ? `${ftp}/${link}` : link
    }
  }
  inject('prefixLinks', prefixLinks)
}
