export function getLotInfosByNumber (lotNumber, subPrograms) {
  if (!lotNumber || !subPrograms) { return '' }

  return new Promise((resolve, reject) => {
    subPrograms.forEach((subProgram) => {
      subProgram.freeFlats.forEach((lot) => {
        if (lot.flatNumber === lotNumber) {
          resolve({
            lot,
            typology: subProgram.typology,
            type: subProgram.type,
            subProgramId: subProgram.subProgramId,
          })
        }
      })
    })
    resolve('')
  })
}
