import { render, staticRenderFns } from "./GalleryFullscreenZoom.vue?vue&type=template&id=85e85390&scoped=true"
import script from "./GalleryFullscreenZoom.vue?vue&type=script&lang=js"
export * from "./GalleryFullscreenZoom.vue?vue&type=script&lang=js"
import style0 from "./GalleryFullscreenZoom.vue?vue&type=style&index=0&id=85e85390&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e85390",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('85e85390')) {
      api.createRecord('85e85390', component.options)
    } else {
      api.reload('85e85390', component.options)
    }
    module.hot.accept("./GalleryFullscreenZoom.vue?vue&type=template&id=85e85390&scoped=true", function () {
      api.rerender('85e85390', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Gallery/GalleryFullscreenZoom.vue"
export default component.exports