<template>
  <div class="construction">
    <h2 class="construction__title">
      {{ $t('construction.title') }}
    </h2>
    <div v-if="program" class="construction__content">
      <div v-if="news || program.constructionSiteImage" class="construction-wrapper__left">
        <section class="construction-news">
          <h3 class="construction-news__heading">
            {{ $t('construction.news') }}
          </h3>
          <ul v-if="news && news.postData.length > 0" class="construction-news__list">
            <News v-for="(item, index) in news.postData" :key="index" :news="item" @newsClicked="openNews(index, item.id, item.read)" />
            <NewsFullscreen v-if="UI.newsOpened" :news="news.postData[newsReader.index]" @closeModalRequest="closeNews()" />
          </ul>
          <p v-else class="construction-news--empty">
            {{ $t('construction.noInformations') }}
          </p>
        </section>
        <section v-if="program.constructionSiteImage && program.constructionSiteImage.length > 0" class="construction-visual">
          <h3 class="construction-visual__heading">
            {{ $t('construction.photos') }}
          </h3>
          <div class="construction-visual__content">
            <img
              v-for="(item, index) in program.constructionSiteImage.map(link => $prefixLinks(link))"
              :key="index"
              :src="item"
              alt="construction images"
              class="construction-visual__images"
              @click="fullscreenGallery(index)"
            >
          </div>
          <GalleryFullscreenZoom v-if="UI.galleryFullscreen" :gallery="gallery" :gallery-index="galleryFullscreenIndex" @BtnCloseClicked="closeGallery" @clickOutside="closeGallery" />
        </section>
      </div>
      <section v-if="program.timeline_construction_site && program.timeline_construction_site.length > 0" class="construction-timeline">
        <h3 class="construction-timeline__heading">
          {{ $t('construction.timeline') }}
        </h3>
        <div class="timeline">
          <ul class="timeline__box">
            <li v-for="(item, index) in program.timeline_construction_site" :key="index" class="timeline__item" :class="{'timeline__item--checked': item.done === true}">
              <div>
                <p class="timeline__heading">
                  {{ item.title }}
                </p>
                <p class="timeline__subtitle">
                  {{ item.description }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <div v-else>
      <p>{{ $t('construction.noInformations') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cookies from 'js-cookie'
import News from '~/components/News/News'
import { getHeaders } from '~/plugins/headers'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  components: {
    News,
  },
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      UI: {
        galleryFullscreen: false,
        newsOpened: false,
      },
      galleryFullscreenIndex: 0,
      gallery: [],
      newsReader: {
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      news: 'news/getProgramNews',
    }),
  },
  methods: {
    ...mapActions({
      setNewsStatusRead: 'news/setNewsStatusRead',
      loadProgramNews: 'news/loadProgramNews',
    }),
    fullscreenGallery (index) {
      this.gallery = []
      for (const item in this.program.constructionSiteImage) {
        this.gallery.push(
          this.$prefixLinks(this.program.constructionSiteImage[item])
        )
      }
      this.UI.galleryFullscreen = !this.UI.galleryFullscreen
      this.galleryFullscreenIndex = index.toString()
    },
    closeGallery () {
      this.UI.galleryFullscreen = false
    },
    openNews (index, id, isNewsStatusRead) {
      const token = Cookies.get('temp-usr')

      this.newsReader.index = index
      this.UI.newsOpened = true
      document.body.classList.add('modal-open')
      if (!isNewsStatusRead && !this.clientParams) {
        this.setNewsStatusRead({ newsId: id, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          .then(() => {
            this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          })
      }
    },
    closeNews () {
      this.UI.newsOpened = false
      document.body.classList.remove('modal-open')
    },
  },
}
</script>

<style lang="scss" scoped>
.construction {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;

  @media (min-width: $breakpoint-medium) {
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 3rem 3rem;
  }

  &__title {
    @extend %ext-title;
  }

  &__content {
    @media (min-width: $breakpoint-large) {
      display: flex;
    }
  }
}

.construction-wrapper {
  &__left {
    width: 100%;

    @media (min-width: $breakpoint-large) {
      width: 50%;
    }
  }
}

.construction-news {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: darken($bg-color, 10%);
    -webkit-box-shadow: 0 0 1px $bg-color;
  }

  &__list {
    padding: 0.4rem 1rem;
    max-height: 20rem;
    overflow-y: scroll;
  }

  &__list :first-child {
    border-top: none;
  }
}

.construction-visual {
  margin-bottom: 2rem;

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__images {
    width: 100%;
    height: auto;
    max-height: 8rem;
    object-fit: cover;
  }
}

.construction-timeline {
  margin-bottom: 1rem;
  width: 100%;

  @media (min-width: $breakpoint-large) {
    width: 50%;
  }
}

.construction-news,
.construction-visual,
.construction-timeline {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;

  margin-bottom: 2rem;

  @media (min-width: $breakpoint-large) {
    margin-right: 1.4rem;
  }

  &__heading {
    @extend %ext-subTitle;
  }
}
</style>
