export function getSettingsByLot (settingsSrc, programData, lotVisitedInfos) {
  if (!programData || !lotVisitedInfos || !programData?.[settingsSrc]?.enabled) {
    return false
  }

  const optionSettings = settingsSrc === 'configuratorSetting'
    ? programData[settingsSrc].pio
    : programData[settingsSrc]

  if ((optionSettings?.configBy === 'flat') || (optionSettings?.modulabBy === 'flat') || (optionSettings?.designBy === 'flat')) {
    return !!(lotVisitedInfos.status === optionSettings.flatStatus)
  }

  if ((optionSettings?.configBy === 'date') || (optionSettings?.modulabBy === 'date') || (optionSettings?.designBy === 'date')) {
    const date = new Date()
    let dd = date.getDate()
    let mm = date.getMonth() + 1
    const yyyy = date.getFullYear()
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }

    const formattedToday = `${yyyy}-${mm}-${dd}`
    const formattedStartDate = optionSettings.startedAt.replace('/', '-').replace('/', '-').split('-').reverse().join('-')
    const formattedEndDate = optionSettings.endedAt.replace('/', '-').replace('/', '-').split('-').reverse().join('-')

    const endDate = new Date(formattedEndDate).getTime()
    const startDate = new Date(formattedStartDate).getTime()
    const compareDate = new Date(formattedToday).getTime()
    return (startDate <= compareDate) && (compareDate <= endDate)
  }
  return false
}

export function getLotConfigurationsStatus ({ programData, lotVisitedInfos }) {
  const isLayoutConfigActive = getSettingsByLot('modulabSetting', programData, lotVisitedInfos)
  const isEquipmentConfigActive = getSettingsByLot('equipmentSetting', programData, lotVisitedInfos)
  const isDecorativeConfigActive = getSettingsByLot('configuratorSetting', programData, lotVisitedInfos)
  const configs = {
    isOneConfigEnabled: isLayoutConfigActive || isEquipmentConfigActive || isDecorativeConfigActive,
    isLayoutEnabled: isLayoutConfigActive,
    isEquipmentEnabled: isEquipmentConfigActive,
    isDecorativeEnabled: isDecorativeConfigActive,
  }

  return configs
}
