
export const state = () => ({
  status: 'prospect',
  role: 'buyer',
})

export const mutations = {
  SET_USER_STATUS: (state, status) => {
    if (status) {
      state.status = status
    }
  },
  SET_USER_ROLE: (state, status) => {
    if (status) {
      state.role = status
    }
  },
  RESET_USER_STATUS_ROLE: (state) => {
    state.status = 'prospect'
    state.role = 'buyer'
  },
}

export const getters = {
  lang: (state) => {
    return state.lang
  },
  getUserStatus (state) {
    return state.status
  },
  getUserRole (state) {
    return state.role
  },
}
