<template>
  <section v-if="commercialInfos && commercialInfos.length > 0" class="contact-card">
    <div class="contact-card__content">
      <p class="contact-card__name">
        {{ commercialInfos[0].commercial_firstname }} {{ commercialInfos[0].commercial_lastname }}
      </p>
      <div v-if="commercialInfos[0].sales_address" class="contact-card__address">
        <p>{{ commercialInfos[0].sales_address }}</p>
      </div>
      <nuxt-link v-if="linkActive" :to="{ name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }" class="contact-card__link" @click="linkClicked()">
        {{ $t('contact.action') }}
      </nuxt-link>
    </div>
  </section>
  <div v-else class="contact-card--empty" />
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  name: 'ContactCard',
  props: {
    contact: {
      type: Array,
      default: null,
    },
    linkActive: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
    }
  },
  computed: {
    ...mapGetters({
      commercialInfos: 'program/commercialInfos',
    }),
  },
  methods: {
    linkClicked () {
      this.$emit('cardLinkClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-card {
  text-align: center;
  background-color: $main-light-color;
  width: 20rem;
  max-height: 20rem;
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem 1rem;

  &__name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $fs-text;
  }

  &__phone,
  &__address {
    padding-bottom: 1rem;
    color: $main-medium-color;
  }

  &__link {
    transition: color 200ms;
    color: var(--second-option-color);

    &:hover {
      text-decoration: underline;
    }
  }

  &--empty {
    @media (min-width: $breakpoint-medium) {
      height: 2rem;
    }
  }
}
</style>
