var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _vm.submitStatus === "SENT"
        ? _c(
            "p",
            { staticClass: "deleteProject deleteProject--confirmation" },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("deleteProject.success")) + "\n  "
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "deleteProject" },
            [
              _c("UiBtnClose", {
                on: {
                  BtnCloseClicked: function ($event) {
                    return _vm.$emit("closeWindowRequest")
                  },
                },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "deleteProject__title" }, [
                _vm._v("\n      " + _vm._s(_vm.$t("deleteProject.text")) + " "),
                _c("br"),
                _c("b", [_vm._v(_vm._s(_vm.projectName.toUpperCase()))]),
                _vm._v(
                  " " + _vm._s(_vm.$t("deleteProject.question")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "deleteProject__cta" },
                [
                  _c("CtaGhost", {
                    attrs: { text: _vm.$t("deleteProject.cta.no") },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeWindowRequest()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("Cta", {
                    attrs: { text: _vm.$t("deleteProject.cta.yes") },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.deleteProject()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.submitStatus === "PENDING"
                ? _c(
                    "p",
                    { staticClass: "msg--pending deleteProject__message" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("deleteProject.sending")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.submitStatus === "ERROR"
                ? _c(
                    "p",
                    { staticClass: "msg--pending deleteProject__message" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("deleteProject.error")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }