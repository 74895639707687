var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "cta-active",
      class: {
        "cta-active--hover": _vm.stayHovered,
        "cta-active--important": _vm.important,
        "cta-active--mobile": _vm.isMobile,
      },
      on: {
        click: function ($event) {
          return _vm.emitAction()
        },
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }