var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "recoveryContainer" }, [
    _vm.resetPasswordStatus === "SUCCES"
      ? _c("div", { staticClass: "recoverySucces" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("pwdRecovery.recoverySucces")))]),
        ])
      : _c("div", [
          _c("div", { staticClass: "recoverySteps" }, [
            _c(
              "div",
              {
                staticClass: "recoverySteps__title",
                class: { recoveryStepsSelected: _vm.recoveryStep === "FIRST" },
                on: {
                  click: function ($event) {
                    return _vm.setRecoveryStep("FIRST")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pwdRecovery.firstStep")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "recoverySteps__title",
                class: { recoveryStepsSelected: _vm.recoveryStep === "SECOND" },
                on: {
                  click: function ($event) {
                    return _vm.setRecoveryStep("SECOND")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pwdRecovery.secondStep")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.recoveryStep === "FIRST"
            ? _c("div", [
                _c("h3", { staticClass: "recoveryContainer__title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pwdRecovery.firstStepTitle")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "recoveryForm",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleResetPassword.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "recoveryForm__field" }, [
                      _vm.formReset.email
                        ? _c("label", { attrs: { for: "username" } }, [
                            _vm._v(_vm._s(_vm.$t("pwdRecovery.username"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formReset.email,
                            expression: "formReset.email",
                          },
                        ],
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("pwdRecovery.username"),
                        },
                        domProps: { value: _vm.formReset.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formReset,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    !_vm.$v.formReset.email.required &&
                    _vm.$v.formReset.email.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.usernameRequired")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formReset.email.email &&
                    _vm.$v.formReset.email.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "pwdRecovery.error.usernameValidRequired"
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      { staticClass: "cta--light", attrs: { type: "submit" } },
                      [_vm._v("\n          Réinitialiser\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "recoveryForm__link",
                        on: {
                          click: function ($event) {
                            return _vm.sendUiRequest("LOGIN")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("pwdRecovery.return")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.recoveryCodeSent === "ERROR"
                      ? _c(
                          "p",
                          { staticClass: "alert-error alert-error-center" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("pwdRecovery.error.EmailUnknow")
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.recoveryStep === "SECOND"
            ? _c("div", [
                _c("h3", { staticClass: "recoveryContainer__title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pwdRecovery.secondStepTitle")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.recoveryCodeSent === "SENT"
                  ? _c("p", { staticClass: "recoveryContainer__subTitle" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pwdRecovery.recoveryCodeSent")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "recoveryForm",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSetNewPassword.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "recoveryForm__field" }, [
                      _vm.form.email
                        ? _c("label", { attrs: { for: "email" } }, [
                            _vm._v(_vm._s(_vm.$t("pwdRecovery.username"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("pwdRecovery.username"),
                        },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    !_vm.$v.form.email.required && _vm.$v.form.email.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.usernameRequired")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.form.email.email && _vm.$v.form.email.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "pwdRecovery.error.usernameValidRequired"
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "recoveryForm__field" }, [
                      _vm.form.password
                        ? _c("label", { attrs: { for: "password" } }, [
                            _vm._v(_vm._s(_vm.$t("pwdRecovery.newPassword"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.password && _vm.passwordFieldType === "text"
                        ? _c("img", {
                            staticClass: "togglePassword",
                            attrs: {
                              srcset: "/icon/eyeOpened.svg",
                              src: "/icon/eyeOpened.png",
                              alt: "eye",
                            },
                            on: { click: _vm.passwordToggle },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.password && _vm.passwordFieldType === "password"
                        ? _c("img", {
                            staticClass: "togglePassword",
                            attrs: {
                              srcset: "/icon/eyeClosed.svg",
                              src: "/icon/eyeClosed.png",
                              alt: "eye",
                            },
                            on: { click: _vm.passwordToggle },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.passwordFieldType === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.password,
                                expression: "form.password",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t("pwdRecovery.newPassword"),
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.form.password)
                                ? _vm._i(_vm.form.password, null) > -1
                                : _vm.form.password,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "password",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "password",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "password", $$c)
                                }
                              },
                            },
                          })
                        : _vm.passwordFieldType === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.password,
                                expression: "form.password",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t("pwdRecovery.newPassword"),
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(_vm.form.password, null),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.form, "password", null)
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.password,
                                expression: "form.password",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t("pwdRecovery.newPassword"),
                              type: _vm.passwordFieldType,
                            },
                            domProps: { value: _vm.form.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "password",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                    ]),
                    _vm._v(" "),
                    !_vm.$v.form.password.required &&
                    _vm.$v.form.password.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.passwordRequired")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.form.password.minLength &&
                    _vm.$v.form.password.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.passwordLength")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "recoveryForm__field" }, [
                      _vm.form.passwordConfirmation
                        ? _c(
                            "label",
                            { attrs: { for: "passwordConfirmation" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("pwdRecovery.confirmPassword"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.passwordConfirmation &&
                      _vm.passwordConfirmationFieldType === "text"
                        ? _c("img", {
                            staticClass: "togglePassword",
                            attrs: {
                              srcset: "/icon/eyeOpened.svg",
                              src: "/icon/eyeOpened.png",
                              alt: "eye",
                            },
                            on: { click: _vm.passwordConfirmationToggle },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.passwordConfirmation &&
                      _vm.passwordConfirmationFieldType === "password"
                        ? _c("img", {
                            staticClass: "togglePassword",
                            attrs: {
                              srcset: "/icon/eyeClosed.svg",
                              src: "/icon/eyeClosed.png",
                              alt: "eye",
                            },
                            on: { click: _vm.passwordConfirmationToggle },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.passwordConfirmationFieldType === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.passwordConfirmation,
                                expression: "form.passwordConfirmation",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t(
                                "pwdRecovery.confirmPassword"
                              ),
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.passwordConfirmation
                              )
                                ? _vm._i(_vm.form.passwordConfirmation, null) >
                                  -1
                                : _vm.form.passwordConfirmation,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.passwordConfirmation,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "passwordConfirmation",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "passwordConfirmation",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.form,
                                    "passwordConfirmation",
                                    $$c
                                  )
                                }
                              },
                            },
                          })
                        : _vm.passwordConfirmationFieldType === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.passwordConfirmation,
                                expression: "form.passwordConfirmation",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t(
                                "pwdRecovery.confirmPassword"
                              ),
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.form.passwordConfirmation,
                                null
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "passwordConfirmation",
                                  null
                                )
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.passwordConfirmation,
                                expression: "form.passwordConfirmation",
                              },
                            ],
                            attrs: {
                              autocapitalize: "off",
                              placeholder: _vm.$t(
                                "pwdRecovery.confirmPassword"
                              ),
                              type: _vm.passwordConfirmationFieldType,
                            },
                            domProps: { value: _vm.form.passwordConfirmation },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "passwordConfirmation",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                    ]),
                    _vm._v(" "),
                    !_vm.$v.form.passwordConfirmation.required &&
                    _vm.$v.form.passwordConfirmation.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.passwordRequired")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.form.passwordConfirmation.sameAsPassword &&
                    _vm.$v.form.passwordConfirmation.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.noPasswordMatch")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "recoveryForm__field" }, [
                      _vm.form.code
                        ? _c("label", { attrs: { for: "code" } }, [
                            _vm._v(_vm._s(_vm.$t("pwdRecovery.code"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.code,
                            expression: "form.code",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("pwdRecovery.code"),
                        },
                        domProps: { value: _vm.form.code },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "code", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    !_vm.$v.form.code.required && _vm.$v.form.code.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("pwdRecovery.error.codeRequired")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    (!_vm.$v.form.code.minLength ||
                      !_vm.$v.form.code.maxLength) &&
                    _vm.$v.form.code.$dirty
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("pwdRecovery.error.codeValidRequired")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      { staticClass: "cta--light", attrs: { type: "submit" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("pwdRecovery.modify")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.resetPasswordStatus === "ERROR"
                      ? _c("p", { staticClass: "error-alert" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("pwdRecovery.error.codeError")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }