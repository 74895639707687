var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-return",
      on: {
        click: function ($event) {
          return _vm.$emit("emitClickBtnReturn")
        },
      },
    },
    [_c("img", { attrs: { src: "/icon/icon-return.png", alt: "icon" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }