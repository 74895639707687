var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$isInIframe()
    ? _c(
        "div",
        { staticClass: "cookie-banner-container" },
        [
          _vm.UI.banner && !_vm.cookies.settings
            ? _c("section", { staticClass: "cookie-banner" }, [
                _c("h3", { staticClass: "cookie-banner__title" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("cookie.banner.title")) +
                      "\n    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cookie-banner__content-wrapper" }, [
                  _c("div", [
                    _c("p", { staticClass: "cookie-banner__text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cookie.banner.text1")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "cookie-banner__text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cookie.banner.text2")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "cookie-banner__text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cookie.banner.text3")) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cookie-banner__cta-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "cta cta--option cookie-banner__cta ",
                        on: {
                          click: function ($event) {
                            return _vm.acceptAllCookies()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cookie.acceptAll")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "cta cta--option cookie-banner__cta",
                        on: {
                          click: function ($event) {
                            return _vm.refuseAllCookies()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cookie.refuseAll")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "cta cookie-banner__cta",
                        on: {
                          click: function ($event) {
                            return _vm.openCustomPannel()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cookie.customize")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.UI.options || (_vm.UI.banner && _vm.cookies.settings)
            ? _c("CookieBannerPanel", {
                on: {
                  emittedClosePanel: function ($event) {
                    return _vm.closeCustomPannel()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }