<template>
  <section class="modal-container" @click="closeOnClickOutside('modal-container')">
    <p v-if="submitStatus === 'SENT'" class="shareProject shareProject--confirmation">
      {{ $t('shareProject.success') }}
    </p>
    <div v-else class="shareProject">
      <UiBtnClose @BtnCloseClicked="$emit('closeWindowRequest')" />
      <h2 class="sharepPoject__title">
        {{ $t('shareProject.title') }}
      </h2>
      <form action="" class="shareProject__form" @submit.prevent="handleSubmit">
        <div>
          <label v-if="form.email" for="email">{{ $t('shareProject.email') }}</label>
          <input v-model="form.email" type="email" :placeholder="$t('shareProject.email')" name="email">
        </div>
        <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error">
          {{ $t('shareProject.error.emailRequired') }}
        </p>
        <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error">
          {{ $t('shareProject.error.emailInvalid') }}>
        </p>
        <Cta :text="$t('shareProject.cta')" class="shareProject__cta"/>
        <p v-if="submitStatus === 'PENDING'" class="msg--pending">
          {{ $t('shareProject.sending') }}
        </p>
        <p v-if="submitStatus === 'ERROR'" class="msg--error">
          {{ $t('shareProject.error.msg') }}
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import Cookies from 'js-cookie'

export default {
  props: {
    checksum: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      form: {
        email: '',
      },
      submitStatus: '',
    }
  },
  validations: {
    form: {
      email: { required, email },
    },
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
  },
  methods: {
    closeWindowRequest () {
      this.$emit('closeWindowRequest')
    },
    closeOnClickOutside (containerClass) {
      if (event.target.classList[0] === containerClass) {
        this.$emit('closeWindowRequest')
      }
    },
    shareProject () {
      const token = Cookies.get('temp-usr')
      const email = this.form.email
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      this.submitStatus = 'PENDING'
      axios.get(`/moder_1_5/cart/shopping?id=${this.checksum}&email=${email}&isFinished=false&lang=${this.lang}`, { headers })
        .then((response) => {
          this.submitStatus = 'SENT'
          setTimeout(() => {
            this.$emit('closeWindowRequest')
            this.submitStatus = ''
          }, 3000)
        })
        .catch((e) => {
          this.submitStatus = 'ERROR'
        })
    },
    handleSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.submitStatus = ''
      } else {
        this.shareProject()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shareProject {
  border-radius: $border-radius;
  background-color: $main-light-color;
  width: 400px;
  margin: auto;
  padding: 24px;
  position: relative;
  animation: revealSlidelight 400ms;

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
    label {
      position: absolute;
      top: -1rem;
      color: $main-dark-color;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.9em;
      animation: revealSlidelight 300ms;
    }

    input {
      border: none;
      border-bottom: 1px solid #b3b3b3;
      color: $main-dark-color;
      font-size: 1rem;
      font-weight: 300;
      width: 100%;
      padding: 2px 0;
    }

    input::placeholder {
      border: solid $border-color 1px;
      border: 0;
      font-weight: lighter;
    }

    input:focus {
      border-bottom: 1px solid $main-dark-color;
    }
  }

  &__cta {
    margin-top: 2rem;
    width: 100%;
  }
}

.shareProject--confirmation {
  width: auto;
  margin: auto;
}
</style>
