
export function getPackcomLotImages (packcom, lotNumber, context) {
  let packcomLotImages = []
  if (packcom && lotNumber) {
    for (const key in packcom) {
      if (key === lotNumber) {
        const images = packcom[key].filter(item => item.includes('Screenshot'))
        context.isMobile
          ? packcomLotImages = images.filter(item => item.includes('/HD_') || item.includes('_HD_'))
          : packcomLotImages = images.filter(item => item.includes('/fullHD_') || item.includes('_fullHD_'))
      }
    }
  }
  return packcomLotImages.map(link => context.$prefixLinks(link))
}

export function getPackcomOrbitalImages (packcom, context) {
  let packcomOrbitalImages = []
  if (packcom) {
    const image = packcom.Orbital.filter(item => item.includes('jpg') && item.includes('landscape'))
    context.isMobile
      ? packcomOrbitalImages = image.filter(item => item.includes('/HD_') || item.includes('_HD_'))
      : packcomOrbitalImages = image.filter(item => item.includes('/fullHD_') || item.includes('_fullHD_'))
  }
  return packcomOrbitalImages.map(link => context.$prefixLinks(link))
}
