var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.UI.confirmation
    ? _c("p", { staticClass: "contactForm-succes" }, [
        _vm._v("\n  " + _vm._s(_vm.$t("contactConfirmation.msg"))),
        _c("br"),
        _vm._v(_vm._s(_vm.$t("contactConfirmation.thx")) + "\n"),
      ])
    : _c(
        "div",
        { staticClass: "contactFormContainer" },
        [
          _c("UiBtnClose", {
            on: {
              BtnCloseClicked: function ($event) {
                return _vm.closeForm()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contactForm" },
            [
              _c(
                "div",
                { staticClass: "contactForm__header" },
                [
                  _c("UiBtnReturn", {
                    on: { emitClickBtnReturn: _vm.btnReturnInfoClicked },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "contactForm__title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("contactFormInfo.title")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.program.promoterInfo.name
                ? _c("p", { staticClass: "contactForm__subTitle" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("contactFormInfo.subTitle")) +
                        " " +
                        _vm._s(_vm.program.promoterInfo.name) +
                        ". " +
                        _vm._s(_vm.$t("contactFormInfo.subTitle2")) +
                        "\n    "
                    ),
                  ])
                : _c("p", { staticClass: "contactForm__subTitle" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("contactFormInfo.subTitle2")) +
                        ".\n    "
                    ),
                  ]),
              _vm._v(" "),
              _c("FormInternForm", {
                attrs: { "form-type": "information" },
                on: { formSent: _vm.setConfirmationUI },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }