<template>
  <div v-if="userLotsInfos && lotSelected" class="lot">
    <h2 class="lot-title">
      {{ $tc('property.title', userLotsInfos.length) }}
    </h2>
    <div class="lot-tabs">
      <p class="lot-tabs__item lot-tabs__item--infos" :class="{'lot-tabs__item--selected': UI.infos}" @click="resetTabUI(); UI.infos = true">
        {{ $t('property.tabs.information') }}
      </p>
      <p v-if="visuals" class="lot-tabs__item lot-tabs__item--visuals" :class="{'lot-tabs__item--selected': UI.visuals}" @click="resetTabUI(); UI.visuals = true">
        {{ $t('property.tabs.pictures') }}
      </p>
      <p v-if="timelineProjectConditions" class="lot-tabs__item lot-tabs__item--timeline" :class="{'lot-tabs__item--selected': UI.timeline}" @click="resetTabUI(); UI.timeline = true">
        {{ $t('property.tabs.timeline') }}
      </p>
    </div>
    <div class="lot-main-container__tabs">
      <div v-for="(userLot, index) in userLotsInfos" :key="index" class="lot-main-container__title" :class="{'lot-main-container__title--disabled': userLot.flatNumber !== lotSelected.flatNumber}" @click="updateLotSelected(userLot)">
        <p class="lot-main-container__lotNumber">
          LOT {{ userLot.flatNumber }}
        </p>
      </div>
    </div>
    <div class="lot-main-container">
      <div v-if="UI.infos && lotSelected && UI.infosReady" class="lot-infos-container">
        <div class="lot-infos__aside">
          <div class="aside-main">
            <div class="aside-main__card">
              <div class="aside-main__boxes">
                <p v-if="lotSelected.price && lotSelected.price > 0">
                  {{ parseFloat(lotSelected.price).toLocaleString() }} {{ lotSelected.priceMeasurement }}
                </p>
                <p v-if="lotSelected.type">
                  {{ lotSelected.type }}
                </p>
                <p v-if="lotSelected.topUrl && lotSelected.topUrl.floor">
                  <span v-if="lotSelected.topUrl.floor.toLowerCase() !== 'rdc'"> {{ $t('property.information.boxes.floor') }}</span> {{ lotSelected.topUrl.floor }}
                </p>
                <p v-if="bedrooms.length">
                  {{ bedrooms.length }} {{ $t('property.information.boxes.bedrooms') }}
                </p>
                <p v-if="bathrooms.length">
                  {{ bathrooms.length }} {{ $t('property.information.boxes.bathrooms') }}
                </p>
              </div>
            </div>
            <Gallery v-if="UI.gallery" :gallery="galleryContent" class="lot-infos__gallery--mobile" @clickOnImage="requestFullscreen($event)" />
            <div v-if="lotSelected.surface && lotSelected.surface.total && lotSelected.surface.total != '0'" class="aside-main__card">
              <h3 class="aside-main__surfaces">
                {{ $t('property.surfaces.title') }}
              </h3>
              <p class="aside-main__text">
                {{ $t('property.surfaces.interior') }} : {{ lotSelected.surface.total }} {{ lotSelected.surfaceMeasurement }}
              </p>
              <p v-if="lotSelected.surface.exteriorTotal && lotSelected.surface.exteriorTotal !== '0'" class="aside-main__text">
                {{ $t('property.surfaces.exterior') }} : {{ lotSelected.surface.exteriorTotal }} {{ lotSelected.surfaceMeasurement }}
              </p>
            </div>
            <div class="surfaces-wrapper aside-main__card">
              <div v-if="(surfaceIntern && surfaceIntern.length) || (surfaceExtern && surfaceExtern.length)" class="surfaces-wrapper__items">
                <h3 class="aside-main__surfaces">
                  {{ $t('property.surfaces.byRooms') }}
                </h3>
                <ul class="infos-list">
                  <li v-for="(item, index) in surfaceIntern" :key="index" class="infos-list__items">
                    <span v-if="item.room && item.surface">{{ $getRoomName(item.room) }} {{ getRoomNumber(item.room) }} : {{ item.surface }} {{ lotSelected.surfaceMeasurement }}</span>
                  </li>
                </ul>
                <ul class="infos-list">
                  <li v-for="(item, index) in surfaceExtern" :key="index" class="infos-list__items">
                    <span v-if="item.room && item.surface ">{{ $getRoomName(item.room) }} {{ getRoomNumber(item.room) }}: {{ item.surface }} {{ lotSelected.surfaceMeasurement }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="aside-footer">
            <button v-if="UI.infos" class="cta lot-infos-cta" @click="openStreamingLink(lotSelected.flatNumber)">
              {{ visitLinkText }} <img class="lot-infos-cta-image" src="/icon/icon-forward-white.png" alt="icon">
            </button>
          </div>
        </div>
        <Gallery v-if="UI.gallery && lotSelected" :gallery="galleryContent" class="lot-infos__gallery--desktop" @clickOnImage="requestFullscreen($event)" />
      </div>
      <section v-if="UI.plans" class="lot-plan2D">
        <Gallery v-if="UI.gallery" :gallery="galleryContent" @clickOnImage="requestFullscreen($event)" />
      </section>
      <GalleryFullscreenZoom v-if="UI.galleryFullscreen && lotSelected" :gallery="galleryContent" :gallery-index="galleryFullscreenIndex" @BtnCloseClicked="resetGalleryFullscreen()" @clickOutside="resetGalleryFullscreen()" />
      <section v-if="UI.visuals" class="lot-visuals">
        This is my images
      </section>
      <section v-if="UI.timeline && program.timeline_project.length > 0" class="dashboard-card">
        <h3 class="lot-infos__timeline">
          {{ $t('dashboard.timeline.title') }}
        </h3>
        <div class="timeline">
          <ul class="timeline__box">
            <li v-for="(item, index) in program.timeline_project" :key="index" class="timeline__item" :class="{'timeline__item--checked': item.done === true}">
              <div>
                <p class="timeline__heading">
                  {{ item.title }}
                </p>
                <p class="timeline__subtitle">
                  {{ item.description }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
  <div v-else class="lot">
    <h3 class="lot-title">
      {{ $tc('property.title', userLotsInfos.length) }}
    </h3>
    <p>{{ $t('property.empty') }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { getLotConfigurationsStatus } from '~/plugins/settings'
import { getStreamingLink } from '~/plugins/streaming'
import { getPackcomLotImages } from '~/plugins/packcom'
import { getLotPlans3D } from '~/plugins/lot'
import { REGEX_IMG } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      UI: {
        visuals: false,
        plans: false,
        infos: true,
        infosReady: true,
        timeline: false,
        gallery: true,
        galleryFullscreen: false,
      },
      lotSelected: {},
      hasLotSelectedConfigEnabled: false,
      timeline: null,
      visuals: null,
      galleryFullscreenIndex: '0',
      plans: [],
      galleryContent: null,
    }
  },
  computed: {
    ...mapGetters({
      userLotsInfos: 'lot/getLot',
      program: 'program/getProgramData',
      programId: 'program/programId',
      lotSubProgramId: 'lot/getLotSubProgramId',
      userStatus: 'user/getUserStatus',
      linkTarget: 'options/getLinkTargetOption',
    }),
    lang () {
      return this.$i18n.locale
    },
    surfaceExtern () {
      if (this.lotSelected.surface) {
        const surfaces = this.lotSelected.surface.surfaceByRoom.filter(x => x.room.split('-')[0] === 'BALC' || x.room.split('-')[0] === 'TERA')
        return surfaces
      }
      return null
    },
    surfaceIntern () {
      if (this.lotSelected.surface) {
        const surfaces = this.lotSelected.surface.surfaceByRoom.filter((x) => {
          return (x.room.split('-')[0] !== 'TERA' && x.room.split('-')[0] !== 'BALC')
        })
        return surfaces
      }
      return null
    },
    bedrooms () {
      if (this.lotSelected.surface) {
        return this.lotSelected.surface.surfaceByRoom.filter((x) => {
          const bedrooms = (x.room.split('-')[0] === 'BEDR')
          return bedrooms
        })
      }
      return 0
    },
    bathrooms () {
      if (this.lotSelected.surface) {
        return this.lotSelected.surface.surfaceByRoom.filter((x) => {
          const bathrooms = (x.room.split('-')[0] === 'BATH')
          return bathrooms
        })
      }
      return 0
    },
    timelineProjectConditions () {
      return !!(this.userStatus !== 'prospect' && this.program.timeline_project && this.program.timeline_project.length > 0)
    },
    visitLinkText () {
      return this.hasLotSelectedConfigEnabled
        ? this.$t('cta.visitLotConfigActivated')
        : this.$t('cta.visitLot')
    },
  },
  mounted () {
    this.lotSelected = this.userLotsInfos[0]
    this.hasLotSelectedConfigEnabled = getLotConfigurationsStatus({
      programData: this.program,
      lotVisitedInfos: this.userLotsInfos[0],
    }).isOneConfigEnabled
    this.galleryContent = this.getGalleryContent(this.lotSelected)
  },
  methods: {
    resetTabUI () {
      this.UI.infos = false
      this.UI.plan2D = false
      this.UI.plan3D = false
      this.UI.plans = false
      this.UI.visuals = false
      this.UI.timeline = false
    },
    isAnImage (elt) {
      return REGEX_IMG.test(elt)
    },
    getRoomNumber (room) {
      const numbers = { 0: '', 1: '2', 2: '3', 3: '4' }
      let number = ''

      for (const item in numbers) {
        if (room.split('-')[1] === item) {
          number = numbers[item]
          return number
        }
      }
    },
    requestFullscreen (index) {
      this.UI.galleryFullscreen = true
      this.galleryFullscreenIndex = index.toString()
    },
    resetGalleryFullscreen () {
      this.UI.galleryFullscreen = false
      this.galleryFullscreenIndex = '0'
    },
    async openStreamingLink (lotNumber) {
      const token = Cookies.get('temp-usr')
      const { link } = await getStreamingLink({ programId: this.programId, flatId: lotNumber, token })

      window.open(link, this.linkTarget)
    },
    updateLotSelected (userLot) {
      this.lotSelected = userLot
      this.UI.gallery = false
      this.UI.infosReady = false
      this.galleryContent = this.getGalleryContent(this.lotSelected)

      setTimeout(() => {
        this.UI.gallery = true
        this.UI.infosReady = true
      }, 25)
    },
    getGalleryContent (lotSelected) {
      let packcomImages = []
      let plans = []

      if (this.$store.state.packcom.packcom) {
        packcomImages = getPackcomLotImages(this.$store.state.packcom.packcom, lotSelected.flatNumber, this)
      }

      plans = getLotPlans3D(lotSelected)

      return plans.concat(packcomImages)
    },
  },
}
</script>

<style lang="scss" scoped>
.lot-infos-container {
  animation: reveallight 220ms linear;

  @media (min-width: $breakpoint-large) {
    display: flex;
  }
}

.lot {
  padding: min(2rem, 6%);
  overflow-x: auto;
  animation: reveallight 600ms;

  @media (min-width: $breakpoint-medium) {
    background-color: $main-light-color;
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    height: 100%;
  }

  &-title {
    @extend %ext-title;
  }

  &-tabs {
    display: flex;
    @extend %ext-tab;
    width: fit-content;
    position: relative;
    padding-bottom: 1rem;

    &__item {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      border-bottom: solid $border-color 2px;
      text-transform: capitalize;
      transition: border-bottom 200ms;

      &:hover {
        border-bottom: solid var(--second-option-color) 2px;
        cursor: pointer;
      }

      &--selected {
        border-bottom: solid var(--second-option-color) 2px;
        color: var(--second-option-color);
        font-weight: 700;

        &:hover {
          cursor: default;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-infos {
    &__specs,
    &__surfaces-subtitle,
    &__timeline {
      @extend %ext-subTitle;
    }

    @media (min-width: $breakpoint-medium) {
      height: 80%;
    }
  }
}

.lot-main-container {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  min-height: 300px;

  &__tabs {
    display: flex;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    border: solid $border-color 1px;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 0;
    text-align: center;
    font-weight: 700;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &__surfaces-text {
      padding-bottom: 1rem;
      color: $main-medium-color;
      display: inline-block;
      margin-right: 2rem;
    }

    &__text {
      color: $main-medium-color;
    }

    &--disabled {
      opacity: 0.6;
      background-color: $bg-color;
    }
  }

  &__lotNumber {
    color: var(--second-option-color);
  }
}

.lot-infos {
  &__aside {
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;

    @media (min-width: $breakpoint-large) {
      width: 40%;
    }
  }

  &__main {
    width: 60%;
    display: flex;
  }

  &__specs,
  &__surfaces-subtitle,
  &__timeline {
    @extend %ext-subTitle;
  }

  &__description {
    @extend %ext-text;
  }

  &__boxes {
    padding-bottom: 2rem;

    > p {
      display: inline-block;
      border: solid $main-medium-color 1px;
      padding: 2px 0.5rem;
      margin-right: 2px;
      margin-bottom: 4px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  &__surfaces-text {
    padding-bottom: 1rem;
    color: $main-medium-color;
    display: inline-block;
    margin-right: 2rem;
  }

  &-cta {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    &-image {
      height: 0.9rem;
      width: auto;
      padding-left: 1rem;
    }
  }

  &__gallery--mobile {
    width: 100%;
    margin: auto;

    @media (min-width: $breakpoint-large) {
      display: none;
    }
  }

  &__gallery--desktop {
    width: 50%;
    margin: auto;

    @media (max-width: $breakpoint-large) {
      display: none;
    }
  }
}

.aside-main {
  max-height: calc(100% - 5rem);
  overflow: auto;

  &__card {
    margin-bottom: 1rem;
  }

  &__title,
  &__specs,
  &__surfaces,
  &__timeline {
    @extend %ext-subTitle;
  }

  &__description {
    @extend %ext-text;
  }

  &__boxes {
    > p {
      display: inline-block;
      border: solid $main-medium-color 1px;
      padding: 2px 0.5rem;
      margin-right: 2px;
      margin-bottom: 4px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  &__text {
    color: $main-medium-color;
    display: inline-block;
    margin-right: 3rem;
  }
}

.infos-list {
  width: 100%;
  margin-right: 8rem;
  color: $main-medium-color;

  &__items {
    padding: 2px 0.5rem;
    list-style-type: disc;
    margin-left: 2rem;
  }
}

.surfaces-wrapper {
  @media (min-width: $breakpoint-large) {
    width: 100%;
    display: flex;
  }

  &__heading {
    font-weight: 700;
    color: var(--second-option-color);
    margin-bottom: 1rem;
  }
  &__items {
    margin-top: 1rem;
    list-style-type: disc;
  }
}
</style>
