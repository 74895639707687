<template>
  <div class="apptContainer">
    <UiBtnClose @BtnCloseClicked="$emit('closeRequest')" />
    <div v-if="response.failed === true" class="inputError">
      <p class="error__text">
        Une erreur est survenue
      </p>
    </div>
    <Loader v-if="isCalendarResLoaded === false && response.failed === false" class="appointment__loader" />
    <p v-if="UI.confirmation" class="appointment-success">
      {{ $t('contactConfirmation.msg') }}<br>{{ $t('contactConfirmation.thx') }}
    </p>
    <div v-if="!apptSelected && isCalendarResLoaded === true" class="appointment">
      <div class="appointment__header">
        <UiBtnReturn v-if="!apptSelected" @emitClickBtnReturn="btnReturnApptClicked" />
        <h2 class="appointment__title">
          rendez-vous {{ apptText }}
        </h2>
      </div>
      <p class="appointment__text">
        Choisir la date
      </p>
      <section class="date">
        <div class="calendar">
          <div class="calendar__days">
            <div v-if="!appt.date" class="daySelection">
              {{ actualDate }}
            </div>
            <div v-else class="daySelection">
              {{ formatedDate }}
            </div>
            <div class="daysBox">
              <div class="daysBox__name">
                L
              </div>
              <div class="daysBox__name">
                M
              </div>
              <div class="daysBox__name">
                M
              </div>
              <div class="daysBox__name">
                J
              </div>
              <div class="daysBox__name">
                V
              </div>
              <div class="daysBox__name">
                S
              </div>
              <div class="daysBox__name">
                D
              </div>
              <div
                v-for="(dates, index) in calendar"
                :id="`daysBox_${dates.date.slice(0,10)}`"
                :key="index"
                :class="{'selectedDay': dates === appt.date}"
                class="daysBox__day"
                @click="setSelectedDay(dates, $event)"
              >
                <div :class="{ dayInactive: weekendFilter(normalizeDate(dates.date))}">
                  {{ normalizeDate(dates.date).split(" ").splice(1,1).join("") }}
                </div>
              </div>
            </div>
          </div>
          <div class="calendar__hours">
            <div
              v-for="hours in appt.date.values"
              :id="`timeSlot_${hours.hour}`"
              :key="hours.startDate"
              class="timeSlot"
              :class="{
                'timeSlot-inactive': hours.isFree === false,
                'timeSlot-active': hours.isFree === true,
                'selectedDay': hours.startDate === appt.startDate
              }"
              @click="appt.startDate = hours.startDate"
            >
              <div v-if="hours.isFree === true">
                {{ hours.hour + " H" }}
              </div>
              <div v-else>
                Réservé
              </div>
            </div>
          </div>
        </div>
      </section>
      <div v-if="apptResumeError" class="error-alert error-alert--center">
        Veuillez sélectionner la date et l'heure de votre rendez-vous
      </div>
      <div class="validation">
        <button class="cta" @click="apptSubmit">
          CONTINUER
        </button>
      </div>
    </div>
    <div v-if="apptSelected && !UI.confirmation" class="appointment">
      <div class="appointment__header">
        <UiBtnReturn @emitClickBtnReturn="apptReset" />
        <h2 class="appointment__title">
          rendez-vous {{ formatedTextConfirmation }}
        </h2>
      </div>
      <span class="appointment__subtitle">{{ formatedDate }} à {{ formatedHour }} heures</span>
      <FormExternQrForm :user-choice-location="apptType" :user-choice-start-date="appt.startDate" class="appointment__contactForm" @statusOK="setConfirmationUI()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  props: {
    apptType: {
      type: String,
      default: '',
    },
    apptText: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isCalendarResLoaded: false,
      appt: {
        date: {},
        startDate: '',
      },
      calendarWindow: {
        start: '',
        end: '',
      },
      apptSelected: '',
      calendar: null,
      location: '',
      response: {
        status: '',
        data: '',
        failed: false,
      },
      error: false,
      errorMessage: '',
      apptResumeError: false,
      UI: {
        confirmation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      programIdQrStock: 'programQrStock/getProgramIdQrStock',
      officeIdQrStock: 'programQrStock/getofficeIdQrStock',
    }),
    formatedDate () {
      return this.normalizeDate(this.appt.date.date)
    },
    formatedHour () {
      return this.normalizeHour(this.appt.startDate)
    },
    actualDate () {
      return this.normalizeDate()
    },
    apptDate () {
      return this.normalizeDate(this.appt.startDate)
    },
    formatedTextConfirmation () {
      return this.normalizeTextConfirmation(this.apptText)
    },
  },
  created () {
    this.getCalendar()
  },
  methods: {
    setSelectedDay (dates, event) {
      try {
        const oldSelection = document.querySelector('.selectedDay')
        if (oldSelection) {
          oldSelection.classList.remove('selectedDay')
        }
      } catch (error) {
        return error
      }
      this.appt.startDate = ''
      this.appt.date = dates
      event.currentTarget.classList.add('selectedDay')
    },
    setCurrentDateSelected () {
      const dateToday = new Date()
      const dateTodayISO = dateToday
        .toISOString()
        .split('T')
        .splice(0, 1)
        .join('')
      const currentDayID = `daysBox_${dateTodayISO}`
      const myarray = document.getElementById(currentDayID)
      myarray.classList.add('selectedDay')
    },
    btnReturnApptClicked () {
      this.$emit('btnReturnApptClicked')
    },
    actualDateHours () {
      const dateToday = new Date()
      const dateTodayISO = dateToday
        .toISOString()
        .split('T')
        .splice(0, 1)
        .join('')
      const dateTodayHours = this.calendar.filter(
        x => x.date.split('T').splice(0, 1).join('') === dateTodayISO
      )[0].values
      this.appt.date.values = []
      for (let i = 0; i < dateTodayHours.length; i++) {
        this.appt.date.values.push(dateTodayHours[i])
      }
    },
    async getCalendar () {
      this.calendarOrganiser()
      const body = {
        startDate: this.calendarWindow.start,
        endDate: this.calendarWindow.end,
        programCrmId: this.programIdQrStock,
        lotCrmId: '',
        officeId: this.officeIdQrStock,
      }
      const headers = {
        'content-type': 'application/json',
        Authorization: process.env.ANTARES_API_KEY,
      }
      await axios
        .post('/antares/Contact/Calendar', body, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.calendar = response.data
            this.actualDateHours()
            this.isCalendarResLoaded = true
          }
          return response
        })
        .then(() => {
          this.setCurrentDateSelected()
        })
        .catch((error) => {
          this.response.status = error.response.status
          this.response.data = error.response.data
          this.response.failed = true
        })
    },
    normalizeHour (element) {
      const hour = new Date(element)
      const normalizeHour = hour.getHours()
      return normalizeHour
    },
    normalizeTextConfirmation (element) {
      const newText = element
        .split(' ')
        .map(x => (x === 'mon' ? (x = 'votre') : (x)))
        .join(' ')
      return newText
    },
    normalizeDate (event) {
      const days = [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ]
      const month = [
        'janvier',
        'fevrier',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'aout',
        'septembre',
        'octobre',
        'novembre',
        'decembre',
      ]
      let dateToFormate
      if (event) {
        dateToFormate = new Date(event)
      } else { dateToFormate = new Date() }
      const dateNumber = dateToFormate.getUTCDate()
      const dateDay = days[dateToFormate.getDay()]
      const dateMonth = month[dateToFormate.getMonth()]

      return `${dateDay} ${dateNumber} ${dateMonth}`
    },
    weekendFilter (array) {
      if (array.includes('samedi')) {
        return true
      }
      if (array.includes('dimanche')) {
        return true
      }
      return false
    },
    calendarOrganiser () {
      const dateToday = new Date()
      const dateTodayUtc = dateToday.getUTCDay()
      let weekStartDate
      let calendarEndDate
      if (dateTodayUtc === 0) {
        weekStartDate = dateToday.setDate(dateToday.getDate() + 1)
        weekStartDate = new Date(weekStartDate).toISOString()
      } else {
        weekStartDate = dateToday.setDate(
          dateToday.getDate() - (dateTodayUtc - 1)
        )
        weekStartDate = new Date(weekStartDate).toISOString()
      }
      const weekStartDateObject = new Date(weekStartDate)
      calendarEndDate = weekStartDateObject.setDate(
        weekStartDateObject.getDate() + 27
      )
      calendarEndDate = new Date(calendarEndDate).toISOString()
      this.calendarWindow.start = weekStartDate
      this.calendarWindow.end = calendarEndDate
    },
    apptSubmit () {
      if (this.apptType && this.appt.startDate) {
        this.apptResumeError = false
        this.apptSelected = true
      } else {
        this.apptResumeError = true
      }
    },
    apptReset () {
      this.appt.startDate = ''
      this.apptSelected = ''
    },
    setConfirmationUI () {
      this.UI.confirmation = true
    },
  },
}
</script>

<style lang="scss" scoped>
.apptContainer {
  background-color: white;
  opacity: 0.94;
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  @media (max-height: 600px) {
    opacity: 1;
  }

  &__footer {
    margin-top: auto;
  }
}

.appointment,
.appointment-success {
  color: $main-dark-color;
  background-color: #ffffff;
  margin: auto;
  padding: 2rem 8vw 0 8vw;
  font-size: $fs-text;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  &__subtitle {
    display: block;
    margin-bottom: 22px;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__text {
    font-size: 1rem;
    color: $alert-color;
    font-weight: bold;
  }
}

.infos__title,
.infos__text {
  padding-bottom: 1rem;
}

.btnSelection {
  border: solid $main-dark-color 1px;
  color: $main-dark-color;
  padding: 0.4em;
  width: calc(100vw / 8);
  text-align: center;
  background-color: $main-light-color;
}

.btnSelected {
  background-color: $main-dark-color;
  color: $main-light-color;
  font-weight: bold;
}

.validation {
  text-align: center;
}

.timeSlot {
  width: 33.33%;
  height: 10vh;

  &-active {
    display: flex;
    background-color: $main-light-color;
    > div {
      margin: auto;
      text-align: center;
    }
  }

  &-inactive {
    background-color: #f0f0f0;
    opacity: 0.8;
    pointer-events: none;
    cursor: default;
    display: flex;
    > div {
      margin: auto;
      width: 100%;
    }
  }

  & > * {
    font-size: 0.8rem;
    width: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
    border-radius: 50%;
  }
}

.calendar {
  min-height: 100%;
  overflow: auto;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    min-height: 100%;
    margin: 1.2rem auto;
  }

  &__days,
  &__hours {
    width: 100%;

    @media (min-width: $breakpoint-medium) {
      width: 49%;
    }
  }

  &__days {
    font-weight: 700;
  }

  &__hours {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    min-height: 300px;
    margin-bottom: 7px;
    border: solid rgb(237, 237, 237) 1px;
  }

  @media screen and (max-width: 890px) {
    margin: 4px auto;
  }
}

.daysBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  margin: 0.5rem auto;
  background-color: rgb(242, 242, 242);

  &__name {
    background-color: $main-light-color;
    width: calc(100% / 7);
    padding: 2px;
    text-align: center;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 900;

    &:nth-child(6n),
    &:nth-child(7n) {
      color: var(--second-option-color);
    }
  }

  &__day {
    width: calc(100% / 7);
    height: 10vh;
    text-align: left;
    display: flex;

    & > div {
      margin: 2px;
      font-size: 0.9rem;
      width: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 50%;
      margin: auto;
    }

    &:nth-child(7n) {
      border-right: solid $main-medium-color 1px;
    }

    &:hover > div {
      background-color: var(--main-option-color);
      color: $main-light-color;
      font-weight: bold;
    }
  }

  &:first-child() {
    color: red;
  }
}

.daySelection {
  background-color: $main-light-color;
  color: $main-dark-color;
  font-weight: 900;
  text-align: left;
  font-size: 1.2rem;
  padding: 0.4em 0;
  min-width: 180px;
  text-transform: uppercase;

  @media screen and (max-width: 890px) {
    font-size: 1rem;
  }
}

.timeSlot-active:hover > div,
.btnSelection:hover > div,
.days:hover > div {
  cursor: pointer;
  background-color: var(--main-option-color);
  color: $main-light-color;
  font-weight: bold;
}

.selectedDay > div,
.selectedHour > div {
  cursor: pointer;
  background-color: var(--second-option-color);
  color: $main-light-color;
  font-weight: bold;
}

.appointmentDateSelected {
  color: var(--second-option-color);
  font-weight: bold;
}

.error {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  &__text {
    margin: auto;
  }
}

.appointment__title,
.appointment__contactForm,
.appointment__text,
.appointment__subtitle,
.date {
  animation: revealSlidelight 400ms;
}

.btnReturn {
  margin-right: 1rem;
}

.appointment__confirmation {
  position: absolute;
  top: 0;
  bottom: 0;
}

.appointment__loader {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
