var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.UI && _vm.UI.userLogin
    ? _c("section", { staticClass: "loginContainer" }, [
        _c("h2", { staticClass: "loginContainer__title" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("login.title")) + "\n  "),
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "loginForm",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "loginForm__field" }, [
              _vm.form.email
                ? _c("label", { attrs: { for: "email" } }, [
                    _vm._v(_vm._s(_vm.$t("login.username"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email",
                  },
                ],
                attrs: {
                  id: "email",
                  type: "email",
                  name: "email",
                  autocomplete: "on",
                  placeholder: _vm.$t("login.username"),
                },
                domProps: { value: _vm.form.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.email.required && _vm.$v.form.email.$dirty
              ? _c("p", { staticClass: "error-alert" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("login.usernameRequired")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.form.email.email && _vm.$v.form.email.$dirty
              ? _c("p", { staticClass: "error-alert" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("login.usernameValidRequired")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "loginForm__field" }, [
              _vm.form.password
                ? _c("label", { attrs: { for: "password" } }, [
                    _vm._v(_vm._s(_vm.$t("login.password"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.password && _vm.passwordFieldType === "text"
                ? _c("img", {
                    staticClass: "togglePassword",
                    attrs: {
                      srcset: "/icon/eyeOpened.svg",
                      src: "/icon/eyeOpened.png",
                      alt: "eye",
                    },
                    on: { click: _vm.passwordToggle },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.form.password && _vm.passwordFieldType === "password"
                ? _c("img", {
                    staticClass: "togglePassword",
                    attrs: {
                      srcset: "/icon/eyeClosed.svg",
                      src: "/icon/eyeClosed.png",
                      alt: "eye",
                    },
                    on: { click: _vm.passwordToggle },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.passwordFieldType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      id: "password",
                      autocapitalize: "off",
                      autocomplete: "off",
                      placeholder: _vm.$t("login.password"),
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.form.password)
                        ? _vm._i(_vm.form.password, null) > -1
                        : _vm.form.password,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.form.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.form, "password", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                "password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, "password", $$c)
                        }
                      },
                    },
                  })
                : _vm.passwordFieldType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      id: "password",
                      autocapitalize: "off",
                      autocomplete: "off",
                      placeholder: _vm.$t("login.password"),
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.form.password, null) },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.form, "password", null)
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      id: "password",
                      autocapitalize: "off",
                      autocomplete: "off",
                      placeholder: _vm.$t("login.password"),
                      type: _vm.passwordFieldType,
                    },
                    domProps: { value: _vm.form.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                    },
                  }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.password.required && _vm.$v.form.password.$dirty
              ? _c("p", { staticClass: "error-alert" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("login.passwordRequired")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "loginForm__footer" }, [
              _c(
                "p",
                {
                  staticClass: "loginForm__recoveryLink",
                  on: { click: _vm.recoveryLinkClicked },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("login.passwordRecovery")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.loginInProgress
              ? _c(
                  "button",
                  {
                    staticClass: "cta--light cta--light--inProgress",
                    attrs: { type: "submit", disabled: "" },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("login.inProgress")) + "\n    "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "loginForm__cta cta--light",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("\n      " + _vm._s(_vm.$t("ctaLogin")) + "\n    ")]
                ),
            _vm._v(" "),
            _c("p", { staticClass: "loginForm__subText" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("login.or")) + "\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cta--light cta--option",
                attrs: { type: "submit" },
                on: { click: _vm.newAccountClicked },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("login.ctaSignin")) + "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.loginError
              ? _c("p", { staticClass: "error-alert error-alert--center" }, [
                  _vm._v("\n      " + _vm._s(_vm.loginError) + "\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error.role
              ? _c("p", { staticClass: "error-alert error-alert--center" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("login.incorrectRole")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _c("Loader")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }