var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "powered-by-box",
      style: "position: " + _vm.position + ";",
      attrs: { href: _vm.website, target: "_blank" },
    },
    [
      _c("img", {
        attrs: { src: require("assets/img/powered-by-moderlab.svg") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }