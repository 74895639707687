var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-menu" },
    [
      _c("VisitLinkSmall", {
        staticClass: "visit-box",
        attrs: { "program-background": _vm.programBackground },
      }),
      _vm._v(" "),
      _vm.userStatus === "acquirer"
        ? _c(
            "div",
            { staticClass: "navigation-box" },
            [
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-home"
                        : "lotId-home",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-home-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.dashboard")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams ? "client-lotId-lot" : "lotId-lot",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-mon-bien-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$tc("menu.lot", _vm.userLotsLength))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-program"
                        : "lotId-program",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-program-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.program")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-project"
                        : "lotId-project",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-my-project-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.projects")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-docs"
                        : "lotId-docs",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-documents-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.docs")))]),
                  _vm._v(" "),
                  _vm.notificationsChoiceSheets > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "notifications navigation-box-notification",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.notificationsChoiceSheets) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.commercialInfos && _vm.commercialInfos.length > 0
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-messages"
                            : "lotId-messages",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "menu-icon",
                        attrs: {
                          src: "/icon/icon-messages-white.png",
                          alt: "icon program",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.$t("menu.messages")))]),
                      _vm._v(" "),
                      _vm.notificationsMessages > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "notifications navigation-box-notification",
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.notificationsMessages) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-construction"
                        : "lotId-construction",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-construction-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.construction")))]),
                  _vm._v(" "),
                  _vm.notificationsNews > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "notifications navigation-box-notification",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.notificationsNews) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "navigation-box" },
            [
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-home"
                        : "lotId-home",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-home-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.dashboard")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-program"
                        : "lotId-program",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-program-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.program")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-project"
                        : "lotId-project",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-my-project-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.projects")))]),
                ]
              ),
              _vm._v(" "),
              _vm.commercialInfos && _vm.commercialInfos.length > 0
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-messages"
                            : "lotId-messages",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "menu-icon",
                        attrs: {
                          src: "/icon/icon-messages-white.png",
                          alt: "icon program",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.$t("menu.messages")))]),
                      _vm._v(" "),
                      _vm.notificationsMessages > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "notifications navigation-box-notification",
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.notificationsMessages) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-construction"
                        : "lotId-construction",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: "/icon/icon-construction-white.png",
                      alt: "icon program",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("menu.construction")))]),
                  _vm._v(" "),
                  _vm.notificationsNews > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "notifications navigation-box-notification",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.notificationsNews) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("div", { staticClass: "cookies-link-container" }, [
        _c(
          "p",
          {
            staticClass: "cookies-link-container__link",
            on: {
              click: function ($event) {
                return _vm.OpenCookiesSettings()
              },
            },
          },
          [_vm._v("\n      Cookies\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }