
import Cookies from 'js-cookie'

export default function ({ store, redirect, route }, inject) {
  inject('resetApp', (routeName) => {
    resetStores(store)
    redirection(routeName, route, redirect)
  })
}

function resetStores (store) {
  Cookies.remove('temp-route')
  store.commit('RESET_USER_INFOS')
  store.commit('messages/RESET_MESSAGES')
  store.commit('choiceSheet/RESET_CHOICESHEETS')
  store.commit('user/RESET_USER_STATUS_ROLE')
  store.commit('program/RESET_PROGRAM')
  store.commit('lot/RESET_LOT')
  store.commit('projects/RESET_PROJECTS')
  store.commit('news/RESET_NEWS')
  Cookies.remove('temp-usr')
  Cookies.remove('temp-usr-rl')
  setTimeout(() => {
    localStorage.removeItem('temp_vx')
  }, 1000)
}

function redirection (routeName, route, redirect) {
  if (['promoter-program', 'lotId', 'client-lotId'].includes(routeName)) {
    const url = new URL(location)
    location.replace(url)
  } else {
    redirect({ name: 'promoter-program' })
  }
}
