/* eslint-disable vue/no-v-html */
<template>
  <div class="messages">
    <div v-if="UI.messages" class="message-container">
      <h2 class="messages__title">
        {{ $t('messages.title') }}
      </h2>
      <div v-if="conversationsLotIdKeys && conversationsLotIdKeys.length > 0" class="lot-selector">
        <span for="lotnumber" class="lot-selector__label"> LOT : </span>
        <select v-model="lotSelected" class="lot-selector__select">
          <option v-for="(item, index) in conversationsLotIdKeys" :key="index" :value="item" class="lot-selector__options">
            {{ item }}
          </option>
        </select>
      </div>
      <section v-if="userMessages && Object.keys(userMessages).length" class="messages__box">
        <div v-for="(item, index) in userMessages" :key="index" class="message-item" :class="{'message-item--unread': !item[item.length - 1].read }" @click="loadMessageInbox(index, item[item.length - 1].id, item[item.length - 1].read)">
          <p class="message-item__date" :class="{'message-item__notification': !item[item.length - 1].read }">
            {{ item[item.length - 1].time }}
          </p>
          <p href="#" class="message-item__heading">
            {{ item[item.length - 1].subject }}
          </p>
        </div>
      </section>
      <div v-else class="messages__box messages__box--empty">
        {{ $t('messages.noMessages') }}
      </div>
      <section class="messages__contact">
        <h2 class="messages__subtitle">
          {{ $t('messages.form.title') }}
        </h2>
        <form class="contact" @submit.prevent="StartConversation()">
          <div class="contact__heading">
            <label for="" class="contact__label">{{ $t('messages.form.subject') }}</label>
            <input v-model="form.subject" type="text" class="contact__input" :placeholder="$t('messages.form.placeholderObject')">
          </div>
          <p v-if="!$v.form.subject.textAreaForbiddenCharacters && $v.form.subject.$dirty" class="error-alert">
            {{ $t('errors.forbiddenCharacters') }}
          </p>
          <p v-if="!$v.form.subject.required && $v.form.subject.$dirty" class="error-alert">
            {{ $t('messages.form.required') }}
          </p>
          <textarea v-model="form.message" :placeholder="$t('messages.form.placeholderInput')" rows="10" class="contact__textarea" />
          <p v-if="!$v.form.message.textAreaForbiddenCharacters && $v.form.message.$dirty" class="error-alert">
            {{ $t('errors.forbiddenCharacters') }}
          </p>
          <p v-if="!$v.form.message.required && $v.form.message.$dirty" class="error-alert">
            {{ $t('messages.form.required') }}
          </p>
          <div v-if="submit.inprogress" class="cta cta-alert--inprogress" disabled>
            <p>{{ $t('messages.form.inProgress') }}</p>
          </div>
          <div v-else-if="submit.success" class="cta cta-alert--succes">
            {{ $t('messages.form.succes') }}
          </div>
          <div v-else>
            <button v-if="UsersInfosExists && userStatus === 'acquirer'" class="cta" :disabled="clientParams" :class="{'cta--disabled': clientParams}">
              {{ $t('messages.form.action') }}
            </button>
            <p v-else>
              {{ $t('messages.form.noUserInfos') }}
            </p>
          </div>
          <p v-if="submit.error" class="error-alert">
            {{ submit.error }}
          </p>
        </form>
      </section>
    </div>
    <div v-if="UI.inbox" class="message-container">
      <h2 class="messages__title">
        {{ $t('messages.title') }}
      </h2>
      <button class="messages__return" @click="resetUI(); UI.messages = true">
        <img src="/icon/icon-return.png" alt="icon">
      </button>
      <div class="conversation__heading">
        <p class="conversation__title">
          {{ messageInbox[0].subject }}
        </p>
      </div>
      <div class="conversation">
        <div class="conversation__content">
          <div v-for="(msg, index) in messageInbox" :key="index" class="conversation-msg" :class="{'conversation-msg--right': buyer.includes(msg.sender_id)}">
            <div class="conversation-msg__heading" :class="{'conversation-msg__heading--right': buyer.includes(msg.sender_id)}">
              <img src="/icon/icon-user-black.png" alt="user icon" class="conversation-msg__icon" :class="{'conversation-msg__icon--right':buyer.includes(msg.sender_id)}">
              <div class="conversation-msg__timestamp">
                <p>{{ msg.sender_name }}</p>
                <p>{{ msg.time }}</p>
              </div>
            </div>
            <div class="conversation-msg__content" :class="{'conversation-msg__content--right': buyer.includes(msg.sender_id)}" v-html="msg.content" />
          </div>
        </div>
      </div>
      <form class="conversation-answer" @submit.prevent="replyToAMessage()">
        <textarea v-model="formReply" :placeholder="$t('messages.form.placeholderInput')" rows="3" class="contact__textarea" />
        <p v-if="!$v.formReply.textAreaForbiddenCharacters && $v.formReply.$dirty" class="error-alert">
          {{ $t('errors.forbiddenCharacters') }}
        </p>
        <p v-if="!$v.formReply.required && $v.formReply.$dirty" class="error-alert">
          {{ $t('messages.form.required') }}
        </p>
        <div v-if="submit.inprogress" class="cta cta-alert--inprogress" disabled>
          <p>{{ $t('messages.form.inProgress') }}</p>
        </div>
        <div v-else-if="submit.success" class="cta cta-alert--succes">
          {{ $t('messages.form.succes') }}
        </div>
        <div v-else>
          <button v-if="UsersInfosExists && userStatus === 'acquirer'" class="cta" :disabled="clientParams" :class="{'cta--disabled': clientParams}">
            {{ $t('messages.form.action') }}
          </button>
          <p v-else>
            {{ $t('messages.form.noUserInfos') }}
          </p>
        </div>
        <p v-if="submit.error" class="error-alert">
          {{ submit.error }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Cookies from 'js-cookie'
import { getHeaders } from '~/plugins/headers'
import { CUSTOMERAREA } from '~/plugins/constants'
const textAreaForbiddenCharacters = (value) => {
  return /^[^=*<>\[\]$/{}"]{0,}$/.test(value)
}

export default {
  name: 'Messages',
  data () {
    return {
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      UI: {
        messages: true,
        inbox: false,
      },
      submit: {
        success: false,
        inprogress: false,
        error: '',
      },
      messageInbox: '',
      contact: {},
      name: '',
      form: {
        subject: '',
        message: '',
      },
      formReply: '',
      conversationsLotIdKeys: [],
      messagesLotIdKeys: [],
      messagesByLots: null,
      lotSelected: 0,
    }
  },
  validations: {
    form: {
      subject: { required, textAreaForbiddenCharacters },
      message: { required, textAreaForbiddenCharacters },
    },
    formReply: { required, textAreaForbiddenCharacters },
  },
  computed: {
    ...mapGetters({
      buyer: 'messages/getBuyer',
      loggedInUser: 'loggedInUser',
      loggedInUserId: 'loggedInUserId',
      loggedInUserFullname: 'loggedInUserFullname',
      programId: 'program/programId',
      commercialInfos: 'program/commercialInfos',
      programData: 'program/getProgramData',
      userConversationsListByLot: 'messages/getMessages',
      userFlats: 'getUserFlats',
      userStatus: 'user/getUserStatus',
    }),
    UsersInfosExists () {
      if (this.commercialInfos && this.commercialInfos.length > 0) {
        return (this.loggedInUserId && this.commercialInfos[0].commercial_id)
      }
      return false
    },
    userMessages () {
      if (this.userConversationsListByLot && this.lotSelected) {
        if (this.lotSelected in this.userConversationsListByLot) {
          return this.userConversationsListByLot[this.lotSelected]
        }
      }
      return false
    },
  },
  async mounted () {
    if (!this.clientParams && this.commercialInfos && this.commercialInfos.length) {
      const messagesResponse = await this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.commercialInfos[0].commercial_id })
      if (messagesResponse.status === 401) { this.$resetApp() }
    }
    if (this.userConversationsListByLot && this.userFlats) {
      this.conversationsLotIdKeys = this.userFlats
      this.lotSelected = this.userFlats.length > 0 && this.userFlats[0]
    }
  },
  methods: {
    ...mapActions({
      loadMessages: 'messages/loadMessages',
    }),
    StartConversation () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) { return }

      if (!this.clientParams && !this.$v.form.$invalid) {
        this.submit.inprogress = true
        this.submit.error = ''
        const token = Cookies.get('temp-usr')

        const body = {
          subject: this.form.subject,
          message: this.form.message,
          sender_id: this.loggedInUserId,
          receiver_id: this.commercialInfos[0].commercial_id,
          promoter_id: this.programData.promoterInfo.promoterId || '',
          program_id: this.programId || '',
          flat_number: this.lotSelected,
        }
        axios.post('/moder_1_5/message', body, { headers: getHeaders(token) })
          .then(() => {
            setTimeout(() => {
              this.submit.success = true
              this.submit.inprogress = false
              setTimeout(() => {
                this.submit.success = false
                this.form.subject = null
                this.form.message = null
                this.$v.$reset()
              }, 2000)
            }, 2000)
          })
          .catch((error) => {
            this.submit.inprogress = false
            this.submit.error = error.response.statusText
          })
      }
    },
    replyToAMessage () {
      this.$v.formReply.$touch()
      if (this.$v.formReply.$invalid) { return }

      if (!this.clientParams && !this.$v.formReply.$invalid) {
        this.submit.inprogress = true
        this.submit.error = ''
        const token = Cookies.get('temp-usr')

        const body = {
          subject: this.messageInbox[0].subject,
          message: this.formReply,
          sender_id: this.loggedInUserId,
          receiver_id: this.commercialInfos[0].commercial_id,
          promoter_id: this.programData.promoterInfo.promoterId || '',
          program_id: this.programId || '',
          flat_number: this.lotSelected,
          read: true,
        }
        axios.post('/moder_1_5/message', body, { headers: getHeaders(token) })
          .then(() => {
            setTimeout(() => {
              this.submit.success = true
              this.submit.inprogress = false
              this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.commercialInfos[0].commercial_id })
                .then(() => {
                  this.loadMessageInbox(this.messageInbox[0].subject, this.messageInbox[this.messageInbox.length - 1].id, false)
                  this.formReply = ''
                  this.$v.$reset()
                })
              setTimeout(() => {
                this.submit.success = false
              }, 2000)
            }, 2000)
          })
          .catch((error) => {
            this.submit.inprogress = false
            this.submit.error = error.response.statusText
          })
      }
    },
    resetUI () {
      this.UI.messages = false
      this.UI.inbox = false
    },
    setMessageAsReaded (conversationId) {
      const token = Cookies.get('temp-usr')

      if (token) {
        axios.post(`/moder_1_5/message/${conversationId}`, null, { headers: getHeaders(token) })
          .then(() => {
            this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.commercialInfos[0].commercial_id })
          })
          .catch((e) => {
            return e
          })
      }
    },
    loadMessageInbox (index, conversationId, isMessageRead) {
      if (!this.clientParams && !isMessageRead) {
        this.setMessageAsReaded(conversationId)
      }
      this.messageInbox = this.userMessages[index]
      this.resetUI()
      this.UI.inbox = true
      this.setMsgScrollTop()
    },
    setDateLocale (timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    setMsgScrollTop () {
      setTimeout(() => {
        const container = document.getElementsByClassName('conversation')
        container[0].scrollTop = container[0].scrollHeight
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.message-container {
  @media (min-width: $breakpoint-large) {
    width: 70%;
  }
}

.messages {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media (min-width: $breakpoint-large) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem 3rem;
  }

  &__box {
    margin-bottom: 2rem;
    border-radius: $border-radius;
    overflow-y: hidden;
    border: solid $border-color 1px;
    border-radius: $border-radius;

    @media (min-width: $breakpoint-medium) {
      overflow-y: auto;
      max-height: 12.5rem;
    }

    &--empty {
      padding: 0.4rem 0.6rem;
    }
  }

  &__title {
    @extend %ext-title;
  }

  &__subtitle {
    @extend %ext-subTitle;
    color: var(--second-option-color);
    padding-bottom: 1rem;
  }

  &__text {
    @extend %ext-text;
  }

  &__return {
    margin-bottom: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    border: solid black 1px;
    border-radius: 50%;
    transition: background-color 200ms;

    img {
      margin-left: 0.5rem;
      width: 1rem;
      height: auto;
    }

    &:hover {
      background-color: $bg-color;
    }
  }

  &__contact {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 1rem;
  }
}

.message-item {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 0.4rem 0;
  color: $main-medium-color;
  font-weight: 500;
  border-top: solid $border-color 1px;

  &:first-child {
    border-top: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $bg-color;
    color: $main-dark-color;
  }

  &__date {
    max-width: 50%;
    width: 12.5rem;
    font-size: 0.9rem;
  }

  &__notification::before {
    content: " ";
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--main-option-color);
  }

  &__heading {
    max-width: 50%;
  }

  &__date,
  &__heading {
    padding-right: 2rem;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--unread {
    color: $main-dark-color;
    font-weight: 600;
  }
}

.lot-selector {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__label {
    margin-right: 0.4rem;
  }

  &__select {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 0 0.6rem;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        background-color: $bg-color;
        cursor: pointer;
      }
    }
  }
}

.contact {
  &__heading,
  &__textarea {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: solid $border-color 1px;
    border-radius: $border-radius;
  }

  &__heading {
    font-weight: 500;
    display: flex;
  }

  &__label {
    margin-right: 2rem;
  }

  &__textarea {
    border: solid $border-color 1px;
    width: 100%;
  }
}

.conversation {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  font-size: $fs-text;
  overflow: auto;
  height: 60%;

  &__heading,
  &__content {
    padding: 0.5rem 1rem;
  }

  &__heading {
    background-color: $bg-color;
    font-weight: 600;
    display: flex;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
  }

  &__text {
    margin: 1rem auto;
    padding-top: 1rem;
    border-top: solid $bg-color 1px;
  }

  &__timestamp,
  &__contact {
    padding: 0;
    margin: 0;
  }

  &__contact,
  &__subject {
    margin-bottom: 0.5rem;
  }
}

.conversation-msg {
  margin: 1rem 0;

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    p {
      font-size: $fs-small;
      font-weight: 600;
    }

    p:last-child {
      font-weight: 300;
    }

    &--right {
      flex-direction: row-reverse;
    }
  }

  &__icon {
    background-color: $bg-color;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;

    &--right {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  &__content {
    font-size: $fs-small;
    background-color: $bg-color;
    border-radius: 1.2rem;
    padding: 0.8rem;
    display: inline-block;

    &--right {
      background-color: $msg-color;
    }
  }

  &--right {
    text-align: right;
  }
}
</style>
