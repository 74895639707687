<template>
  <div id="btn-close" class="btn-close" aria-label="close" @click="closeBtnClicked" />
</template>

<script>
export default {
  name: 'UiBtnCLose',
  methods: {
    closeBtnClicked () {
      this.$emit('BtnCloseClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--main-option-color);
  padding-bottom: 1px;
  border-radius: 50%;
  z-index: 999;
  width: 2.2rem;
  height: 2.2rem;
  background-image: url("/icon/icon-cross-white.png");
  background-size: 0.8rem;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0.8rem;

  @media (min-width: $breakpoint-small) {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    background-size: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #cbc9c9ff;
  }
}
</style>
