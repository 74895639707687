import { render, staticRenderFns } from "./ProjectsItem.vue?vue&type=template&id=2370c212&scoped=true"
import script from "./ProjectsItem.vue?vue&type=script&lang=js"
export * from "./ProjectsItem.vue?vue&type=script&lang=js"
import style0 from "./ProjectsItem.vue?vue&type=style&index=0&id=2370c212&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2370c212",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2370c212')) {
      api.createRecord('2370c212', component.options)
    } else {
      api.reload('2370c212', component.options)
    }
    module.hot.accept("./ProjectsItem.vue?vue&type=template&id=2370c212&scoped=true", function () {
      api.rerender('2370c212', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Projects/ProjectsItem.vue"
export default component.exports