export default {
  name: 'routeMixin',
  methods: {
    $_getRouteForContext (index, params) {
      const programId = params[0]
      const routes = {
        login: {
          remote: `${process.env.MODERAPI}/login`,
          static: `${process.env.LOCAL_JSON}/login.json`,
        },
        user: {
          remote: `${process.env.MODERAPI}/user?programId=${programId}`,
          static: `${process.env.LOCAL_JSON}/${programId}/user.json`,
        },
        'program-info': {
          remote: `${process.env.MODERAPI}/programs-info/${params[0]}/${params[1]}`,
          static: `${process.env.LOCAL_JSON}/${params[0]}/${params[1]}/info.json`,
        },
        streaming: {
          remote: `${process.env.MODERAPI}/streaming/links/direct?programId=${programId}&flatId=${params[1]}&token=${params[2]}`,
          static: `${process.env.LOCAL_JSON}/${programId}/streaming.json`,
        },
        cart: {
          remote: `${process.env.MODERAPI}/cart/shopping`,
          static: `${process.env.LOCAL_JSON}/cart.json`,
        },
        cache: {
          remote: `${process.env.MODERAPI}/program/cache/${programId}`,
          static: `${process.env.LOCAL_JSON}/${programId}/cache.json`,
        },
      }
      return localStorage.getItem('isOnline') === 'true' ? routes[index].remote : routes[index].static
    },
  },
}
