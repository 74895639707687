
import Cookies from 'js-cookie'

export default function ({ store, route, redirect }, inject) {
  inject('isAuth', () => {
    const auth = Cookies.get('temp-usr')
    if (auth === undefined) {
      return false
    }
    return true
  })
}
