<template>
  <div class="modal-container" @click="closeOnClickOutside('modal-container')">
    <section class="user-infos-container">
      <UiBtnClose @BtnCloseClicked="$emit('clickOutside')" />
      <h2 class="user-infos__title">
        {{ $t('userInfos.title') }}
      </h2>
      <div class="user-infos__cards">
        <div class="user-card">
          <h3 class="user-card__heading">
            {{ $t('userInfos.acquirer') }}
          </h3>
          <div class="user-card__content">
            <p class="user-card__name">
              {{ user.firstName }} {{ user.lastName }}
            </p>
            <div class="user-card-contact">
              <p class="user-card-contact__item">
                {{ $t('userInfos.phone') }}
              </p>
              <p v-if="user.phone" class="user-card-contact__value">
                {{ user.phone }}
              </p>
              <p v-else class="user-card-contact__value">
                {{ $t('userInfos.noInfos') }}
              </p>
            </div>
            <div class="user-card-contact">
              <p class="user-card-contact__item">
                {{ $t('userInfos.email') }}
              </p>
              <p v-if="user.email" class="user-card-contact__value">
                {{ user.email }}
              </p>
              <p v-else class="user-card-contact__value">
                {{ $t('userInfos.noInfos') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <nuxt-link v-if="userStatus !== 'prospect' && buyerAccess" :to="{ name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }" class="user-infos__link" @click.native="closeModal">
        {{ $t('userInfos.action') }}
      </nuxt-link>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiBtnClose from '~/components/UI/UiBtnClose.vue'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  comonents: {
    UiBtnClose,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      buyerAccess: false,
    }
  },
  computed: {
    ...mapGetters({
      userStatus: 'user/getUserStatus',
      programData: 'program/getProgramData',
    }),
  },
  mounted () {
    document.body.classList.add('modal-open')
    this.buyerAccess = this.programData.buyer_access
  },
  beforeDestroy () {
    document.body.classList.remove('modal-open')
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList.value === containerClass) {
        this.$emit('clickOutside')
      }
    },
    closeModal () {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.user-infos-container {
  margin: auto 1rem;
  background-color: white;
  margin: auto;
  border-radius: $border-radius;
  padding: 2rem;
  text-align: left;
  position: relative;

  @media (min-width: $breakpoint-small) {
    min-width: 500px;
  }
}

.user-infos {
  &__title {
    @extend %ext-title;
  }

  &__cards {
      @media (min-width: $breakpoint-large) {
          display: flex;
          justify-content: space-between;
          gap: 2rem;
      }
  }

  &__link {
    text-decoration: underline;
  }
}

.user-card {
  padding-bottom: 1rem;
  width: 100%;

  &__heading {
    @extend %ext-subTitle;
  }

  &__content {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 1rem;
  }

  &__name {
    text-transform: uppercase;
    font-weight: 700;
  }

  &-contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;

    &__item {
      font-weight: 600;
    }
  }
}
</style>
