
export const state = () => ({
  packcom: null,
})

export const mutations = {
  SET_PACKCOM: (state, packcom) => {
    state.packcom = packcom
  },
}
