var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "customer-manager-link",
        on: {
          click: function ($event) {
            _vm.UI.managerCard = true
          },
        },
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("customerManager")) + "\n  ")]
    ),
    _vm._v(" "),
    _vm.UI.managerCard && _vm.commercialInfos
      ? _c(
          "div",
          {
            staticClass: "customer-manager-card",
            on: {
              click: function ($event) {
                return _vm.closeOnClickOutside("customer-manager-card")
              },
            },
          },
          [
            _c("ContactCard", {
              staticClass: "contact-card-item",
              attrs: { contact: _vm.commercialInfos },
              on: {
                cardLinkClicked: function ($event) {
                  return _vm.closeCard()
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }