var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "cookie-banner-panel" },
    [
      _c("UiBtnClose", {
        staticClass: "cookie-banner-panel__close-cta",
        on: {
          BtnCloseClicked: function ($event) {
            return _vm.emitClosePanel()
          },
        },
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "cookie-banner-panel__title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("cookie.panel.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "cookie-banner-panel__subtitle" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("cookie.panel.subtitle")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cookie-banner-panel-settings" }, [
        _c("div", { staticClass: "cookie-banner-panel-settings__card" }, [
          _c("h3", { staticClass: "cookie-banner-panel-settings__title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("cookie.panel.card1Title")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cookie-banner-panel-settings__content" }, [
            _c("p", { staticClass: "cookie-banner-panel-settings__text" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("cookie.panel.card1Text")) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cookie-banner-panel-settings__card" }, [
          _c("h3", { staticClass: "cookie-banner-panel-settings__title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("cookie.panel.card2Title")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cookie-banner-panel-settings__content" }, [
            _c("p", { staticClass: "cookie-banner-panel-settings__text" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("cookie.panel.card2Text")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "cookie-banner-panel-settings__cta",
                  class: {
                    "cookie-banner-panel-settings__cta--authorised-active":
                      _vm.cookies.settings === "&gtag=true",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setGtagCookie("&gtag=true")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("cookie.accept")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "cookie-banner-panel-settings__cta",
                  class: {
                    "cookie-banner-panel-settings__cta--refused-active":
                      _vm.cookies.settings === "&gtag=false",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setGtagCookie("&gtag=false")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("cookie.refuse")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }