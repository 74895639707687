var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.commercialInfos && _vm.commercialInfos.length > 0
    ? _c("section", { staticClass: "contact-card" }, [
        _c(
          "div",
          { staticClass: "contact-card__content" },
          [
            _c("p", { staticClass: "contact-card__name" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.commercialInfos[0].commercial_firstname) +
                  " " +
                  _vm._s(_vm.commercialInfos[0].commercial_lastname) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.commercialInfos[0].sales_address
              ? _c("div", { staticClass: "contact-card__address" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.commercialInfos[0].sales_address)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.linkActive
              ? _c(
                  "nuxt-link",
                  {
                    staticClass: "contact-card__link",
                    attrs: {
                      to: {
                        name: _vm.clientParams
                          ? "client-lotId-messages"
                          : "lotId-messages",
                        params: { lotId: _vm.lotIdParams },
                      },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.linkClicked()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("contact.action")) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "contact-card--empty" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }