export const state = () => ({
  areCookiesUpdateRequired: false,
  gaPluginCountLoadedTimes: 0,
})

export const mutations = {
  SET_COOKIES_UPDATE_STATE: (state, status) => {
    state.areCookiesUpdateRequired = status
  },
  INCREMENT_GA_TIMES: (state) => {
    state.gaPluginCountLoadedTimes = state.gaPluginCountLoadedTimes + 1
  },
  RESET_GA_TIMES: (state) => {
    state.gaPluginCountLoadedTimes = 0
  },
}

export const getters = {
  getCookiesUpdateState: (state) => {
    return state.areCookiesUpdateRequired
  },
  getGaCountTimes: (state) => {
    return state.gaPluginCountLoadedTimes
  },
}
