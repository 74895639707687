<template>
  <div id="map">
    <div id="mapContainer" ref="hereMap" />
  </div>
</template>

<script>
export default {
  name: 'HereMap',
  props: {
    center: {
      type: Object,
      default () {
        return {
          lat: 0,
          lng: 0,
        }
      },
    },
  },
  data () {
    return {
      platform: null,
      apikey: process.env.HERE_API_KEY,
      apiCode: process.env.HERE_API_CODE,
      pointOfInterest: {
        transports: null,
      },
    }
  },
  mounted () {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey,
    })
    this.platform = platform
    this.initializeHereMap()
  },
  methods: {
    initializeHereMap () { // rendering map
      const mapContainer = this.$refs.hereMap
      const H = window.H
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers()

      // Instantiate (and display) a map object:
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 16,
        center: this.center,
        pixelRatio: window.devicePixelRatio || 1,
      })

      map.getViewModel().setLookAtData({
        tilt: 70,
        heading: 60,
      })
      addEventListener('resize', () => map.getViewPort().resize())

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map))

      // add UI
      H.ui.UI.createDefault(map, maptypes)
      // End rendering the initial map
      const marker = new H.map.Marker(this.center)
      map.addObject(marker)
    },
  },
}
</script>

<style lang="scss" scoped>
#map {
  width: 80%;
  margin: auto;
  text-align: center;
  background-color: #ccc;

  @media (max-width: $breakpoint-small) {
    width: 100%;
  }
}

#mapContainer {
  width: auto;
  height: 300px;

  @media (min-width: $breakpoint-medium) {
    height: 600px;
  }
}
</style>
