export const state = () => ({
  choiceSheetsProgram: null,
  choiceSheets: null,
  choiceSheetsNotifications: 0,
})

export const mutations = {
  SET_CHOICE_SHEETS_PROGRAM: (state, data) => {
    state.choiceSheets = data
  },
  SET_CHOICE_SHEETS: (state, data) => {
    state.choiceSheets = data
  },
  SET_CHOICE_SHEETS_NOTIFICATIONS: (state, number) => {
    state.choiceSheetsNotifications = number
  },
  RESET_CHOICESHEETS: (state) => {
    state.choiceSheetsProgram = null
    state.choiceSheets = null
    state.choiceSheetsNotifications = 0
  },
}

export const getters = {
  getChoiceSheetsProgram: (state) => {
    return state.choiceSheetsProgram
  },
  getChoiceSheets: (state) => {
    return state.choiceSheets
  },
  getNotification: (state) => {
    return state.choiceSheetsNotifications
  },
}
