<template>
  <div v-if="program" class="program">
    <h2 class="program-title">
      {{ $t('program.title') }}
    </h2>
    <div class="program-tabs">
      <p class="program-tabs__item" :class="{'program-tabs__item--selected': UI.infos}" @click="setInfosUI">
        {{ $t('program.tabs.information') }}
      </p>
      <p v-if="programCoordinates" class="program-tabs__item" :class="{'program-tabs__item--selected': UI.localisation}" @click="setLocalisationUI">
        {{ $t('program.tabs.location') }}
      </p>
      <p v-if="programGalleryLength" class="program-tabs__item" :class="{'program-tabs__item--selected': UI.visuals}" @click="setVisualsUI">
        {{ $t('program.tabs.visuals') }}
      </p>
    </div>
    <div class="program-main">
      <section v-if="UI.infos" class="program-infos">
        <p v-if="programAddress" class="program-infos__adress">
          {{ programAddress }}
        </p>
        <div v-if="program.descriptions[$i18n.locale]" class="program-infos___desc" v-html="program.descriptions[$i18n.locale]" />
        <div v-if="program.typology" class="program-infos__boxes">
          <p v-for="(item, index) in program.typology" :key="index">
            {{ item }}
          </p>
        </div>
        <div v-if="floors" class="program-infos__boxes">
          <p v-for="(item, index) in floors" :key="index">
            {{ item.length > 1? item : 'R+'+ item }}
          </p>
        </div>
        <div class="program-infos__actions">
          <button class="cta program-infos__cta-visit" @click="openProgramStreamingLink()">
            {{ $t('cta.visitProgram') }}
          </button>
          <button v-if="formExternLinkConditions || formLinkConditions" class="cta cta--option program-infos__cta-infos" @click="UI.form.info = true">
            {{ $t('cta.getInfos') }}
          </button>
        </div>
      </section>
      <section v-if="UI.localisation" class="program-localisation">
        <LocalisationMap :center="programCoordinates" />
      </section>
      <section v-if="UI.visuals" class="program-visuals">
        <Gallery :gallery="gallery" @clickOnImage="requestFullscreen($event)" />
        <GalleryFullscreenZoom v-if="UI.galleryFullscreen" :gallery="gallery" :gallery-index="galleryFullscreenIndex" @BtnCloseClicked="UI.galleryFullscreen = false" @clickOutside="UI.galleryFullscreen = false" />
      </section>
    </div>
    <div v-if="UI.form.info" class="form-infos">
      <FormIntern v-if="formLinkConditions" @closeFormRequest="closeForm()" />
      <FormExternQr v-if="formExternLinkConditions" @closeFormRequest="closeForm()" />
    </div>
    <div v-if="UI.form.option" class="form-option">
      <img src="/images/form-option.png" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { sortFloors } from '~/plugins/helpers'
import { getStreamingLink } from '~/plugins/streaming'
import { getPackcomOrbitalImages } from '~/plugins/packcom'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      pointOfInterest: {
        transports: {
          metro: null,
          station: null,
        },
        school: {
          school: null,
          middleschool: null,
          highschool: null,
        },
      },
      UI: {
        visuals: false,
        localisation: false,
        infos: true,
        galleryFullscreen: false,
        form: {
          info: false,
          option: false,
        },
      },
      galleryFullscreenIndex: '0',
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      userStatus: 'user/getUserStatus',
      userRole: 'user/getUserRole',
      StreamingLink: 'program/getStreamingLink',
      linkTarget: 'options/getLinkTargetOption',
    }),
    formLinkConditions () {
      return ((this.userStatus === 'prospect' || this.userRole === 'admin') && this.program.promoterInfo.name !== 'QUARTUS')
    },
    formExternLinkConditions () {
      return ((this.userStatus === 'prospect' || this.userRole === 'admin') && this.program.promoterInfo.name === 'QUARTUS')
    },
    programAddress () {
      let programAddress = ''
      if (this.program) {
        const cityInfos = (this.program.programZipCode || '') + ' ' + (this.program.programCity || '')
        if (cityInfos) {
          programAddress = `${this.program.programStreetName || ''}  ${cityInfos || ''}`
        }
      }
      return programAddress
    },
    programCoordinates () {
      if (this.program.lat && this.program.lng) {
        return { lat: this.program.lat, lng: this.program.lng }
      }
      return null
    },
    programGalleryLength () {
      if (this.program && this.program.previewImage && this.program.previewImage.length) {
        return this.program && this.program.previewImage.length
      }
      return 0
    },
    floors () {
      return sortFloors(this.program.floor)
    },
  },
  mounted () {
    this.places('transport')
    this.places('school')
    this.setProgramGallery()
  },
  methods: {
    async setProgramGallery () {
      const packcom = await getPackcomOrbitalImages(this.$store?.state?.packcom?.packcom, this)
      let previewImage = []
      previewImage = this.program?.previewImage?.map(link => this.$prefixLinks(link))
      this.gallery = previewImage.concat(packcom)
    },
    setLocalisationUI () {
      this.UI.localisation = true
      this.UI.infos = false
      this.UI.visuals = false
    },
    setInfosUI () {
      this.UI.localisation = false
      this.UI.infos = true
      this.UI.visuals = false
    },
    setVisualsUI () {
      this.UI.localisation = false
      this.UI.infos = false
      this.UI.visuals = true
    },
    closeForm (containerClass) {
      this.UI.form.info = false
      this.UI.form.option = false
    },
    requestFullscreen (index) {
      this.UI.galleryFullscreen = true
      this.galleryFullscreenIndex = index.toString()
    },
    filterPlacesResponseStartsWith (response, param) {
      const results = response.filter(x => (x.title.startsWith(param)) && (x.distance < 1000))
      if (results.length > 0) {
        return results
      }
    },
    places (query) {
      return axios.get(`https://places.ls.hereapi.com/places/v1/discover/search?app_id=${process.env.HERE_API_CODE}&apiKey=${process.env.HERE_API_KEY}&at=${this.program.lat},${this.program.lng}&q=${query}`)
        .then((response) => {
          return response.data.results.items
        })
        .then((response) => {
          if (query === 'transport') {
            this.pointOfInterest.transports.metro = this.filterPlacesResponseStartsWith(response, 'Métro')
            this.pointOfInterest.transports.station = this.filterPlacesResponseStartsWith(response, 'SNCF')
          }
          if (query === 'school') {
            this.pointOfInterest.school.school = this.filterPlacesResponseStartsWith(response, 'École')
            this.pointOfInterest.school.middleschool = this.filterPlacesResponseStartsWith(response, 'Collège')
            this.pointOfInterest.school.highschool = this.filterPlacesResponseStartsWith(response, 'Lycée')
          }
        })
        .catch((e) => {
          return e
        })
    },
    async openProgramStreamingLink () {
      const token = await Cookies.get('temp-usr')
      const { link } = await getStreamingLink({ programId: this.program.programId, token })

      if (this.linkTarget === '_self') {
        window.location.href = link
      } else {
        window.open(link, this.linkTarget)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.program {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;

  &-main {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 1rem;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    height: 100%;
  }

  &-title {
    @extend %ext-title;
  }

  &-tabs {
    display: flex;
    @extend %ext-tab;
    width: fit-content;
    position: relative;
    padding-bottom: 1rem;

    &__item {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      border-bottom: solid $border-color 2px;
      text-transform: capitalize;
      transition: border-bottom 200ms;

      &:hover {
        border-bottom: solid var(--second-option-color) 2px;
        cursor: pointer;
      }

      &--selected {
        border-bottom: solid var(--second-option-color) 2px;
        color: var(--second-option-color);
        font-weight: 700;

        &:hover {
          cursor: default;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-infos {
    &__adress {
      font-size: $fs-subTitle;
      color: var(--second-option-color);
      padding-bottom: 1rem;
    }

    &___desc {
      @extend %ext-text;
      margin-bottom: 1rem;
    }

    &__boxes {
      > p {
        display: inline-block;
        border: solid $main-dark-color 1px;
        padding: 2px 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
      }
    }

    &__actions {
      margin-top: 2rem;
    }

    &__desc {
      margin: 2rem;
      color: $main-medium-color;
    }

    &__link {
      text-decoration: underline;
      margin-bottom: 1rem;

      &:hover {
        color: var(--second-option-color);
        cursor: pointer;
      }
    }
    &__cta-visit,
    &__cta-infos {
      min-width: 20rem;;
    }

    &__cta-visit:after {
      content: "";
      display: block;
      background: url("/icon/icon-forward-white.png");
      background-size: 0.8rem auto;
      background-repeat: no-repeat;
      height: 1.2rem;
      width: 1rem;
      float: right;
      margin: 0.1rem 0.8rem;
    }

    &__cta-image {
      height: 0.9rem;
      width: auto;
      padding-left: 1rem;
    }
  }
  &-localisation,
  &-visuals {
    text-align: left;
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-medium) {
      height: 80%;
    }
  }
  &-localisation {
    margin: auto;
    width: 100%;
  }
}
</style>
