var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _vm.UI.formIndex
        ? _c(
            "div",
            { staticClass: "info-index" },
            [
              _c("UiBtnClose", {
                on: {
                  BtnCloseClicked: function ($event) {
                    return _vm.closeForm()
                  },
                },
              }),
              _vm._v(" "),
              _c("section", { staticClass: "info-form" }, [
                _c(
                  "button",
                  {
                    staticClass: "cta info-form__btn",
                    on: {
                      click: function ($event) {
                        return _vm.openFormInfo()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("contactIndex.btn.info")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "cta info-form__btn",
                    on: {
                      click: function ($event) {
                        return _vm.openFormAppointment()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("contactIndex.btn.appointment")) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass: "demonstration",
                  style: {
                    backgroundImage: "url(" + _vm.programBackground + ")",
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "demonstration-btn",
                      on: {
                        click: function ($event) {
                          return _vm.openFormDemo()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("contactIndex.btn.multiplayerDemo")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("header", { staticClass: "demonstration-header" }, [
                    _c("h2", { staticClass: "demonstration-header__title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("contactIndex.demonstration.title"))
                      ),
                      _c(
                        "p",
                        { staticClass: "demonstration-header__subtitle" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("contactIndex.demonstration.title2")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "demonstration-header__description" },
                      [
                        _c("ol", [
                          _c("li", [
                            _vm._v("1. "),
                            _c("span", { staticClass: "header-link" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "contactIndex.demonstration.footer.here"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contactIndex.demonstration.footer.step1"
                                )
                              ) + " "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contactIndex.demonstration.footer.step2"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contactIndex.demonstration.footer.step3"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.formInfo
        ? _c("FormInternInfo", {
            on: {
              btnReturnInfoClicked: _vm.resetFormUi,
              requestToCloseForm: function ($event) {
                return _vm.closeForm()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.formAppointment
        ? _c("FormInternAppointment", {
            on: {
              btnReturnInfoClicked: _vm.resetFormUi,
              requestToCloseForm: function ($event) {
                return _vm.closeForm()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.formDemo
        ? _c("FormInternDemo", {
            on: {
              btnReturnInfoClicked: _vm.resetFormUi,
              requestToCloseForm: function ($event) {
                return _vm.closeForm()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "demonstration__img" }, [
      _c("img", { attrs: { src: "/images/immersive_white.png", alt: "logo" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }