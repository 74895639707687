<template>
  <div v-if="program" class="visit-link">
    <div class="circle-ext" />
    <a :href="link" :target="linkTarget" class="circle-int">
      <img :src="programLogoFiltered" alt="logo" class="circle-int__img">
      <div>
        <h1 class="circle-int__title">
          {{ program.title }}
        </h1>
        <p class="circle-int__city">
          {{ program.programCity }}
        </p>
      </div>
      <a :href="link" :target="linkTarget" class="visit-link--cta">{{ $t('cta.visit') }}</a>
    </a>
    <div class="circle-int__bottom" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    programBackground: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      ftp: process.env.FTP,
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      linkTarget: 'options/getLinkTargetOption',
    }),
    programLogoFiltered () {
      const logo = this.program
        ? this.program.programLogo || this.program.promoterInfo.logo.main
        : null
      return logo ? this.$prefixLinks(logo) : null
    },
  },
}
</script>

<style lang="scss" scoped>
.visit-link {
  background-position: center;
  background-size: cover;
  font-weight: 700;
  margin: 0;
  border-radius: 0;

  &--cta {
    padding: 0.8em 3em;
    background-color: var(--main-option-color);
    color: $main-light-color;
    border-radius: $border-radius;
    letter-spacing: 1px;
    font-size: 2vmin;
    text-transform: uppercase;
    transition: background-color 300ms;
    margin-bottom: 4%;

    @media (max-width: $breakpoint-large) {
      font-size: 3vmin;
    }

    &:hover {
      background-color: var(--second-option-color);
    }
  }
}

.circle-int {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50vmin;
  height: 50vmin;
  min-width: 13.75rem;
  min-height: 13.75rem;
  background-color: #ffffffb7;
  color: $main-dark-color;
  border: solid #ffffffb7 0px;
  transition: background-color 200ms 50ms, box-shadow 200ms 50ms,
    transform 400ms;

  &:hover {
    cursor: pointer;
    background-color: $transparent-medium-color;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }

  &__title,
  &__city,
  &__text {
    text-transform: uppercase;
  }

  &__title {
    font-size: 3.2vmin;
    font-weight: 900;
  }

  &__city {
    font-size: 2.6vmin;
    font-weight: 300;
    letter-spacing: 1px;
  }

  &__text {
    font-size: 2.6vmin;
    font-weight: 300;
  }

  &__img {
    margin-top: 4%;
    height: auto;
    max-height: 10vmin;
    width: auto;
    max-width: 31vmin;
  }

  &__bottom {
    height: 2rem;
  }

  @media (max-width: 890px) {
    width: 70vmin;
    height: 70vmin;

    &__city {
      font-size: 10px;
      font-size: 3vmin;
    }

    &__title {
      font-size: 12px;
      font-size: 4vmin;
    }
    &__img {
      height: auto;
      max-height: 14vmin;
      width: auto;
      max-width: 46vmin;
    }
  }
}

.circle-int,
.circle-ext {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  animation: reveallight 300ms;
}

.circle-ext {
  background-color: $transparent-light-color;
  width: 58vmin;
  height: 58vmin;
  min-width: 15.625rem;
  min-height: 15.625rem;

  @media (max-width: 890px) {
    width: 80vmin;
    height: 80vmin;
  }
}
</style>
