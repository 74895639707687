var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "news"
    ? _c("div", { staticClass: "notifications__news" }, [
        _c("img", {
          staticClass: "notifications__icon",
          attrs: { src: "/icon/icon-news-white.png", alt: "notification" },
        }),
        _vm._v(
          "\n  " +
            _vm._s(_vm.unread) +
            " " +
            _vm._s(_vm.$tc("notifications.news", _vm.unread)) +
            "\n"
        ),
      ])
    : _vm.type === "messages"
    ? _c("div", { staticClass: "notifications__messages" }, [
        _c("img", {
          staticClass: "notifications__icon",
          attrs: { src: "/icon/icon-messages-white.png", alt: "notification" },
        }),
        _vm._v(
          "\n  " +
            _vm._s(_vm.unread) +
            " " +
            _vm._s(_vm.$tc("notifications.messages", _vm.unread)) +
            "\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }