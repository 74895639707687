import MobileDetect from 'mobile-detect'

export default function ({ redirect, route }, inject) {
  inject('isMobile', () => {
    if (process.client) {
      return isMobileDetection(window)
    }
  })
}

function isMobileDetection (window) {
  try {
    const mb = new MobileDetect(window.navigator.userAgent)
    let result = false
    if (mb.tablet() != null) {
      result = false
    } else if (mb.mobile() != null) {
      result = true
    }
    return result
  } catch (error) {
    return error
  }
}
