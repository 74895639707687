var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content || _vm.title
    ? _c("section", { staticClass: "dashboard-last-item" }, [
        _c("div", { staticClass: "dashboard-last-item__wrapper" }, [
          _c("div", { staticClass: "dashboard-last-item__title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "dashboard-last-item__date" }, [
            _vm._v("\n      " + _vm._s(_vm.date) + "\n    "),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "dashboard-last-item__content" }, [
          _vm._v("\n    " + _vm._s(_vm.content) + "\n  "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }