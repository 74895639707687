<template>
  <section class="modal-container newsReader" @click="closeOnClickOutside('modal-container')">
    <div class="newsReader__container">
      <UiBtnClose class="newsReader__closeBtn" @BtnCloseClicked="$emit('closeModalRequest')" />
      <h2 class="newsReader__title">
        {{ news.title }}
      </h2>
      <div v-html="news.content" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    news: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList[0] === containerClass) {
        this.$emit('closeModalRequest')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.newsReader {
  overflow: scroll;
  padding: 2rem 0;

  &__container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 2rem;
    border-radius: $border-radius;
    background-color: $main-light-color;
    animation: reveallight 400ms;
    position: relative;
  }

  &__title {
    padding-bottom: 2rem;
    @extend %ext-subTitle;
  }
}
</style>
