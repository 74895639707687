import axios from 'axios'

export const state = () => ({
  news: null,
  notification: 0,
})
export const actions = {
  async loadProgramNews ({ commit, dispatch }, { programId, userId, headers, flatId, subProgramId }) {
    try {
      if (flatId && subProgramId) {
        const response = await axios.get(`/moder_1_5/program-post?programId=${programId}&userId=${userId}&subProgramId=${subProgramId}&flatId=${flatId}&behalf=true`, { headers })
        const newPostData = response.data.postData
        commit('SET_PROGRAM_NEWS', response.data)
        dispatch('checkNewsNotifications', newPostData)

        return response
      } else {
        const response = await axios.get(`/moder_1_5/program-post?programId=${programId}&userId=${userId}`, { headers })
        const newPostData = response.data.postData
        commit('SET_PROGRAM_NEWS', response.data)
        dispatch('checkNewsNotifications', newPostData)
        return response
      }
    } catch (error) {
      return error
    }
  },
  checkNewsNotifications ({ commit }, postData) {
    const notifications = postData.filter(news => news.read === false)
    commit('SET_NEWS_NOTIFICATION', notifications.length)
  },
  setNewsStatusRead ({ commit }, { newsId, userId, headers }) {
    axios.post(`/moder_1_5/program-post/${newsId}/${userId}`, null, { headers })
  },
}

export const mutations = {
  SET_PROGRAM_NEWS: (state, programNews) => {
    if (programNews) {
      state.news = programNews
    }
  },
  RESET_NEWS: (state) => {
    state.news = null
  },
  SET_NEWS_NOTIFICATION: (state, programNews) => {
    state.notification = programNews || 0
  },
  RESET_NEWS_NOTIFICATION: (state) => {
    state.notification = 0
  },
}

export const getters = {
  getProgramNews: (state) => {
    return state.news
  },
  getNotification: (state) => {
    return state.notification
  },
}
