var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.program
    ? _c("div", { staticClass: "visit-link" }, [
        _c("div", { staticClass: "circle-ext" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "circle-int",
            attrs: { href: _vm.link, target: _vm.linkTarget },
          },
          [
            _c("img", {
              staticClass: "circle-int__img",
              attrs: { src: _vm.programLogoFiltered, alt: "logo" },
            }),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "circle-int__title" }, [
                _vm._v("\n        " + _vm._s(_vm.program.title) + "\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "circle-int__city" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.program.programCity) + "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "visit-link--cta",
                attrs: { href: _vm.link, target: _vm.linkTarget },
              },
              [_vm._v(_vm._s(_vm.$t("cta.visit")))]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "circle-int__bottom" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }