<template>
  <div class="main-menu">
    <VisitLinkSmall class="visit-box" :program-background="programBackground" />
    <div v-if="userStatus === 'acquirer'" class="navigation-box">
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-home-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.dashboard') }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-lot' : 'lotId-lot', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-mon-bien-white.png" alt="icon program" class="menu-icon">
        <p>{{ $tc('menu.lot', userLotsLength) }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-program-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.program') }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-project' : 'lotId-project', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-my-project-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.projects') }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-docs' : 'lotId-docs', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-documents-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.docs') }}</p>
        <div v-if="notificationsChoiceSheets > 0" class="notifications navigation-box-notification">
          {{ notificationsChoiceSheets }}
        </div>
      </nuxt-link>
      <nuxt-link v-if="commercialInfos && commercialInfos.length > 0" :to="{name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-messages-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.messages') }}</p>
        <div v-if="notificationsMessages > 0" class="notifications navigation-box-notification">
          {{ notificationsMessages }}
        </div>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-construction-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.construction') }}</p>
        <div v-if="notificationsNews > 0" class="notifications navigation-box-notification">
          {{ notificationsNews }}
        </div>
      </nuxt-link>
    </div>
    <div v-else class="navigation-box">
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-home-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.dashboard') }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-program-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.program') }}</p>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-project' : 'lotId-project', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-my-project-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.projects') }}</p>
      </nuxt-link>
      <nuxt-link v-if="commercialInfos && commercialInfos.length > 0" :to="{name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-messages-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.messages') }}</p>
        <div v-if="notificationsMessages > 0" class="notifications navigation-box-notification">
          {{ notificationsMessages }}
        </div>
      </nuxt-link>
      <nuxt-link :to="{ name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }">
        <img src="/icon/icon-construction-white.png" alt="icon program" class="menu-icon">
        <p>{{ $t('menu.construction') }}</p>
        <div v-if="notificationsNews > 0" class="notifications navigation-box-notification">
          {{ notificationsNews }}
        </div>
      </nuxt-link>
    </div>
    <div class="cookies-link-container">
      <p class="cookies-link-container__link" @click="OpenCookiesSettings()">
        Cookies
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
    }
  },
  computed: {
    ...mapGetters({
      userStatus: 'user/getUserStatus',
      commercialInfos: 'program/commercialInfos',
      notificationsNews: 'news/getNotification',
      notificationsMessages: 'messages/getNotification',
      notificationsChoiceSheets: 'choiceSheet/getNotification',
      userLotsLength: 'lot/getLotsLength',
    }),
    programBackground () {
      return this.$prefixLinks(this.$store.state.program.programData.background)
    },
  },
  methods: {
    OpenCookiesSettings () {
      this.$store.commit('cookies/SET_COOKIES_UPDATE_STATE', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.main-menu {
  display: none;

  @media (min-width: $breakpoint-large) {
    background-color: var(--main-option-color);
    color: $main-light-color;
    min-height: 100%;
    width: 20%;
    min-width: 20%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.navigation-box:hover a {
  background-color: var(--main-option-color);
}

.navigation-box {
  position: relative;

  a {
    display: flex;
    align-items: center;
    padding: 0.9rem;
    max-height: 64px;
    font-weight: 500;
    text-transform: capitalize;
    transition: background-color 200ms;

    &:hover {
      background-color: var(--second-option-color);
    }
  }
  a p {
    font-size: $fs-link;
  }
}

.navigation-box-notification {
  font-weight: 600;
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.visit-box {
  display: none;

  @media (min-width: $breakpoint-medium) {
    height: auto;
    max-height: 40%;
    min-height: 20vw;
    display: flex;
    flex-grow: 1;
    position: relative;
  }
}

.menu-icon {
  height: auto;
  width: 1rem;
  margin-right: 1rem;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
}

.nuxt-link-active {
  background-color: var(--second-option-color);
}
</style>
