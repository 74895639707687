<template>
  <a class="powered-by-box" :href="website" target="_blank" :style="`position: ${position};`">
    <img src="~/assets/img/powered-by-moderlab.svg">
  </a>
</template>

<script>
import { WEBSITE_LINK } from '~/plugins/constants.js'

export default {
  props: {
    position: {
      type: String,
      default: 'absolute',
    },
  },
  data () {
    return {
      website: WEBSITE_LINK,
    }
  },
}
</script>

<style lang="scss" scoped>
.powered-by-box {
    z-index: $z-index-powered;
    bottom: 0;
    right: 0;

    img {
      width: 7rem;
      height: 1.35rem;
      pointer-events: none;
      position: relative;
      z-index: -1;
    }

    @media (max-width: $breakpoint-small) {
      display: none;
    }
}
</style>
