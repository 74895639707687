<template>
  <div>
    <p class="customer-manager-link" @click="UI.managerCard = true">
      {{ $t('customerManager') }}
    </p>
    <div v-if="UI.managerCard && commercialInfos" class="customer-manager-card" @click="closeOnClickOutside('customer-manager-card')">
      <ContactCard :contact="commercialInfos" class="contact-card-item" @cardLinkClicked="closeCard()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      UI: {
        managerCard: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      commercialInfos: 'program/commercialInfos',
    }),
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList.value === containerClass) {
        this.closeCard()
      }
    },
    closeCard () {
      this.UI.managerCard = false
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-manager-link {
  text-decoration: underline;
  color: var(--second-option-color);
  padding-bottom: 0.4rem;

  &:hover {
    cursor: pointer;
  }
}

.customer-manager-card {
  z-index: $z-index-modal;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contact-card-item {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
