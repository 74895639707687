import { getConvertColor } from '@/plugins/convertColorToHex.js'

export default ({ store }) => {
  setTimeout(() => {
    let programInfoColors
    if (store.state.program && store.state.program.programData) {
      programInfoColors = store.state.program.programData.promoterInfo.colors
    }
    const optionColors = []
    if (store.state.program && programInfoColors) {
      for (let i = 0; i < programInfoColors.length; i++) {
        const newColor = getConvertColor(programInfoColors[i])
        optionColors.push(newColor)
      }
    }
    const element = document.querySelector('body')
    element.style.setProperty('--main-option-color', optionColors[0] ? optionColors[0] : '#1A1A1Aff')
    element.style.setProperty('--second-option-color', optionColors[1] ? optionColors[1] : '#746649ff')
    element.style.setProperty('--third-option-color', optionColors[2] ? optionColors[2] : '#1A1A1Aff')
  }, 200)
}
