var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "indexContainer" },
        [
          _vm.isProgramApiStockLoaded === "PENDING" ? _c("Loader") : _vm._e(),
          _vm._v(" "),
          _vm.isProgramApiStockLoaded === "ERROR"
            ? _c("p", [_vm._v("\n      Une erreur s'est produite\n    ")])
            : _vm._e(),
          _vm._v(" "),
          _vm.contactIndexDisplay === true &&
          _vm.isProgramApiStockLoaded === "LOADED"
            ? _c(
                "div",
                { staticClass: "contactMenu" },
                [
                  _c("UiBtnClose", {
                    on: {
                      BtnCloseClicked: function ($event) {
                        return _vm.$emit("closeFormRequest")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("section", { staticClass: "infoBox" }, [
                    _c("h2", { staticClass: "infoBox__title" }, [
                      _vm._v("\n          S'INFORMER\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "cta",
                        attrs: { id: "infoBox__button" },
                        on: {
                          click: function ($event) {
                            return _vm.openQrFormInfo()
                          },
                        },
                      },
                      [_vm._v("\n          CONTINUER\n        ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "appointmentBox" }, [
                    _c("h2", { staticClass: "appointmentBox__title" }, [
                      _vm._v("\n          PRENDRE RENDEZ-VOUS\n        "),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttonBox" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cta",
                          attrs: { id: "cta__phone" },
                          on: {
                            click: function ($event) {
                              return _vm.openQrFormAppointment(
                                "phone",
                                "par téléphone"
                              )
                            },
                          },
                        },
                        [_vm._v("\n            par telephone\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cta",
                          attrs: { id: "cta__visio" },
                          on: {
                            click: function ($event) {
                              return _vm.openQrFormAppointment(
                                "visio",
                                "en visioconférence"
                              )
                            },
                          },
                        },
                        [_vm._v("\n            visioconférence\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cta",
                          attrs: { id: "cta__home" },
                          on: {
                            click: function ($event) {
                              return _vm.openQrFormAppointment(
                                "home",
                                "à mon domicile"
                              )
                            },
                          },
                        },
                        [_vm._v("\n            au domicile\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cta",
                          attrs: { id: "cta__office" },
                          on: {
                            click: function ($event) {
                              return _vm.openQrFormAppointment(
                                "office",
                                "à l'espace de vente"
                              )
                            },
                          },
                        },
                        [_vm._v("\n            Espace de vente\n          ")]
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactInfoDisplay === true
            ? _c("FormExternQrInfo", {
                on: {
                  btnReturnInfoClicked: _vm.btnReturnFromInfoClicked,
                  closeRequest: function ($event) {
                    return _vm.$emit("closeFormRequest")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.contactApptDisplay === true
            ? _c("FormExternQrAppt", {
                attrs: { "appt-type": _vm.apptType, "appt-text": _vm.apptText },
                on: {
                  btnReturnApptClicked: _vm.btnReturnFromApptClicked,
                  closeRequest: function ($event) {
                    return _vm.$emit("closeFormRequest")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }