import Cookies from 'js-cookie'

export default function ({ store }, inject) {
  inject('storeRouteBackup', (route) => {
    return new Promise((resolve, reject) => {
      Cookies.set('temp-route',
        JSON.stringify({
          name: route.name,
          params: {
            promoter: route.params.promoter || undefined,
            program: route.params.program || undefined,
            client: route.params.client || undefined,
            lotId: route.params.lotId || undefined,
          },
        }))
      return resolve()
    })
  })
}
