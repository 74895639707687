
export const state = () => ({
  lang: 'fr',
})

export const mutations = {
  setLanguage: (state, lang) => {
    if (lang) {
      state.lang = lang
    }
  },
}

export const getters = {
  lang: (state) => {
    return state.lang
  },
}
