import { REGEX_IMG } from '~/plugins/constants'

export function getLotPlans (lotData) {
  let plans = []

  if (lotData.plan3d && lotData.topUrl) {
    const plans3D = Object.values(lotData.plan3d)
    const plans2D = Object.values(lotData.topUrl)
    plans = (plans3D.filter(val => REGEX_IMG.test(val))).concat(plans2D.filter(val => REGEX_IMG.test(val)))
  }

  return plans
}

export function getLotPlans3D (lotData) {
  let plans = []

  if (lotData.plan3d) {
    const array = Object.values(lotData.plan3d)
    plans = array.filter(val => REGEX_IMG.test(val))
  }

  return plans
}

export function getLotPlans2D (lotData) {
  const array = Object.values(lotData.topUrl)

  return array.filter(val => REGEX_IMG.test(val))
}
