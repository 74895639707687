import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6bf5e9a4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e0a26a6 = () => interopDefault(import('../pages/_promoter/index.vue' /* webpackChunkName: "pages/_promoter/index" */))
const _42779964 = () => interopDefault(import('../pages/_promoter/_program/index.vue' /* webpackChunkName: "pages/_promoter/_program/index" */))
const _c2fd037c = () => interopDefault(import('../pages/_promoter/_program/access.vue' /* webpackChunkName: "pages/_promoter/_program/access" */))
const _08c28eaf = () => interopDefault(import('../pages/_promoter/_program/construction.vue' /* webpackChunkName: "pages/_promoter/_program/construction" */))
const _bb9bfdce = () => interopDefault(import('../pages/_promoter/_program/docs.vue' /* webpackChunkName: "pages/_promoter/_program/docs" */))
const _5201e1bd = () => interopDefault(import('../pages/_promoter/_program/home.vue' /* webpackChunkName: "pages/_promoter/_program/home" */))
const _0c1f3c7b = () => interopDefault(import('../pages/_promoter/_program/login.vue' /* webpackChunkName: "pages/_promoter/_program/login" */))
const _f8179a3a = () => interopDefault(import('../pages/_promoter/_program/lot.vue' /* webpackChunkName: "pages/_promoter/_program/lot" */))
const _7f7d9caa = () => interopDefault(import('../pages/_promoter/_program/messages.vue' /* webpackChunkName: "pages/_promoter/_program/messages" */))
const _a3d8e0d4 = () => interopDefault(import('../pages/_promoter/_program/program.vue' /* webpackChunkName: "pages/_promoter/_program/program" */))
const _39d269ab = () => interopDefault(import('../pages/_promoter/_program/project.vue' /* webpackChunkName: "pages/_promoter/_program/project" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _6bf5e9a4,
    name: "index"
  }, {
    path: "/:promoter",
    component: _5e0a26a6,
    name: "promoter"
  }, {
    path: "/:promoter/:program",
    component: _42779964,
    name: "promoter-program"
  }, {
    path: "/:promoter/:program/access",
    component: _c2fd037c,
    name: "promoter-program-access"
  }, {
    path: "/:promoter/:program/construction",
    component: _08c28eaf,
    name: "promoter-program-construction"
  }, {
    path: "/:promoter/:program/docs",
    component: _bb9bfdce,
    name: "promoter-program-docs"
  }, {
    path: "/:promoter/:program/home",
    component: _5201e1bd,
    name: "promoter-program-home"
  }, {
    path: "/:promoter/:program/login",
    component: _0c1f3c7b,
    name: "promoter-program-login"
  }, {
    path: "/:promoter/:program/lot",
    component: _f8179a3a,
    name: "promoter-program-lot"
  }, {
    path: "/:promoter/:program/messages",
    component: _7f7d9caa,
    name: "promoter-program-messages"
  }, {
    path: "/:promoter/:program/program",
    component: _a3d8e0d4,
    name: "promoter-program-program"
  }, {
    path: "/:promoter/:program/project",
    component: _39d269ab,
    name: "promoter-program-project"
  }, {
    path: "/:promoter/:program/espace-client/",
    component: _42779964,
    name: "client"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?",
    component: _42779964,
    name: "client-lotId"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/access",
    component: _c2fd037c,
    name: "client-lotId-access"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/construction",
    component: _08c28eaf,
    name: "client-lotId-construction"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/docs",
    component: _bb9bfdce,
    name: "client-lotId-docs"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/home",
    component: _5201e1bd,
    name: "client-lotId-home"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/index",
    component: _42779964,
    name: "client-lotId-index"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/login",
    component: _0c1f3c7b,
    name: "client-lotId-login"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/lot",
    component: _f8179a3a,
    name: "client-lotId-lot"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/messages",
    component: _7f7d9caa,
    name: "client-lotId-messages"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/program",
    component: _a3d8e0d4,
    name: "client-lotId-program"
  }, {
    path: "/:promoter/:program/espace-client/:lotId?/project",
    component: _39d269ab,
    name: "client-lotId-project"
  }, {
    path: "/:promoter/:program/:lotId?/",
    component: _42779964,
    name: "lotId"
  }, {
    path: "/:promoter/:program/:lotId?/access",
    component: _c2fd037c,
    name: "lotId-access"
  }, {
    path: "/:promoter/:program/:lotId?/construction",
    component: _08c28eaf,
    name: "lotId-construction"
  }, {
    path: "/:promoter/:program/:lotId?/docs",
    component: _bb9bfdce,
    name: "lotId-docs"
  }, {
    path: "/:promoter/:program/:lotId?/home",
    component: _5201e1bd,
    name: "lotId-home"
  }, {
    path: "/:promoter/:program/:lotId?/index",
    component: _42779964,
    name: "lotId-index"
  }, {
    path: "/:promoter/:program/:lotId?/login",
    component: _0c1f3c7b,
    name: "lotId-login"
  }, {
    path: "/:promoter/:program/:lotId?/lot",
    component: _f8179a3a,
    name: "lotId-lot"
  }, {
    path: "/:promoter/:program/:lotId?/messages",
    component: _7f7d9caa,
    name: "lotId-messages"
  }, {
    path: "/:promoter/:program/:lotId?/program",
    component: _a3d8e0d4,
    name: "lotId-program"
  }, {
    path: "/:promoter/:program/:lotId?/project",
    component: _39d269ab,
    name: "lotId-project"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
