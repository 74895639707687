<template>
  <section class="modal-container" @click="closeOnClickOutside('modal-container')">
    <div class="indexContainer">
      <Loader v-if="isProgramApiStockLoaded === 'PENDING'" />
      <p v-if="isProgramApiStockLoaded === 'ERROR'">
        Une erreur s'est produite
      </p>
      <div v-if="contactIndexDisplay === true && isProgramApiStockLoaded === 'LOADED'" class="contactMenu">
        <UiBtnClose @BtnCloseClicked="$emit('closeFormRequest')" />
        <section class="infoBox">
          <h2 class="infoBox__title">
            S'INFORMER
          </h2>
          <button id="infoBox__button" class="cta" @click="openQrFormInfo()">
            CONTINUER
          </button>
        </section>
        <section class="appointmentBox">
          <h2 class="appointmentBox__title">
            PRENDRE RENDEZ-VOUS
          </h2>
          <div class="buttonBox">
            <div id="cta__phone" class="cta" @click="openQrFormAppointment('phone', 'par téléphone')">
              par telephone
            </div>
            <div id="cta__visio" class="cta" @click="openQrFormAppointment('visio', 'en visioconférence')">
              visioconférence
            </div>
            <div id="cta__home" class="cta" @click="openQrFormAppointment('home', 'à mon domicile')">
              au domicile
            </div>
            <div id="cta__office" class="cta" @click="openQrFormAppointment('office', 'à l\'espace de vente')">
              Espace de vente
            </div>
          </div>
        </section>
      </div>
      <FormExternQrInfo
        v-if="contactInfoDisplay === true"
        class=""
        @btnReturnInfoClicked="btnReturnFromInfoClicked"
        @closeRequest="$emit('closeFormRequest')"
      />
      <FormExternQrAppt
        v-if="contactApptDisplay === true"
        class=""
        :appt-type="apptType"
        :appt-text="apptText"
        @btnReturnApptClicked="btnReturnFromApptClicked"
        @closeRequest="$emit('closeFormRequest')"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      contactIndexDisplay: true,
      contactInfoDisplay: false,
      contactApptDisplay: false,
      apptType: '',
      errorMsg: 'UNE ERREUR EST SURVENUE',
      apiStockStatus: 'loading',
    }
  },
  computed: {
    ...mapGetters({
      isProgramApiStockLoaded: 'programQrStock/getQrApiLoadStatus',
      program: 'program/getProgramData',
    }),
  },
  mounted () {
    this.loadQrStockProgramInfos(this.program.technicalId)
  },
  methods: {
    ...mapActions({
      loadQrStockProgramInfos: 'programQrStock/loadQrStockProgramInfos',
    }),
    closeOnClickOutside (containerClass) {
      if (event.target.classList[0] === containerClass) {
        this.$emit('closeFormRequest')
      }
    },
    openQrFormInfo () {
      this.contactIndexDisplay = false
      this.contactInfoDisplay = true
    },
    openQrFormAppointment (type, text) {
      this.contactIndexDisplay = false
      this.contactApptDisplay = true
      this.contactInfoDisplay = false
      this.apptType = type
      this.apptText = text
    },
    btnReturnFromInfoClicked () {
      this.contactIndexDisplay = true
      this.contactInfoDisplay = false
    },
    btnReturnFromApptClicked () {
      this.contactIndexDisplay = true
      this.contactApptDisplay = false
    },
  },
}
</script>

<style lang="scss" scoped>
.indexContainer {
  width: 100%;
  height: 100%;
  background-color: $main-light-color;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    margin: auto;
    width: 84%;
    height: 84%;
  }
  @media (min-width: $breakpoint-large) {
    margin: auto;
  }
}

.contactMenu {
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: $breakpoint-medium) {
    display: flex;
  }
}

.infoBox,
.appointmentBox {
  width: 100%;
  height: 50%;

  @media (min-width: $breakpoint-medium) {
    width: 50%;
    min-height: 100%;
  }
}

.infoBox {
  background-color: #e9e9e9e7;
}

.appointmentBox {
  background-color: #ffffffec;
}

.appointmentBox,
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    color: $main-dark-color;
    font-weight: 900;
    margin: 0.4rem 0;
    font-size: 1.6rem;
    letter-spacing: -0.02em;
  }
}

.buttonBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem;
}

.contactConfirmation {
  background-color: $main-light-color;
  width: 100%;
  display: flex;
  &__text {
    margin: auto;
    color: $main-dark-color;
    font-weight: bolder;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}
</style>
