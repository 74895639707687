const middleware = {}

middleware['data'] = require('../middleware/data.js')
middleware['data'] = middleware['data'].default || middleware['data']

middleware['role'] = require('../middleware/role.js')
middleware['role'] = middleware['role'].default || middleware['role']

middleware['accessConfirmed'] = require('../node_modules/moder-vel/middleware/accessConfirmed.js')
middleware['accessConfirmed'] = middleware['accessConfirmed'].default || middleware['accessConfirmed']

middleware['auth'] = require('../node_modules/moder-vel/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['lot'] = require('../node_modules/moder-vel/middleware/lot.js')
middleware['lot'] = middleware['lot'].default || middleware['lot']

export default middleware
