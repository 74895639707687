var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "modal-container newsReader",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "newsReader__container" },
        [
          _c("UiBtnClose", {
            staticClass: "newsReader__closeBtn",
            on: {
              BtnCloseClicked: function ($event) {
                return _vm.$emit("closeModalRequest")
              },
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "newsReader__title" }, [
            _vm._v("\n      " + _vm._s(_vm.news.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.news.content) } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }