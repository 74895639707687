<template>
  <button class="cta-ghost" :class="{'cta-ghost--mobile': isMobile}" @click="emitAction()">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isMobile: false,
    }
  },
  mounted () {
    this.isMobile = this.$globalValue
  },
  methods: {
    emitAction () {
      this.$emit('emittedAction')
    },
  },
}
</script>

<style lang="scss" scoped>
.cta-ghost {
  min-width: 8rem;
  padding: 0.6rem 1.2rem;
  margin: 0.5rem auto;
  color: var(--main-option-color);
  border: solid var(--main-option-color) 1px;
  border-radius: $border-radius;
  font-size: $fs-btn;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: background-color linear 100ms;

  @media (hover: hover) {
    &:hover {
      border: solid var(--second-option-color) 1px;
      color: var(--second-option-color);
    }
  }

  &:active {
    opacity: 0.6;
  }

  &--mobile {
    padding: 0.2rem 1rem;
  }
}
</style>
