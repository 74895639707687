export const state = () => ({
  auth: {
    logged: null,
    isLogged: false,
    user: {
      userId: null,
      firstname: null,
      lastname: null,
    },
  },
  isOnline: undefined,
  isClientSpaceAccessAuthorized: false,
})

export const actions = {
  setNetworkStatus: ({ commit }, status) => {
    commit('setStatus', status)
  },
}

export const mutations = {
  SET_USER_TOKEN: (state, data) => {
    state.auth.logged = data
  },
  SET_USER_INFOS: (state, data) => {
    state.auth.user = data
    state.auth.isLogged = true
  },
  RESET_USER_INFOS: (state) => {
    state.auth = {
      logged: null,
      isLogged: false,
      user: {
        userId: null,
        firstname: null,
        lastname: null,
      },
    }
  },
  setStatus: (state, status) => {
    state.isOnline = status
    localStorage.setItem('isOnline', status)
  },
  SET_CLIENT_SPACE_ACCESS_STATUS: (state, status) => {
    state.isClientSpaceAccessAuthorized = status
  },
}

export const getters = {
  isAuthenticated (state) {
    return state.auth.isLogged
  },

  loggedInUser (state) {
    return state.auth.user
  },
  loggedInUserId (state) {
    return state.auth.user.userId
  },
  loggedInUserFullname (state) {
    return state.auth.user.firstName + ' ' + state.auth.user.lastName
  },
  getUserFlats (state) {
    return state.auth.user.flats
  },
  isOnline: (state) => {
    return state.isOnline
  },
  getClientSpaceAccessStatus (state) {
    return state.isClientSpaceAccessAuthorized
  },
}
