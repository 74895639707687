
<template>
  <div>
    <client-only>
      <Nuxt />
    </client-only>
    <PoweredBy />
    <CookieBanner v-if="(areCookieLoaded && !areCookiesConfigured) || cookiesUpdateState" @emitCookieBannerClose="closeCookieBanner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Default',
  data () {
    return {
      areCookieLoaded: false,
      areCookiesConfigured: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      program: 'program/getProgramData',
      cookiesUpdateState: 'cookies/getCookiesUpdateState',
    }),
  },
  mounted () {
    this.loadCookiesAndSetStatus()
  },
  methods: {
    closeCookieBanner () {
      this.areCookiesConfigured = true
      this.$store.commit('cookies/SET_COOKIES_UPDATE_STATE', false)
    },
    loadCookiesAndSetStatus () {
      this.areCookiesConfigured = !!Cookies.get('moderapp_settings')
      setTimeout(() => {
        this.areCookieLoaded = true
      }, 1000)
    },
  },
}
</script>
