import axios from 'axios'

export const state = () => ({
  programIdQrStock: null,
  officeId: null,
  qrStockApiStatus: null,
})

export const actions = {
  async loadQrStockProgramInfos ({ commit, state }, technicalId) {
    if (!state.programIdQrStock) {
      commit('SET_APISTOCK_STATUS', 'PENDING')
      const headers = {
        'content-type': 'application/json',
        Authorization: process.env.QR_API_KEY,
      }
      await axios.get('/quartus_api/api/Stock', { headers })
        .then((response) => {
          if (response.status === 200) {
            return response.data.datas
          }
        })
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            for (let j = 0; j < response[i].operations.length; j++) {
              if ((response[i].operations[j].if_operationid === technicalId)) {
                if (response[i].office.if_salesofficeid) {
                  commit('SET_QR_OFFICEID', response[i].office.if_salesofficeid)
                }
                return response[i].if_programid
              }
            }
          }
        })
        .then((programIdStock) => {
          commit('SET_APISTOCK_STATUS', 'LOADED')
          commit('SET_QR_PROGRAMID_FROMSTOCK', programIdStock)
        })
        .catch((e) => {
          commit('SET_APISTOCK_STATUS', 'ERROR')
          return e
        })
    }
  },
}

export const mutations = {
  SET_QR_PROGRAMID_FROMSTOCK: (state, programIdFromStock) => {
    if (programIdFromStock) {
      state.programIdQrStock = programIdFromStock
    }
  },
  SET_QR_OFFICEID: (state, id) => {
    state.officeId = id
  },
  SET_APISTOCK_STATUS: (state, status) => {
    state.qrStockApiStatus = status
  },
}

export const getters = {
  getProgramIdQrStock: (state) => {
    return state.programIdQrStock
  },
  getQrApiLoadStatus: (state) => {
    return state.qrStockApiStatus
  },
  getofficeIdQrStock: (state) => {
    return state.officeId
  },
}
