<template>
  <div class="infoContainer">
    <UiBtnClose @BtnCloseClicked="$emit('closeRequest')" />
    <p v-if="UI.confirmation" class="infos-succes">
      {{ $t('contactConfirmation.msg') }}<br>{{ $t('contactConfirmation.thx') }}
    </p>
    <section v-else class="infos">
      <div class="infos__header">
        <UiBtnReturn @emitClickBtnReturn="btnReturnInfoClicked" />
        <h2 class="infos__title">
          S'INFORMER
        </h2>
      </div>
      <p class="infos__text">
        Vous souhaitez des renseignements sur les biens et services proposés par QUARTUS Résidentiel. Laissez vos coordonnées et nous vous contacterons dans les meilleurs délais pour vous apporter informations et conseils.
      </p>
      <FormExternQrForm class="infos__form" @statusOK="statusOK" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      UI: {
        confirmation: false,
      },
    }
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    btnReturnInfoClicked () {
      this.$emit('btnReturnInfoClicked')
    },
    statusOK () {
      this.UI.confirmation = true
    },
  },
}
</script>

<style lang="scss" scoped>
.infoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background-color: $main-light-color;
  position: relative;
}

.infos,
.infos-succes {
  margin: auto;
  padding: 2rem 10%;
  border-radius: $border-radius;
  background-color: $main-light-color;
  animation: revealSlidelight 500ms;
  font-size: $fs-text;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  &__text {
    margin-bottom: 1.8rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 1px;
  }
}

.infos__title,
.infos__text,
.infos__form {
  animation: revealSlidelight 400ms;
}

.btnReturn {
  margin-right: 1rem;
}

.contactFooter {
  margin-top: auto;
}

.contactConfirmation {
  position: absolute;
  top: 0;
  bottom: 0;
}
</style>
