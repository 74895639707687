<template>
  <section v-if="gallery" class="carousel-wrapper">
    <div ref="mySwiper" v-swiper="swiperOption">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in gallery" :key="index" class="swiper-slide">
          <div class="swiper-zoom-container">
            <img :key="index" :src="item" alt="image program" @click="$emit('clickOnImage', index)">
          </div>
        </div>
      </div>
      <div v-if="gallery.length > 1" class="swiper-pagination" />
      <div v-if="gallery.length > 1" class="swiper-button-prev" aria-label="prev" />
      <div v-if="gallery.length > 1" class="swiper-button-next" aria-label="next" />
    </div>
  </section>
</template>

<script>
import 'swiper/swiper.scss'
import { directive } from 'vue-awesome-swiper'
import UiBtnClose from '~/components/UI/UiBtnClose'

export default {
  comonents: {
    UiBtnClose,
  },
  directives: {
    swiper: directive,
  },
  props: {
    gallery: {
      type: Array,
      default () {
        return null
      },
    },
  },
  data () {
    return {
      swiperOption: {
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  height: 100%;
}

.swiper-slide {
  margin: auto;
  &:hover {
    cursor: pointer;
  }
}

.carousel-wrapper {
  width: 100%;
  @media (min-width: $breakpoint-large) {
    width: 80%;
    margin: auto;
  }
}

.carousel-wrapper .swiper-button-next,
.carousel-wrapper .swiper-button-prev {
  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.carousel-wrapper .swiper-button-next::after,
.carousel-wrapper .swiper-button-prev::after {
  font-size: 1.8rem;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: background-color 200ms, border 200ms;

  &:hover {
    background-color: $transparent-medium-color !important;
  }
}
</style>
