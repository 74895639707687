export default ({ route, redirect, app }) => {
  if (process.client) {
    app.router.beforeEach(async (to, from, next) => {
      // if third party is not allowed, streaming will display an error page
      try {
        localStorage.setItem('test', 'true')
      } catch (error) {
        window.location.replace(await redirectionToStreaming(route))
        return
      }

      const internalFromParams = route.query.internal_origin
      const internalFromStorage = localStorage.getItem('internal_origin')
      const date = Date.now()
      let internalOrigin = hasBeenReloaded()

      // if not expired
      if (internalFromStorage && JSON.parse(internalFromStorage).value === 'true') {
        internalOrigin = date < JSON.parse(internalFromStorage).expiry
      }

      // if from local or has internal_origin in params or store
      if (from.name || internalFromParams || internalOrigin) {
        await localStorage.setItem(
          'internal_origin',
          JSON.stringify({
            value: 'true',
            expiry: Date.now() + 10 * 60000,
          })
        )
        return next()
      }
      // coming from outside => redirected to streaming
      await localStorage.removeItem('internal_origin')
      window.location.replace(await redirectionToStreaming(route))
    })

    app.router.beforeEach(async (to, from, next) => {
      if (from.name === null) {
        await app.store.dispatch('program/loadProgramGlobal', { promoterName: route.params.promoter, programName: route.params.program })
      }
      next()
    })
  }
}

async function redirectionToStreaming (route) {
  const programTitle = route.params.program
  const promoter = route.params.promoter
  const lotId = route.params.lotId
  const program = await fetch(`/moder_1_5/programs-info/${promoter}/${programTitle}`).then(res => res.json())
  const programId = program.programInfo.programId
  const streamingLink = await fetch(`/moder_1_5/streaming/links/direct?programId=${programId}&flatId=${lotId}`).then(res => res.json())

  return streamingLink.link
}

function hasBeenReloaded () {
  return (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance.getEntriesByType('navigation').map(nav => nav.type).includes('reload')
}
