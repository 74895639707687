<template>
  <div class="loader-container">
    <div class="spinner">
      <div class="cube1" />
      <div class="cube2" />
    </div>
  </div>
</template>
<script>
export default ({
  name: 'Loader',
})
</script>

<style lang="scss" scoped>
.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}
.spinner {
  margin: auto;
  width: 100px;
  height: 100px;
  position: relative;
}

.cube1, .cube2 {
  background-color: #F0F0F0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.95s;
  animation-delay: -0.95s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(75px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(75px) translateY(75px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(75px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(75px) rotate(-90deg) scale(0.5);
  } 50% {
    transform: translateX(75px) translateY(75px) rotate(-179deg);
    -webkit-transform: translateX(75px) translateY(75px) rotate(-179deg);
  } 50.1% {
    transform: translateX(75px) translateY(75px) rotate(-180deg);
    -webkit-transform: translateX(75px) translateY(75px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(75px) rotate(-270deg) scale(0.5);
  } 100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
</style>
