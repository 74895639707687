export function sortFloors (floors) {
  const floorsSorted = []
  const floorsDesiredOrder = ['-5', '-4', '-3', '-2', '-1', 'RDJ', 'RDC', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']
  floorsDesiredOrder.forEach((floor) => {
    if (floors.includes(floor)) {
      floorsSorted.push(floor)
    }
  })
  return floorsSorted
}

export async function getTokenInUrlParams () {
  if (!window) { return {} }

  const url = await window.location.search
  const params = await new URLSearchParams(url)
  const tokenValue = await params.get('token')
  const hasTokenParam = await params.has('token')
  return { hasTokenParam, tokenValue }
}

export function waitTimer (time) {
  return new Promise(resolve => setTimeout(resolve, time))
}
