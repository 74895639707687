<template>
  <p v-if="UI.confirmation" class="contactForm-succes">
    {{ $t('contactConfirmation.msg') }}<br>{{ $t('contactConfirmation.thx') }}
  </p>
  <div v-else class="contactFormContainer">
    <UiBtnClose @BtnCloseClicked="closeForm()" />
    <div class="contactForm">
      <section v-if="UI.datePicker" class="datepicker-container">
        <div class="contactForm__header">
          <UiBtnReturn v-if="UI.datePicker" @emitClickBtnReturn="btnReturnInfoClicked" />
          <h2 class="contactForm__title">
            {{ $t('contactFormDemonstration.title') }}
          </h2>
        </div>
        <p class="contactForm__subTitle">
          {{ $t('contactFormDemonstration.subTitle') }}
        </p>
        <section class="picker">
          <v-date-picker
            v-model="date"
            mode="date"
            :date="new Date()"
            :min-date="new Date()"
            show-caps
            :locale="lang"
            class="datepicker"
          />
          <div class="daypicker">
            <h3 class="daypicker__title">
              {{ $t('contactFormDemonstration.daypicker') }}
            </h3>
            <div v-for="(hour, index) in hours" :key="index" :class="{'hour-selected': hour === hourSelected}" class="daypicker__hour" @click="hourSelected = hour ; dateError = false">
              <div>{{ hour }}h</div>
            </div>
          </div>
        </section>
        <div class="validation">
          <button class="cta" @click="dateValidation">
            {{ $t('contactFormDemonstration.cta') }}
          </button>
        </div>
        <transition name="fade">
          <div v-if="dateError" class="msg--error">
            {{ $t('contactFormDemonstration.error') }}
          </div>
        </transition>
      </section>
      <section v-if="UI.form">
        <div class="contactForm__header">
          <UiBtnReturn v-if="UI.form" @emitClickBtnReturn="returnToDatePicker" />
          <h2 class="contactForm__title">
            {{ $t('contactFormDemonstration.title') }}
          </h2>
        </div>
        <p class="contactForm__subTitle">
          {{ appointmentFormatedForSubtitle }}
        </p>
        <FormInternForm form-type="demonstration" :date="dateAndHours" @formSent="setConfirmationUI" />
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      status: '',
      UI: {
        form: false,
        datePicker: true,
        confirmation: false,
      },
      date: new Date(),
      hours: ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
      hourSelected: '',
      isDateValidate: false,
      dateError: false,
    }
  },
  computed: {
    ...mapState({
      programContactAddress: state => state.programContactAddress,
      programContactHours: state => state.programContactHours,
      programContactPhone: state => state.programContactPhone,
      programId: state => state.programId,
      programLogo: state => state.programLogo,
    }),
    dateAndHours () {
      if (this.hourSelected && this.date) {
        const dateFormated = this.date.toISOString().split('T').splice(0, 1)
        const myHour = parseInt(this.hourSelected)
        dateFormated.push(`T${myHour}:00:00.000Z`)
        return dateFormated.join('')
      }
      return ''
    },
    appointmentFormatedForSubtitle () {
      const myDate = new Date(this.dateAndHours)
      let date = myDate.getUTCDate().toString()
      const hours = myDate.getUTCHours()
      let month = (myDate.getUTCMonth() + 1).toString()
      const year = myDate.getFullYear()
      if (month.length === 1) {
        month = '0' + month
      }
      if (date.length === 1) {
        date = '0' + date
      }
      if (this.lang === 'en') {
        return month + ' / ' + date + ' / ' + year + '-' + hours + 'H'
      } else {
        return date + ' / ' + month + ' / ' + year + '-' + hours + 'H'
      }
    },
    lang () {
      return this.$i18n.locale
    },
    programContactInfoExist () {
      return !!((this.programContactHours || this.programContactPhone || this.programContactAddress))
    },
  },
  methods: {
    btnReturnInfoClicked () {
      this.$emit('btnReturnInfoClicked', true)
    },
    setConfirmationUI () {
      this.UI.confirmation = true
      setTimeout(() => {
        this.$emit('requestToCloseForm', true)
      }, 4000)
    },
    closeForm () {
      this.$emit('requestToCloseForm', true)
    },
    dateValidation () {
      if (this.hourSelected && this.date) {
        this.UI.form = true
        this.UI.datePicker = false
      } else {
        this.UI.form = false
        this.dateError = true
      }
    },
    returnToDatePicker () {
      this.UI.form = false
      this.UI.datePicker = true
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation {
  margin: auto;
}

.contactFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background-color: $main-light-color;
  position: relative;

  @media (min-width: $breakpoint-small) {
    margin: auto;
    display: flex;
  }
  @media (min-width: $breakpoint-large) {
    width: 84%;
    height: 84%;
    margin: auto;
  }
}

.contactForm {
  width: 100%;
  margin: auto;
  border-radius: $border-radius;
  padding: 2rem 10%;
  background-color: $main-light-color;
  animation: revealSlidelight 500ms;
  font-size: $fs-text;

  &-succes {
    width: auto;
    margin: auto;
    border-radius: $border-radius;
    padding: 2rem 10%;
    background-color: $main-light-color;
    animation: revealSlidelight 500ms;
    font-size: $fs-text;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  &__subTitle {
    animation: revealSlidelight 400ms;
  }
}

.validation {
  padding-top: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: $breakpoint-large) {
    padding-top: 0.8rem;
  }
}

.datepicker,
.daypicker {
  margin: 2rem 0;
  width: 48%;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.picker {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    display: block;
  }
}

.daypicker {
  border: solid #d9dadbff 1px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;

  &__hour {
    text-align: center;
    width: 20%;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs-text;
      font-weight: 500;
      width: 2rem;
      height: 2rem;
      margin: auto;
      border-radius: 50%;

      &:hover {
        background-color: rgb(229, 229, 229);
        cursor: pointer;
      }
    }
  }
  &__title {
    position: absolute;
    top: 1rem;
    font-weight: 600;
    font-size: $fs-text;
    color: $main-dark-color;
  }

  @media (max-width: 650px) {
    min-height: 220px;
  }
}

.hour-selected {
  div {
    background-color: #3685cf;
    color: white;

    &:hover {
      background-color: #3685cf;
    }
  }
}
</style>
