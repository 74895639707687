<template>
  <li class="doc" :class="{'doc--read' : doc.read}" @click="$emit('docClicked')">
    <div class="doc__main">
      <div class="doc__title" :class="{'doc__notification' : !doc.read}">
        {{ doc.message }}
      </div>
      <div class="doc__date">
        {{ doc.created_at }}
      </div>
    </div>
    <slot />
  </li>
</template>

<script>
export default {
  props: {
    doc: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.doc {
  color: $main-dark-color;
  font-weight: 600;
  margin: 0.2rem 0;
  padding: 0.4rem 0;
  border: $border-radius;
  border-top: solid $border-color 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__main {
      display: flex;
  justify-content: space-between;
  align-items: center;
  }

  &--read {
    color: $main-medium-color;
    font-weight: 500;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1rem;
  }

  &__date {
    font-size: 0.9rem;
  }

  &__notification::before {
    content: " ";
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--main-option-color);
  }
}
</style>
