
<template>
  <div class="btn-burger" aria-label="menu" @click="btnBurgerClicked">
    <div class="line" />
    <div class="line" />
    <div class="line" />
  </div>
</template>

<script>
export default {
  name: 'UiBtnBurger',
  methods: {
    btnBurgerClicked () {
      this.$emit('btnBurgerClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.line {
  width: 80%;
  height: 2px;
  color: $main-medium-color;
  background-color: $main-medium-color;
  margin: 0.2rem;
  transition: width 300ms;
  animation: lineMove ease-out 400ms;
}

.btn-burger {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    .line:nth-child(2) {
      width: 24px;
    }
  }
}
</style>
