<template class="notifications">
  <div v-if="type === 'news'" class="notifications__news">
    <img src="/icon/icon-news-white.png" alt="notification" class="notifications__icon">
    {{ unread }} {{ $tc('notifications.news', unread) }}
  </div>
  <div v-else-if="type === 'messages'" class="notifications__messages">
    <img src="/icon/icon-messages-white.png" alt="notification" class="notifications__icon">
    {{ unread }} {{ $tc('notifications.messages', unread) }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    unread: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  &__news,
  &__messages {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    color: $main-light-color;
    background-color: var(--second-option-color);
    border-radius: $border-radius;
  }
  &__icon {
    height: auto;
    width: 2rem;
    margin-right: 0.5rem;
  }
}
</style>
