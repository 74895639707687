<template>
  <section class="error-page">
    <div class="error-page__container">
      <p v-if="error.statusCode && error.statusCode !== 'false'" class="error-page__subtitle">
        {{ error.statusCode }}
      </p>
      <h1 v-if="error.message" class="error-page__title">
        {{ error.message }}
      </h1>
      <nuxt-link v-if="error.customPath" :to="error.path" class="error-page__link">
        {{ $t('error.returnToHome') }}
      </nuxt-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Error',
  layout: 'error',
  props: {
    error: {
      type: Object,
      default () {
        return {
          message: this.$t('error.pathLot'),
          statusCode: 404,
          path: '/',
          customPath: false,
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.error-page {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  max-width: 80%;
  margin: auto;

  @media (min-width: $breakpoint-medium) {
    max-width: 60%;
  }

  &__container {
    margin: auto;
    background-image: url("/icon/square.svg");
    background-repeat: no-repeat;
    background-size: 20%;
    min-width: 80%;
    padding: 1rem;

    @media (min-width: $breakpoint-medium) {
      background-size: 7.5rem;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    animation: reveallight 1000ms, revealSlideToRightLight 600ms;

    @media (min-width: $breakpoint-medium) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: "Segoe UI", Verdana, sans-serif;
    color: $main-medium-color;
    animation: reveallight 400ms;
  }

  &__link {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: $main-dark-color;
    background-color: $main-light-color;
    border-radius: $border-radius;
    border: solid $main-dark-color 1px;
    padding: 0.6rem 2rem;
    animation: reveallight 1200ms;

    &:hover {
      color: $main-light-color;
      background-color: $main-dark-color;
    }
  }
}
</style>
