var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("client-only", [_c("Nuxt")], 1),
      _vm._v(" "),
      _c("PoweredBy"),
      _vm._v(" "),
      (_vm.areCookieLoaded && !_vm.areCookiesConfigured) ||
      _vm.cookiesUpdateState
        ? _c("CookieBanner", {
            on: { emitCookieBannerClose: _vm.closeCookieBanner },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }