var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infoContainer" },
    [
      _c("UiBtnClose", {
        on: {
          BtnCloseClicked: function ($event) {
            return _vm.$emit("closeRequest")
          },
        },
      }),
      _vm._v(" "),
      _vm.UI.confirmation
        ? _c("p", { staticClass: "infos-succes" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("contactConfirmation.msg"))),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("contactConfirmation.thx")) + "\n  "),
          ])
        : _c(
            "section",
            { staticClass: "infos" },
            [
              _c(
                "div",
                { staticClass: "infos__header" },
                [
                  _c("UiBtnReturn", {
                    on: { emitClickBtnReturn: _vm.btnReturnInfoClicked },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "infos__title" }, [
                    _vm._v("\n        S'INFORMER\n      "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "infos__text" }, [
                _vm._v(
                  "\n      Vous souhaitez des renseignements sur les biens et services proposés par QUARTUS Résidentiel. Laissez vos coordonnées et nous vous contacterons dans les meilleurs délais pour vous apporter informations et conseils.\n    "
                ),
              ]),
              _vm._v(" "),
              _c("FormExternQrForm", {
                staticClass: "infos__form",
                on: { statusOK: _vm.statusOK },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }