<template>
  <section class="menu-mobile-landing">
    <UiBtnBurger @btnBurgerClicked="menuActive = true" />
    <UserInfos v-if="UI.userinfos" :user="loggedInUser" @clickOutside="UI.userinfos = false" @closeModal="UI.userinfos = false" />
    <nav v-if="menuActive" class="menu-mobile-landing__container">
      <div class="menu-mobile-landing__content">
        <UiBtnClose class="menu-mobile-landing__close" @BtnCloseClicked="menuActive = false" />
        <p v-if="isAuthenticated && loggedInUser" class="menu-mobile-landing__name" @click="openUserInfos()">
          {{ loggedInUser.firstName }} {{ loggedInUser.lastName }}
        </p>
        <p v-if="isAuthenticated && loggedInUser" class="menu-mobile-landing__logout" @click="logout()">
          {{ $t('ctaLogout') }}
        </p>
        <nuxt-link v-if="!isAuthenticated && buyerAccess" :to="{ name: clientParams ? 'client-lotId-login' : 'lotId-login', params: { lotId: lotIdParams } }" class="menu-mobile-landing__login">
          {{ $t('ctaLogin') }}
        </nuxt-link>
        <p class="menu-mobile-landing__language" @click="UI.language = true">
          {{ actualLanguage.toUpperCase() }}
        </p>
        <p class="cookies-link-container__link--absolute" @click="OpenCookiesSettings()">
          Cookies
        </p>
      </div>
    </nav>
    <Language v-if="UI.language" @clickOutside="UI.language = false" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      menuActive: false,
      UI: {
        language: false,
        userinfos: false,
      },
      buyerAccess: false,
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      isAuthenticated: 'isAuthenticated',
      programNameForUrl: 'programNameForUrl',
      loggedInUser: 'loggedInUser',
    }),
    actualLanguage () {
      return this.$i18n.locale
    },
  },
  mounted () {
    this.buyerAccess = this.program.buyer_access
  },
  methods: {
    logout () {
      this.$resetApp(this.$route.name)
      this.menuActive = false
    },
    OpenCookiesSettings () {
      this.$store.commit('cookies/SET_COOKIES_UPDATE_STATE', true)
    },
    openUserInfos () {
      this.UI.userinfos = true
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-mobile-landing {
  &__container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: $z-index-navigation;
    animation: reveallight 300ms;
  }

  &__content {
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
    position: absolute;
    top: 0;
    width: 90%;
    min-height: 100%;
    z-index: $z-index-navigation;
    padding: 5rem 1rem;
    background-color: $main-light-color;
    border-radius: 0 $border-radius $border-radius 0;
    font-size: $fs-title;
    animation: revealSlide 300ms;
  }

  &__close {
    font-size: $fs-text;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
  }
  &__name,
  &__language,
  &__logout,
  &__login,
  &__link {
    font-size: $fs-text;
    border-bottom: solid $bg-color 1px;
    margin-bottom: 2rem;
    display: inline-block;
    min-width: 100%;
    transition: text-decoration 200ms;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__name {
    margin-bottom: 0;

    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }

  @media (min-width: $breakpoint-large) {
    display: none;
  }
}
</style>
