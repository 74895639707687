function getConvertColor (x) {
  const color = x.split(/..=/)
  color.splice(0, 1)

  const r = parseInt(parseFloat(color[0]) * 255)
  const b = parseInt(parseFloat(color[1]) * 255)
  const g = parseInt(parseFloat(color[2]) * 255)
  const a = parseFloat(color[3])

  let rHex = r.toString(16)
  let gHex = g.toString(16)
  let bHex = b.toString(16)
  let aHex = Math.round(a * 255).toString(16)

  if (rHex.length === 1) { rHex = '0' + rHex }
  if (gHex.length === 1) { gHex = '0' + gHex }
  if (bHex.length === 1) { bHex = '0' + bHex }
  if (aHex.length === 1) { aHex = '0' + aHex }
  const HEX = `#${rHex}${gHex}${bHex}${aHex}`

  return HEX
}

export { getConvertColor }
