<template>
  <div class="card" :class="{'card--selected': isSelected}">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.8rem;
  box-shadow: 0 0 0.8rem $border-color;
  border-radius: 0.5rem;
  background-color: $main-light-color;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  margin: 0.1rem;

  &--selected {
    outline: solid var(--second-option-color) 3px;
  }
}
</style>
