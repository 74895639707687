<template>
  <div v-if="!appNotReady && isDomReady" id="app" class="app-template" :style="{display: appNotReady ? 'none' : '' }">
    <app-header class="app-header" @displayContact="toggleContactUI()" @displayUserCard="toggleUserCardUI()" />
    <m-page class="app-main" />
    <transition name="reveal-light">
      <mlab-modal-container v-if="UI.contact || UI.userCard" class="app-modal" @mousedown.native="closeModals({event: $event, expetion: null})" @touchdown.native="closeModals({event: $event, exeption: null})">
        <m-contact v-if="UI.contact" />
        <m-user-card v-else-if="UI.userCard" />
      </mlab-modal-container>
    </transition>
    <PoweredBy />
  </div>
</template>

<script>
export default {
  data () {
    return {
      UI: {
        contact: false,
        userCard: false,
      },
      isDomReady: false,
      isMobile: false,
    }
  },
  computed: {
    appNotReady () {
      return this.$moderVelModule ? !this.$moderVelModule.ready : false
    },
    flatName () {
      return this.$store.getters['programVel/lot'].data.name
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.isDomReady = true
      this.setOptionsColors()
      let locale = (this.getLang() || '').split('-')[0]
      if (!['fr', 'en'].includes(locale)) {
        locale = 'en'
      }
      sessionStorage.setItem('appBeforeLocale', this.$i18n.locale)
      this.$i18n.locale = locale

      const viewport = document.querySelector('[name="viewport"]')
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
      }
    })
  },
  destroyed () {
    if (sessionStorage.getItem('appBeforeLocale')) {
      this.$i18n.locale = sessionStorage.getItem('appBeforeLocale')
    }
  },
  methods: {
    getLang () {
      if (navigator.languages !== undefined) { return navigator.languages[0] }
      return navigator.language
    },
    setOptionsColors () {
      const element = document.querySelector('body')
      element.style.setProperty('--main-option-color', '#1A1A1Aff')
      element.style.setProperty('--second-option-color', '#746649ff')
      element.style.setProperty('--third-option-color', '#1A1A1Aff')
    },
    toggleContactUI () {
      this.closeModals({ event: null, exeption: 'contact' })
      setTimeout(() => {
        this.UI.contact = !this.UI.contact
      }, 100)
    },
    toggleUserCardUI () {
      this.closeModals({ event: null, exeption: 'userCard' })
      setTimeout(() => {
        this.UI.userCard = !this.UI.userCard
      }, 100)
    },
    closeModals ({ event, exeption }) {
      if (event && event.target && !event.target.classList.contains('mlab-modal-container')) {
        return
      }
      Object.keys(this.UI).forEach((key) => {
        if (key !== exeption) {
          this.UI[key] = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
.app-template {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100%;
  overflow: hidden;
}

.app-header {
  margin: 0 auto;
}

.app-main {
  overflow: auto;
}

.app-modal {
  z-index: 500;
}
</style>
