import { render, staticRenderFns } from "./DashboardNotification.vue?vue&type=template&id=714bafb9&scoped=true"
import script from "./DashboardNotification.vue?vue&type=script&lang=js"
export * from "./DashboardNotification.vue?vue&type=script&lang=js"
import style0 from "./DashboardNotification.vue?vue&type=style&index=0&id=714bafb9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714bafb9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('714bafb9')) {
      api.createRecord('714bafb9', component.options)
    } else {
      api.reload('714bafb9', component.options)
    }
    module.hot.accept("./DashboardNotification.vue?vue&type=template&id=714bafb9&scoped=true", function () {
      api.rerender('714bafb9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Dashboard/DashboardNotification.vue"
export default component.exports