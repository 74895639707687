<template>
  <div v-if="!$isInIframe()" class="cookie-banner-container">
    <section v-if="UI.banner && !cookies.settings" class="cookie-banner">
      <h3 class="cookie-banner__title">
        {{ $t('cookie.banner.title') }}
      </h3>
      <div class="cookie-banner__content-wrapper">
        <div>
          <p class="cookie-banner__text">
            {{ $t('cookie.banner.text1') }}
          </p>
          <p class="cookie-banner__text">
            {{ $t('cookie.banner.text2') }}
          </p>
          <p class="cookie-banner__text">
            {{ $t('cookie.banner.text3') }}
          </p>
        </div>
        <div class="cookie-banner__cta-wrapper">
          <button class="cta cta--option cookie-banner__cta " @click="acceptAllCookies()">
            {{ $t('cookie.acceptAll') }}
          </button>
          <button class="cta cta--option cookie-banner__cta" @click="refuseAllCookies()">
            {{ $t('cookie.refuseAll') }}
          </button>
          <button class="cta cookie-banner__cta" @click="openCustomPannel()">
            {{ $t('cookie.customize') }}
          </button>
        </div>
      </div>
    </section>
    <CookieBannerPanel v-if="UI.options || (UI.banner && cookies.settings)" @emittedClosePanel="closeCustomPannel()" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import UiBtnClose from '~/components/UI/UiBtnClose'

export default {
  comonents: {
    UiBtnClose,
  },
  data () {
    return {
      UI: {
        banner: true,
        options: false,
      },
      cookies: {
        settings: null,
      },
    }
  },
  mounted () {
    this.getCookieschoices()
    document.body.classList.add('modal-open')
  },
  beforeDestroy () {
    document.body.classList.remove('modal-open')
  },
  methods: {
    openCustomPannel () {
      this.UI.banner = false
      this.UI.options = true
    },
    closeCustomPannel () {
      this.UI.banner = true
      this.UI.options = false
      this.getCookieschoices()

      if (this.cookies.settings !== null) {
        this.closeCookieBanner()
      }
    },
    acceptAllCookies () {
      Cookies.set('moderapp_settings', '&gtag=true', { expires: 360 })
      this.getCookieschoices()
      this.closeCookieBanner()
    },
    refuseAllCookies () {
      Cookies.set('moderapp_settings', '&gtag=false', { expires: 360 })
      this.getCookieschoices()
      this.closeCookieBanner()
    },
    getCookieschoices () {
      this.cookies.settings = Cookies.get('moderapp_settings') || null
    },
    closeCookieBanner () {
      this.$emit('emitCookieBannerClose')
    },
  },
}
</script>

<style lang="scss" scoped>
.cookie-banner-container {
  position: fixed;
  background-color: $modal-bg-color;
  z-index: $z-index-modal;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
}

.cookie-banner {
  z-index: $z-index-modal;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 80%;
  background-color: $main-light-color;
  padding: 2rem 1.6rem;
  overflow-y: auto;
  animation: revealSlideUp 800ms;

  &__title {
    color: var(--main-option-color);
    font-weight: 700;
    font-size: $fs-title;
    margin-bottom: 1rem;
  }

  &__text {
    color: $main-dark-color;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  &__cta {
    min-width: 100%;
    margin-bottom: 1rem;
    font-size: 0.8rem;
  }

  @media (min-width: $breakpoint-small) {
    &__cta-wrapper {
      display: flex;
      justify-content: center;
    }

    &__cta {
      min-width: 10rem;
      width: auto;
      margin-bottom: 1rem;
    }
  }

  @media (min-width: $breakpoint-large) {
    width: 100%;
    margin: auto;

    &__content-wrapper {
      display: flex;
      justify-content: center;
      margin: auto;
    }

    &__title {
      margin: 0 auto 1rem auto;
    }

    &__title,
    &__content-wrapper {
      max-width: 1200px;
    }

    &__text {
      padding-right: 2rem;
    }

    &__cta-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__cta {
      min-width: 10rem;
      width: 14rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
