import { render, staticRenderFns } from "./Program.vue?vue&type=template&id=5fa964ce&scoped=true"
import script from "./Program.vue?vue&type=script&lang=js"
export * from "./Program.vue?vue&type=script&lang=js"
import style0 from "./Program.vue?vue&type=style&index=0&id=5fa964ce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa964ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fa964ce')) {
      api.createRecord('5fa964ce', component.options)
    } else {
      api.reload('5fa964ce', component.options)
    }
    module.hot.accept("./Program.vue?vue&type=template&id=5fa964ce&scoped=true", function () {
      api.rerender('5fa964ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Program/Program.vue"
export default component.exports