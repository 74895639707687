var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "menu-mobile" },
    [
      _c("UiBtnBurger", {
        on: {
          btnBurgerClicked: function ($event) {
            _vm.menuActive = true
          },
        },
      }),
      _vm._v(" "),
      _vm.UI.userinfos
        ? _c("UserInfos", {
            attrs: { user: _vm.loggedInUser },
            on: {
              clickOutside: function ($event) {
                _vm.UI.userinfos = false
              },
              closeModal: function ($event) {
                _vm.UI.userinfos = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.menuActive
        ? _c("div", { staticClass: "menu-mobile__container" }, [
            _vm.userStatus === "acquirer"
              ? _c(
                  "div",
                  { staticClass: "menu-mobile__content" },
                  [
                    _c("UiBtnClose", {
                      staticClass: "menu-mobile__close",
                      on: {
                        BtnCloseClicked: function ($event) {
                          _vm.menuActive = false
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-home"
                              : "lotId-home",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-home-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.dashboard")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-lot"
                              : "lotId-lot",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-mon-bien-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$tc("menu.lot", _vm.userLotsLength))
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-program"
                              : "lotId-program",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-program-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.program")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-project"
                              : "lotId-project",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-my-project-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.projects")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-docs"
                              : "lotId-docs",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-documents-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.docs")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.commercialInfos && _vm.commercialInfos.length > 0
                      ? _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: {
                                name: _vm.clientParams
                                  ? "client-lotId-messages"
                                  : "lotId-messages",
                                params: { lotId: _vm.lotIdParams },
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "menu-icon",
                              attrs: {
                                src: "/icon/icon-messages-white.png",
                                alt: "icon program",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.$t("menu.messages")))]),
                            _vm._v(" "),
                            _vm.notificationsMessages > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "notifications menu-mobile__content-notification",
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.notificationsMessages) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-construction"
                              : "lotId-construction",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-construction-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.construction")))]),
                        _vm._v(" "),
                        _vm.notificationsNews > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "notifications menu-mobile__content-notification",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.notificationsNews) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("VisitLinkSmall", {
                      staticClass: "menu-mobile__visit",
                      attrs: {
                        "program-background": _vm.programBackground,
                        link: _vm.visitUrl,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "menu-mobile__link",
                        on: {
                          click: function ($event) {
                            _vm.UI.language = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.actualLanguage.toUpperCase()) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isAuthenticated
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "menu-mobile__link",
                              on: {
                                click: function ($event) {
                                  _vm.UI.userinfos = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("menu.infos")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isAuthenticated
                            ? _c(
                                "p",
                                {
                                  staticClass: "menu-mobile__link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("ctaLogout")) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _c("nuxt-link", {
                          staticClass: "menu-mobile__link",
                          attrs: {
                            to: {
                              name: _vm.clientParams ? "client-lotId" : "lotId",
                              params: { lotId: _vm.lotIdParams },
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "cookies-link-container__link--absolute",
                        on: {
                          click: function ($event) {
                            return _vm.OpenCookiesSettings()
                          },
                        },
                      },
                      [_vm._v("\n        Cookies\n      ")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "menu-mobile__content" },
                  [
                    _c("UiBtnClose", {
                      staticClass: "menu-mobile__close",
                      on: {
                        BtnCloseClicked: function ($event) {
                          _vm.menuActive = false
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-home"
                              : "lotId-home",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-home-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.dashboard")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-program"
                              : "lotId-program",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-program-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.program")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-project"
                              : "lotId-project",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-my-project-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.projects")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.commercialInfos && _vm.commercialInfos.length > 0
                      ? _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: {
                                name: _vm.clientParams
                                  ? "client-lotId-messages"
                                  : "lotId-messages",
                                params: { lotId: _vm.lotIdParams },
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "menu-icon",
                              attrs: {
                                src: "/icon/icon-messages-white.png",
                                alt: "icon program",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.$t("menu.messages")))]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-construction"
                              : "lotId-construction",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "menu-icon",
                          attrs: {
                            src: "/icon/icon-construction-white.png",
                            alt: "icon program",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("menu.construction")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("VisitLinkSmall", {
                      staticClass: "menu-mobile__visit",
                      attrs: {
                        "program-background": _vm.programBackground,
                        link: _vm.visitUrl,
                      },
                    }),
                    _vm._v(" "),
                    _vm.isAuthenticated
                      ? _c(
                          "p",
                          {
                            staticClass: "menu-mobile__link",
                            on: {
                              click: function ($event) {
                                return _vm.logout()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("ctaLogout")) +
                                "\n      "
                            ),
                          ]
                        )
                      : _c("nuxt-link", {
                          staticClass: "menu-mobile__link",
                          attrs: {
                            to: {
                              name: _vm.clientParams ? "client-lotId" : "lotId",
                              params: { lotId: _vm.lotIdParams },
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "menu-mobile__link",
                        on: {
                          click: function ($event) {
                            _vm.UI.language = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.actualLanguage.toUpperCase()) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "cookies-link-container__link--absolute",
                        on: {
                          click: function ($event) {
                            return _vm.OpenCookiesSettings()
                          },
                        },
                      },
                      [_vm._v("\n        Cookies\n      ")]
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.language
        ? _c("Language", {
            on: {
              clickOutside: function ($event) {
                _vm.UI.language = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }