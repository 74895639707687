var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.program
    ? _c("div", { staticClass: "program" }, [
        _c("h2", { staticClass: "program-title" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("program.title")) + "\n  "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "program-tabs" }, [
          _c(
            "p",
            {
              staticClass: "program-tabs__item",
              class: { "program-tabs__item--selected": _vm.UI.infos },
              on: { click: _vm.setInfosUI },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("program.tabs.information")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.programCoordinates
            ? _c(
                "p",
                {
                  staticClass: "program-tabs__item",
                  class: {
                    "program-tabs__item--selected": _vm.UI.localisation,
                  },
                  on: { click: _vm.setLocalisationUI },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("program.tabs.location")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.programGalleryLength
            ? _c(
                "p",
                {
                  staticClass: "program-tabs__item",
                  class: { "program-tabs__item--selected": _vm.UI.visuals },
                  on: { click: _vm.setVisualsUI },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("program.tabs.visuals")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "program-main" }, [
          _vm.UI.infos
            ? _c("section", { staticClass: "program-infos" }, [
                _vm.programAddress
                  ? _c("p", { staticClass: "program-infos__adress" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.programAddress) + "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.program.descriptions[_vm.$i18n.locale]
                  ? _c("div", {
                      staticClass: "program-infos___desc",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.program.descriptions[_vm.$i18n.locale]
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.program.typology
                  ? _c(
                      "div",
                      { staticClass: "program-infos__boxes" },
                      _vm._l(_vm.program.typology, function (item, index) {
                        return _c("p", { key: index }, [
                          _vm._v("\n          " + _vm._s(item) + "\n        "),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.floors
                  ? _c(
                      "div",
                      { staticClass: "program-infos__boxes" },
                      _vm._l(_vm.floors, function (item, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.length > 1 ? item : "R+" + item) +
                              "\n        "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "program-infos__actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "cta program-infos__cta-visit",
                      on: {
                        click: function ($event) {
                          return _vm.openProgramStreamingLink()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cta.visitProgram")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formExternLinkConditions || _vm.formLinkConditions
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "cta cta--option program-infos__cta-infos",
                          on: {
                            click: function ($event) {
                              _vm.UI.form.info = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("cta.getInfos")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.UI.localisation
            ? _c(
                "section",
                { staticClass: "program-localisation" },
                [
                  _c("LocalisationMap", {
                    attrs: { center: _vm.programCoordinates },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.UI.visuals
            ? _c(
                "section",
                { staticClass: "program-visuals" },
                [
                  _c("Gallery", {
                    attrs: { gallery: _vm.gallery },
                    on: {
                      clickOnImage: function ($event) {
                        return _vm.requestFullscreen($event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.UI.galleryFullscreen
                    ? _c("GalleryFullscreenZoom", {
                        attrs: {
                          gallery: _vm.gallery,
                          "gallery-index": _vm.galleryFullscreenIndex,
                        },
                        on: {
                          BtnCloseClicked: function ($event) {
                            _vm.UI.galleryFullscreen = false
                          },
                          clickOutside: function ($event) {
                            _vm.UI.galleryFullscreen = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.UI.form.info
          ? _c(
              "div",
              { staticClass: "form-infos" },
              [
                _vm.formLinkConditions
                  ? _c("FormIntern", {
                      on: {
                        closeFormRequest: function ($event) {
                          return _vm.closeForm()
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formExternLinkConditions
                  ? _c("FormExternQr", {
                      on: {
                        closeFormRequest: function ($event) {
                          return _vm.closeForm()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.UI.form.option
          ? _c("div", { staticClass: "form-option" }, [
              _c("img", { attrs: { src: "/images/form-option.png", alt: "" } }),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }