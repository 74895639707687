<template>
  <section class="user-identification" :style="{ backgroundImage: `url(${$prefixLinks(program.background)})` }">
    <div class="user-identification-header">
      <nuxt-link :to="{ name: clientParams ? 'client-lotId' : 'lotId', params: { lotId: lotIdParams } }" class="user-identification-header__return">
        <img src="/icon/icon-return.png" alt="icon">
      </nuxt-link>
      <img v-if="program && isIdentificationPending" :src="programLogoFiltered" alt="" class="user-identification-header__logo">
    </div>
    <UserLogin v-if="userAction === 'LOGIN'" @signinRequest="setUserUi('SIGNIN')" @recoveryRequest="setUserUi('RECOVERY')" @identificationPending="isIdentificationPending = true" />
    <UserSignin v-if="userAction === 'SIGNIN'" @loginRequest="setUserUi('LOGIN')" />
    <UserPasswordRecovery v-if="userAction === 'RECOVERY'" @loginRequest="setUserUi('LOGIN')" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      userAction: 'LOGIN',
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      isIdentificationPending: false,
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
    }),
    programLogoFiltered () {
      const logo = this.program
        ? this.program.programLogo || this.program.promoterInfo.logo.main
        : null
      return logo ? this.$prefixLinks(logo) : null
    },
  },
  methods: {
    setUserUi (uiRequested) {
      this.userAction = uiRequested
    },
  },
}
</script>

<style lang="scss">
.user-identification {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-position: center;
  background-size: cover;

  @media (min-width: $breakpoint-small) {
    background-image: radial-gradient(circle, #e0e0e0, #ffffff);
  }

  &-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-size;
    width: 100%;
    text-align: center;
    padding: 1.4rem;

    &__logo {
      height: 4.25rem;
      width: auto;
      max-width: 50%;
      max-height: 3.75rem;
    }

    &__return {
      border-radius: 50%;
      background-color: $transparent-medium-color;
      width: 4.25rem;
      height: 4.25rem;
      padding: 1.2rem;
      transition: box-shadow 200ms, background-color 200ms;

      &:hover {
        background-color: $main-light-color;
      }
    }
  }
}

.recoveryContainer,
.signInContainer,
.loginContainer {
  padding: 2.3rem 2.7rem;
  margin: auto;
  background-color: $main-light-color;
  animation: revealSlidelight 300ms;
  border-radius: $border-radius;

  @media (min-width: $breakpoint-small) {
    margin: auto;
  }
  @media (max-width: $breakpoint-xs) {
    padding: 10%;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  &__logo {
    width: 50%;
    max-width: 50%;
    max-height: 3.75rem;
    text-align: center;
    border: solid black 1px;
  }
}

.recoveryForm,
.signInForm,
.loginForm {
  padding-top: 0.4rem;
  max-width: 25rem;
  margin: auto;

  @media (min-width: $breakpoint-small) {
    min-width: 18.75rem;
  }
}

.recoveryForm__field,
.signInForm__field,
.loginForm__field {
  position: relative;
  margin-top: 1.6rem;
  flex-direction: column;

  label {
    position: absolute;
    top: -1rem;
    color: $main-dark-color;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $fs-text;
    animation: revealSlidelight 300ms;
  }

  input {
    border: none;
    border-bottom: 1px solid #b3b3b3;
    color: $main-dark-color;
    font-size: $fs-text;
    font-weight: 300;
    width: 100%;
    padding: 2px 0;
  }

  input::placeholder {
    border: 0;
    font-weight: lighter;
  }

  input:focus {
    border-bottom: 1px solid $main-dark-color;
  }
}

.togglePassword {
  position: absolute;
  right: 0;
}

.recoveryForm__link {
  text-align: center;
}

.recoveryForm__link,
.loginForm__recoveryLink,
.loginForm__field--checkbox label {
  font-size: $fs-link;
}

.recoveryForm__link,
.loginForm__recoveryLink,
.loginForm__field--checkbox {
  margin-top: 0.9rem;
}

.recoveryForm__link,
.loginForm__recoveryLink {
  text-decoration: underline;
  color:  var(--main-option-color);
  cursor: pointer;

  &:hover {
    color: var(--second-option-color);
    cursor: pointer;
  }
}

.signInSucces {
  height: auto;
  display: flex;
  padding: 4rem;

  p {
    margin: auto;
    font-weight: 500;
    text-transform: uppercase;
    color: $main-dark-color;
    letter-spacing: -0.04rem;
  }
}

.loginForm__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recoveryForm__subText,
.loginForm__subText {
  text-align: center;
  font-size: $fs-text;
  padding: 0.5rem 0;
  color: $main-dark-color;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 300ms;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
