var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projects" }, [
    _c("h2", { staticClass: "projects__title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("projects.title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-container" },
      _vm._l(_vm.projects, function (project, index) {
        return _c("ProjectsItem", {
          key: index,
          staticClass: "project-item",
          attrs: { index: index, project: project },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }