<template>
  <section class="recoveryContainer">
    <div v-if="resetPasswordStatus === 'SUCCES'" class="recoverySucces">
      <p>{{ $t('pwdRecovery.recoverySucces') }}</p>
    </div>
    <div v-else>
      <div class="recoverySteps">
        <div :class="{'recoveryStepsSelected' : recoveryStep === 'FIRST'}" class="recoverySteps__title" @click="setRecoveryStep('FIRST')">
          {{ $t('pwdRecovery.firstStep') }}
        </div>
        <div :class="{'recoveryStepsSelected' : recoveryStep === 'SECOND'}" class="recoverySteps__title" @click="setRecoveryStep('SECOND')">
          {{ $t('pwdRecovery.secondStep') }}
        </div>
      </div>
      <div v-if="recoveryStep === 'FIRST'">
        <h3 class="recoveryContainer__title">
          {{ $t('pwdRecovery.firstStepTitle') }}
        </h3>
        <form class="recoveryForm" @submit.prevent="handleResetPassword">
          <div class="recoveryForm__field">
            <label v-if="formReset.email" for="username">{{ $t('pwdRecovery.username') }}</label>
            <input v-model="formReset.email" type="email" :placeholder="$t('pwdRecovery.username')">
          </div>
          <p v-if="!$v.formReset.email.required && $v.formReset.email.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.usernameRequired') }}
          </p>
          <p v-if="!$v.formReset.email.email && $v.formReset.email.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.usernameValidRequired') }}
          </p>
          <button type="submit" class="cta--light">
            Réinitialiser
          </button>
          <p class="recoveryForm__link" @click="sendUiRequest('LOGIN')">
            {{ $t('pwdRecovery.return') }}
          </p>
          <p v-if="recoveryCodeSent === 'ERROR'" class="alert-error alert-error-center">
            {{ $t('pwdRecovery.error.EmailUnknow') }}
          </p>
        </form>
      </div>
      <div v-if="recoveryStep === 'SECOND'">
        <h3 class="recoveryContainer__title">
          {{ $t('pwdRecovery.secondStepTitle') }}
        </h3>
        <p v-if="recoveryCodeSent === 'SENT'" class="recoveryContainer__subTitle">
          {{ $t('pwdRecovery.recoveryCodeSent') }}
        </p>
        <form class="recoveryForm" @submit.prevent="handleSetNewPassword">
          <div class="recoveryForm__field">
            <label v-if="form.email" for="email">{{ $t('pwdRecovery.username') }}</label>
            <input v-model="form.email" type="email" :placeholder="$t('pwdRecovery.username')">
          </div>
          <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.usernameRequired') }}
          </p>
          <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.usernameValidRequired') }}
          </p>
          <div class="recoveryForm__field">
            <label v-if="form.password" for="password">{{ $t('pwdRecovery.newPassword') }}</label>
            <img
              v-if="form.password && passwordFieldType === 'text'"
              srcset="/icon/eyeOpened.svg"
              src="/icon/eyeOpened.png"
              alt="eye"
              class="togglePassword"
              @click="passwordToggle"
            >
            <img
              v-if="form.password && passwordFieldType === 'password'"
              srcset="/icon/eyeClosed.svg"
              src="/icon/eyeClosed.png"
              alt="eye"
              class="togglePassword"
              @click="passwordToggle"
            >
            <input v-model="form.password" :type="passwordFieldType" autocapitalize="off" :placeholder="$t('pwdRecovery.newPassword')">
          </div>
          <p v-if="!$v.form.password.required && $v.form.password.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.passwordRequired') }}
          </p>
          <p v-if="!$v.form.password.minLength && $v.form.password.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.passwordLength') }}
          </p>
          <div class="recoveryForm__field">
            <label v-if="form.passwordConfirmation" for="passwordConfirmation">{{ $t('pwdRecovery.confirmPassword') }}</label>
            <img
              v-if="form.passwordConfirmation && passwordConfirmationFieldType === 'text'"
              srcset="/icon/eyeOpened.svg"
              src="/icon/eyeOpened.png"
              alt="eye"
              class="togglePassword"
              @click="passwordConfirmationToggle"
            >
            <img
              v-if="form.passwordConfirmation && passwordConfirmationFieldType === 'password'"
              srcset="/icon/eyeClosed.svg"
              src="/icon/eyeClosed.png"
              alt="eye"
              class="togglePassword"
              @click="passwordConfirmationToggle"
            >
            <input v-model="form.passwordConfirmation" :type="passwordConfirmationFieldType" autocapitalize="off" :placeholder="$t('pwdRecovery.confirmPassword')">
          </div>
          <p v-if="!$v.form.passwordConfirmation.required && $v.form.passwordConfirmation.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.passwordRequired') }}
          </p>
          <p v-if="!$v.form.passwordConfirmation.sameAsPassword && $v.form.passwordConfirmation.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.noPasswordMatch') }}
          </p>
          <div class="recoveryForm__field">
            <label v-if="form.code" for="code">{{ $t('pwdRecovery.code') }}</label>
            <input v-model="form.code" type="text" :placeholder="$t('pwdRecovery.code')">
          </div>
          <p v-if="!$v.form.code.required && $v.form.code.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.codeRequired') }}
          </p>
          <p v-if="(!$v.form.code.minLength || !$v.form.code.maxLength) && $v.form.code.$dirty" class="error-alert">
            {{ $t('pwdRecovery.error.codeValidRequired') }}
          </p>
          <button type="submit" class="cta--light">
            {{ $t('pwdRecovery.modify') }}
          </button>
          <p v-if="resetPasswordStatus === 'ERROR'" class="error-alert">
            {{ $t('pwdRecovery.error.codeError') }}
          </p>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import { required, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      passwordFieldType: 'password',
      passwordConfirmationFieldType: 'password',
      formReset: {
        email: '',
      },
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
        code: '',
      },
      recoveryStep: 'FIRST',
      recoveryCodeSent: '',
      recoveryCodeNotSent: false,
      resetPasswordStatus: '',
    }
  },
  validations: {
    formReset: {
      email: { required, email },
    },
    form: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      passwordConfirmation: { required, sameAsPassword: sameAs('password') },
      code: { required, minLength: minLength(5), maxLength: maxLength(5) },
    },
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
  },
  methods: {
    passwordToggle () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    passwordConfirmationToggle () {
      this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
    },
    setRecoveryStep (step) {
      this.recoveryStep = step
    },
    sendUiRequest (type) {
      if (type === 'LOGIN') {
        this.$emit('loginRequest')
      }
    },
    handleResetPassword () {
      this.$v.formReset.$touch()
      if (!this.$v.formReset.$invalid) {
        const body = { email: this.formReset.email }
        axios.post(`/moder_1_5/reset-password/email?lang=${this.lang}`, body)
          .then(() => {
            this.recoveryStep = 'SECOND'
            this.recoveryCodeSent = 'SENT'
            this.form.email = this.formReset.email
          })
          .catch(() => {
            this.recoveryCodeSent = 'ERROR'
          })
      }
    },
    handleSetNewPassword () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        const body = {
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.passwordConfirmation,
          code: this.form.code,
        }
        axios.post(`/moder_1_5/reset-password/validation`, body)
          .then(() => {
            this.resetPasswordStatus = 'SUCCES'
            setTimeout(() => { this.sendUiRequest('LOGIN') }, 3000)
          })
          .catch(() => {
            this.resetPasswordStatus = 'ERROR'
          })
      }
    },
  },
}
</script>

<style lang="scss">
.recoverySteps {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1rem auto 1.8rem;

  &__title {
    width: 100%;
    padding: 0.6rem;
    font-size: 1rem;
    text-align: center;
    background-color: $main-light-color;
    color: $main-dark-color;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: solid var(--main-option-color) 1px;

    @media (min-width: $breakpoint-small) {
      min-width: 14rem;
    }

    &:hover {
        cursor: pointer;
    }
  }
}

.recoveryStepsSelected {
  font-weight: 900;
  background-color:var(--main-option-color);
  color: white;
}

.recoverySucces {
  height: auto;
  padding: 4rem;
  display: flex;
  background-color: white;

  p {
    font-size: $fs-text;
    margin: auto;
    font-weight: 500;
    text-transform: uppercase;
    color: $main-dark-color;
    letter-spacing: -0.04rem;
    background-color: $main-light-color;
  }
}
</style>
