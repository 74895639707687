<template>
  <section class="modal-container" @click="closeOnClickOutside('modal-container')">
    <p v-if="submitStatus === 'SENT'" class="deleteProject deleteProject--confirmation">
      {{ $t('deleteProject.success') }}
    </p>
    <div v-else class="deleteProject">
      <UiBtnClose @BtnCloseClicked="$emit('closeWindowRequest')" />
      <h2 class="deleteProject__title">
        {{ $t('deleteProject.text') }} <br><b>{{ projectName.toUpperCase() }}</b> {{ $t('deleteProject.question') }}
      </h2>
      <div class="deleteProject__cta">
        <CtaGhost :text="$t('deleteProject.cta.no')" @click.native="closeWindowRequest()" />
        <Cta :text="$t('deleteProject.cta.yes')" @click.native="deleteProject()" />
      </div>
      <p v-if="submitStatus === 'PENDING'" class="msg--pending deleteProject__message">
        {{ $t('deleteProject.sending') }}
      </p>
      <p v-if="submitStatus === 'ERROR'" class="msg--pending deleteProject__message">
        {{ $t('deleteProject.error') }}
      </p>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  props: {
    checksum: {
      type: String,
      default: '',
    },
    projectName: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      form: {
        email: '',
      },
      submitStatus: '',
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',

    }),
  },
  methods: {
    ...mapActions({
      loadProjects: 'projects/loadProjects',
    }),
    closeWindowRequest () {
      this.$emit('closeWindowRequest')
    },
    closeOnClickOutside (containerClass) {
      if (event.target.classList[0] === containerClass) {
        this.$emit('closeWindowRequest')
      }
    },
    deleteProject () {
      this.submitStatus = 'PENDING'
      const token = Cookies.get('temp-usr')
      const headers = {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      axios.delete(`/moder_1_5/cart/${this.checksum}`, { headers })
        .then(() => {
          this.submitStatus = 'SENT'
          this.loadProjects({ headers, programId: this.program.programId })
          setTimeout(() => {
            this.closeWindowRequest()
          }, 2000)
        })
        .catch((e) => {
          this.submitStatus = 'ERROR'
          setTimeout(() => {
            this.closeWindowRequest()
          }, 2000)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteProject {
  border-radius: $border-radius;
  background-color: $main-light-color;
  margin: auto;
  padding: 24px;
  position: relative;
  animation: revealSlidelight 400ms;
  margin: auto 1rem;
  font-size: $fs-text;

  @media (min-width: $breakpoint-small) {
    margin: auto;
  }

  &__cta {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    button:nth-child(1) {
      margin-right: 0;

      @media (min-width: $breakpoint-small) {
        margin-right: 1rem;
      }
    }

    button {
      width: 100%;

      @media (min-width: $breakpoint-small) {
        width: 14rem;
      }
    }
  }

  &__title,
  &__message {
    text-align: center;
  }
}

.deleteProject--confirmation {
  width: auto;
  margin: auto;
}
</style>
