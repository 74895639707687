<template>
  <section v-if="content || title" class="dashboard-last-item">
    <div class="dashboard-last-item__wrapper">
      <div class="dashboard-last-item__title">
        {{ title }}
      </div>
      <p class="dashboard-last-item__date">
        {{ date }}
      </p>
    </div>
    <p class="dashboard-last-item__content">
      {{ content }}
    </p>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-last-item {
  background-color: $bg-color;
  padding: 1rem;
  border-radius: $border-radius;
  margin: 1px 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 600;
  }

  &__date,
  &__content {
    font-weight: 300;
  }
}
</style>
