import Vue from 'vue'
import ModerVel from 'moder-vel'
import '@mdi/font/css/materialdesignicons.css'

export default function (ctx, inject) {
  Vue.use(ModerVel, {
    isNuxtApp: true,
    assetBaseUrl: process.env.FTP,
    messageBird: {
      AccessKey: process.env.MESSAGE_BIRD_ACCESS_KEY, //'Pu4nu2TMVZgIbMLhs3a7dIhdT',
      originator: process.env.MESSAGE_BIRD_ORIGINATOR, //'MODERLAB',
      timeout: parseInt(process.env.MESSAGE_BIRD_TIMEOUT), //3600,
      template: process.env.MESSAGE_BIRD_TEMPLATE, //'Votre code pour poursuivre votre réservation en ligne : %token'
    }
  })
  inject('getFields', (formName) => {
    return require(`moder-vel/fields/${formName}`).default
  })
  inject('moderVelModule', Vue.observable({ ready: process.client }))
}
