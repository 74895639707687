/* eslint-disable */

import Cookies from 'js-cookie'
/*
 ** Only run on client-side and only in production mode
 */

export default function ({ app, router, store }) {
    if (process.env.NODE_ENV === 'production') {
        /*Include Google Analytics Script */
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

        /*
        ** Every time the route changes (fired on initialization too)
        */
        app.router.afterEach((to, from) => {
            const isGtagConfigured = Cookies.get('moderapp_settings') || null
            const IsGtagAccepted = !!(isGtagConfigured === '&gtag=true')

            let count = store.state.cookies.gaPluginCountLoadedTimes
            if (IsGtagAccepted) {
                store.commit('cookies/INCREMENT_GA_TIMES')
                if (count === 1) {
                    /** Set the current page */
                    ga('create', process.env.GTAG_TRACKING_ID, 'auto')
                }
                /*
                 ** We tell Google Analytic to add a page view
                 */
                window[`ga-disable-${process.env.GTAG_TRACKING_ID}`] = false;
                ga('set', 'page', to.fullPath)
                ga('send', 'pageview')
            }

            if (!IsGtagAccepted) {
                if(process.client) {
                    store.commit('cookies/RESET_GA_TIMES')
                    Cookies.remove('_gat')
                    Cookies.remove('_gid')
                    Cookies.remove('_ga')
                    window[`ga-disable-${process.env.GTAG_TRACKING_ID}`] = true;
                }
            }
        })
    }
}
