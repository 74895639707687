var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "menu-mobile-landing" },
    [
      _c("UiBtnBurger", {
        on: {
          btnBurgerClicked: function ($event) {
            _vm.menuActive = true
          },
        },
      }),
      _vm._v(" "),
      _vm.UI.userinfos
        ? _c("UserInfos", {
            attrs: { user: _vm.loggedInUser },
            on: {
              clickOutside: function ($event) {
                _vm.UI.userinfos = false
              },
              closeModal: function ($event) {
                _vm.UI.userinfos = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.menuActive
        ? _c("nav", { staticClass: "menu-mobile-landing__container" }, [
            _c(
              "div",
              { staticClass: "menu-mobile-landing__content" },
              [
                _c("UiBtnClose", {
                  staticClass: "menu-mobile-landing__close",
                  on: {
                    BtnCloseClicked: function ($event) {
                      _vm.menuActive = false
                    },
                  },
                }),
                _vm._v(" "),
                _vm.isAuthenticated && _vm.loggedInUser
                  ? _c(
                      "p",
                      {
                        staticClass: "menu-mobile-landing__name",
                        on: {
                          click: function ($event) {
                            return _vm.openUserInfos()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.loggedInUser.firstName) +
                            " " +
                            _vm._s(_vm.loggedInUser.lastName) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated && _vm.loggedInUser
                  ? _c(
                      "p",
                      {
                        staticClass: "menu-mobile-landing__logout",
                        on: {
                          click: function ($event) {
                            return _vm.logout()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("ctaLogout")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAuthenticated && _vm.buyerAccess
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "menu-mobile-landing__login",
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-login"
                              : "lotId-login",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("ctaLogin")) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "menu-mobile-landing__language",
                    on: {
                      click: function ($event) {
                        _vm.UI.language = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.actualLanguage.toUpperCase()) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "cookies-link-container__link--absolute",
                    on: {
                      click: function ($event) {
                        return _vm.OpenCookiesSettings()
                      },
                    },
                  },
                  [_vm._v("\n        Cookies\n      ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.language
        ? _c("Language", {
            on: {
              clickOutside: function ($event) {
                _vm.UI.language = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }