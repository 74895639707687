import { render, staticRenderFns } from "./UiBtnReturn.vue?vue&type=template&id=f4811a50&scoped=true"
var script = {}
import style0 from "./UiBtnReturn.vue?vue&type=style&index=0&id=f4811a50&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4811a50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/moderlab/moderclient/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f4811a50')) {
      api.createRecord('f4811a50', component.options)
    } else {
      api.reload('f4811a50', component.options)
    }
    module.hot.accept("./UiBtnReturn.vue?vue&type=template&id=f4811a50&scoped=true", function () {
      api.rerender('f4811a50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/UI/UiBtnReturn.vue"
export default component.exports