<template>
  <div class="app-container">
    <CookieBanner v-if="(areCookieLoaded && !areCookiesConfigured) || cookiesUpdateState" @emitCookieBannerClose="closeCookieBanner" />
    <TheHeader v-if="isClientSpaceAccessAuthorized" />
    <client-only>
      <Nuxt />
    </client-only>
    <PoweredBy :position="'fixed'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Client',
  data () {
    return {
      areCookieLoaded: false,
      areCookiesConfigured: false,
      isAuthorized: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      program: 'program/getProgramData',
      cookiesUpdateState: 'cookies/getCookiesUpdateState',
      isClientSpaceAccessAuthorized: 'getClientSpaceAccessStatus',
    }),
  },
  mounted () {
    this.loadCookiesAndSetStatus()
  },
  methods: {
    closeCookieBanner () {
      this.areCookiesConfigured = true
      this.$store.commit('cookies/SET_COOKIES_UPDATE_STATE', false)
    },
    loadCookiesAndSetStatus () {
      this.areCookiesConfigured = !!Cookies.get('moderapp_settings')
      setTimeout(() => {
        this.areCookieLoaded = true
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  min-height: var(--vh);

  @media (min-width: $breakpoint-large) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
