<template>
  <div class="signInContainer">
    <section v-if="!signInSucces">
      <h2 class="signInContainer__title">
        {{ $t('signup.title') }}
      </h2>
      <form class="signInForm" @submit.prevent="handleSignInSubmit">
        <div class="signInForm__field">
          <label v-if="form.lastname" for="lastname">{{ $t('signup.lastname') }}</label>
          <input v-model="form.lastname" type="text" :placeholder="$t('signup.lastname')">
        </div>
        <p v-if="!$v.form.lastname.required && $v.form.lastname.$dirty" class="error-alert">
          {{ $t('signup.error.lastnameRequired') }}
        </p>
        <p v-if="!$v.form.lastname.forbiddenCharactersAndDigit && $v.form.lastname.$dirty" class="error-alert">
          {{ $t('signup.error.forbiddenCharacters') }}
        </p>
        <p v-if="!$v.form.lastname.minLength && $v.form.lastname.$dirty" class="error-alert">
          {{ $t('signup.error.lastnameSize') }}
        </p>
        <div class="signInForm__field">
          <label v-if="form.firstname" for="firstname">{{ $t('signup.firstname') }}</label>
          <input v-model="form.firstname" type="text" :placeholder="$t('signup.firstname')">
        </div>
        <p v-if="!$v.form.firstname.required && $v.form.firstname.$dirty" class="error-alert">
          {{ $t('signup.error.firstnameRequired') }}
        </p>
        <p v-if="!$v.form.firstname.forbiddenCharactersAndDigit && $v.form.firstname.$dirty" class="error-alert">
          {{ $t('signup.error.forbiddenCharacters') }}
        </p>
        <p v-if="!$v.form.firstname.minLength && $v.form.firstname.$dirty" class="error-alert">
          {{ $t('signup.error.firstnameSize') }}
        </p>
        <div class="signInForm__field">
          <label v-if="form.email" for="email">{{ $t('signup.email') }}</label>
          <input v-model="form.email" type="email" :placeholder="$t('signup.email')">
        </div>
        <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error-alert">
          {{ $t('signup.error.emailRequired') }}
        </p>
        <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error-alert">
          {{ $t('signup.error.emailValidRequired') }}}
        </p>
        <div class="signInForm__field">
          <label v-if="form.phone" for="phone">{{ $t('signup.phone') }}</label>
          <input v-model="form.phone" type="text" :placeholder="$t('signup.phone')">
        </div>
        <p v-if="!$v.form.phone.required && $v.form.phone.$dirty" class="error-alert">
          {{ $t('signup.error.phoneRequired') }}
        </p>
        <p v-if="(!$v.form.phone.phoneRegex || !$v.form.phone.minLength || !$v.form.phone.maxLength) && $v.form.phone.$dirty" class="error-alert">
          {{ $t('signup.error.phoneValidRequired') }}
        </p>
        <div class="signInForm__field">
          <label v-if="form.zipCode" for="zipCode">{{ $t('signup.postalCode') }}</label>
          <input v-model="form.zipCode" type="text" :placeholder="$t('signup.postalCode')">
        </div>
        <p v-if="!$v.form.zipCode.required && $v.form.zipCode.$dirty" class="error-alert">
          {{ $t('signup.error.postalCodeRequired') }}
        </p>
        <p v-if="(!$v.form.zipCode.forbiddenCharacters || !$v.form.zipCode.minLength || !$v.form.zipCode.maxLength) && $v.form.zipCode.$dirty" class="error">
          {{ $t('signup.error.postalCodeInvalid') }}
        </p>
        <p v-if="errorStatus" class="error-alert error-alert--center">
          {{ errorStatus }}
        </p>
        <div class="terms">
          <input id="" v-model="form.optin" type="checkbox" name="terms">
          <label for="terms">{{ $t('signup.terms') }} <a href="https://moderapp.com/espace-pro/confidentiality" target="_blank" rel="noopener">{{ $t('signup.privacyPolicy') }}</a> .</label>
        </div>
        <p v-if="!$v.form.optin.checked && $v.form.optin.$dirty" class="error-alert">
          {{ $t('signup.error.required') }}
        </p>
        <button v-if="signInProgress" class="cta--light cta--light--inProgress" type="submit" disabled>
          {{ $t('signup.inProgress') }}
        </button>
        <button v-else class="cta--light" type="submit">
          {{ $t('signup.signup') }}
        </button>
        <p class="loginForm__subText">
          {{ $t('signup.or') }}
        </p>
        <div class="cta--light cta--option" type="submit" @click="sendUiRequest('LOGIN')">
          {{ $t('ctaLogin') }}
        </div>
      </form>
    </section>
    <section v-else class="signInSucces">
      <p>
        {{ $t('signup.signinSucces') }}<br>
        {{ $t('signup.thxCheckYourEmails') }}
      </p>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

const phoneRegex = (value) => {
  return /^[^-=*<>\(\)\[\]$!/{}\"]{0,}$/.test(value)
}
const forbiddenCharacters = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}"]{0,}$/.test(value)
}
const forbiddenCharactersAndDigit = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}\d"]{0,}$/.test(value)
}
export default {
  data () {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        zipCode: '',
        phone: '',
        promoterId: '',
        optin: false,
      },
      errorStatus: '',
      signInSucces: false,
      signInProgress: false,
    }
  },
  validations: {
    form: {
      firstname: { required, forbiddenCharactersAndDigit, minLength: minLength(2) },
      lastname: { required, forbiddenCharactersAndDigit, minLength: minLength(2) },
      email: { required, email },
      zipCode: { required, forbiddenCharacters, minLength: minLength(4), maxLength: maxLength(20) },
      phone: { required, phoneRegex, minLength: minLength(9), maxLength: maxLength(20) },
      optin: { checked: value => value === true },
    },
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
    }),
    lang () {
      return this.$i18n.locale
    },
  },
  methods: {
    handleSignInSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.sendFormSignInData()
      }
    },
    sendUiRequest (type) {
      if (type === 'LOGIN') {
        this.$emit('loginRequest')
      }
    },
    sendFormSignInData () {
      this.signInProgress = true
      const body = {
        firstName: this.form.firstname,
        lastName: this.form.lastname,
        email: this.form.email,
        zipCode: this.form.zipCode,
        phone: this.form.phone,
        programId: this.program.programId,
        promoterId: this.program.promoterInfo.promoterId,
      }
      this.errorStatus = ''
      axios.post(`/moder_1_5/create-account?lang=${this.lang}`, body)
        .then(() => {
          this.signInSucces = true
          this.signInProgress = false
          setTimeout(() => { this.sendUiRequest('LOGIN') }, 3000)
        })
        .catch((error) => {
          this.signInProgress = false
          if (error.response.data.account.error.email) {
            this.errorStatus = 'Cette adresse mail est déjà dans notre base de données.'
          } else {
            this.errorStatus = error.response.data.account.error
          }
        })
    },
  },
}
</script>
