var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "user-identification",
      style: {
        backgroundImage:
          "url(" + _vm.$prefixLinks(_vm.program.background) + ")",
      },
    },
    [
      _c(
        "div",
        { staticClass: "user-identification-header" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "user-identification-header__return",
              attrs: {
                to: {
                  name: _vm.clientParams ? "client-lotId" : "lotId",
                  params: { lotId: _vm.lotIdParams },
                },
              },
            },
            [
              _c("img", {
                attrs: { src: "/icon/icon-return.png", alt: "icon" },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.program && _vm.isIdentificationPending
            ? _c("img", {
                staticClass: "user-identification-header__logo",
                attrs: { src: _vm.programLogoFiltered, alt: "" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.userAction === "LOGIN"
        ? _c("UserLogin", {
            on: {
              signinRequest: function ($event) {
                return _vm.setUserUi("SIGNIN")
              },
              recoveryRequest: function ($event) {
                return _vm.setUserUi("RECOVERY")
              },
              identificationPending: function ($event) {
                _vm.isIdentificationPending = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAction === "SIGNIN"
        ? _c("UserSignin", {
            on: {
              loginRequest: function ($event) {
                return _vm.setUserUi("LOGIN")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAction === "RECOVERY"
        ? _c("UserPasswordRecovery", {
            on: {
              loginRequest: function ($event) {
                return _vm.setUserUi("LOGIN")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }