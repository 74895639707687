<template>
  <nav class="menu-mobile">
    <UiBtnBurger @btnBurgerClicked="menuActive = true" />
    <UserInfos v-if="UI.userinfos" :user="loggedInUser" @clickOutside="UI.userinfos = false" @closeModal="UI.userinfos = false" />
    <div v-if="menuActive" class="menu-mobile__container">
      <div v-if="userStatus === 'acquirer'" class="menu-mobile__content">
        <UiBtnClose class="menu-mobile__close" @BtnCloseClicked="menuActive = false" />
        <nuxt-link :to="{name: clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-home-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.dashboard') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-lot' : 'lotId-lot', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-mon-bien-white.png" alt="icon program" class="menu-icon">
          <p>{{ $tc('menu.lot', userLotsLength) }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-program-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.program') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-project' : 'lotId-project', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-my-project-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.projects') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-docs' : 'lotId-docs', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-documents-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.docs') }}</p>
        </nuxt-link>
        <nuxt-link v-if="commercialInfos && commercialInfos.length > 0" :to="{name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-messages-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.messages') }}</p>
          <div v-if="notificationsMessages > 0" class="notifications menu-mobile__content-notification">
            {{ notificationsMessages }}
          </div>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-construction-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.construction') }}</p>
          <div v-if="notificationsNews > 0" class="notifications menu-mobile__content-notification">
            {{ notificationsNews }}
          </div>
        </nuxt-link>
        <VisitLinkSmall class="menu-mobile__visit" :program-background="programBackground" :link="visitUrl" />
        <p class="menu-mobile__link" @click="UI.language = true">
          {{ actualLanguage.toUpperCase() }}
        </p>
        <div v-if="isAuthenticated">
          <p class="menu-mobile__link" @click="UI.userinfos = true">
            {{ $t('menu.infos') }}
          </p>
          <p v-if="isAuthenticated" class="menu-mobile__link" @click="logout()">
            {{ $t('ctaLogout') }}
          </p>
        </div>
        <nuxt-link v-else :to="{ name: clientParams ? 'client-lotId' : 'lotId', params: { lotId: lotIdParams } }" class="menu-mobile__link" />
        <p class="cookies-link-container__link--absolute" @click="OpenCookiesSettings()">
          Cookies
        </p>
      </div>
      <div v-else class="menu-mobile__content">
        <UiBtnClose class="menu-mobile__close" @BtnCloseClicked="menuActive = false" />
        <nuxt-link :to="{name: clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-home-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.dashboard') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-program-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.program') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-project' : 'lotId-project', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-my-project-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.projects') }}</p>
        </nuxt-link>
        <nuxt-link v-if="commercialInfos && commercialInfos.length > 0" :to="{name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-messages-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.messages') }}</p>
        </nuxt-link>
        <nuxt-link :to="{name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }">
          <img src="/icon/icon-construction-white.png" alt="icon program" class="menu-icon">
          <p>{{ $t('menu.construction') }}</p>
        </nuxt-link>
        <VisitLinkSmall class="menu-mobile__visit" :program-background="programBackground" :link="visitUrl" />
        <p v-if="isAuthenticated" class="menu-mobile__link" @click="logout()">
          {{ $t('ctaLogout') }}
        </p>
        <nuxt-link v-else :to="{ name: clientParams ? 'client-lotId' : 'lotId', params: { lotId: lotIdParams } }" class="menu-mobile__link" />
        <p class="menu-mobile__link" @click="UI.language = true">
          {{ actualLanguage.toUpperCase() }}
        </p>
        <p class="cookies-link-container__link--absolute" @click="OpenCookiesSettings()">
          Cookies
        </p>
      </div>
    </div>
    <Language v-if="UI.language" @clickOutside="UI.language = false" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      menuActive: false,
      UI: {
        userinfos: false,
        language: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      programNameForUrl: 'program/getProgramNameForUrl',
      isAuthenticated: 'isAuthenticated',
      userStatus: 'user/getUserStatus',
      commercialInfos: 'program/commercialInfos',
      loggedInUser: 'loggedInUser',
      notificationsNews: 'news/getNotification',
      notificationsMessages: 'messages/getNotification',
      userLotsLength: 'lot/getLotsLength',
    }),
    actualLanguage () {
      return this.$i18n.locale
    },
    programBackground () {
      return `${this.ftp}${this.$store.state.program.programData.background}`
    },
    visitUrl () {
      let visitUrl
      if (this.$store.state.program.programData.title) {
        const urlEdited = this.$store.state.program.programData.title.split(' ').join('-')
        visitUrl = `www.moderapp.com/streaming/${urlEdited}`
      }
      return visitUrl
    },
  },
  methods: {
    logout () {
      this.$resetApp(this.$route.name)
    },
    OpenCookiesSettings () {
      this.$store.commit('cookies/SET_COOKIES_UPDATE_STATE', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-mobile {
  &__container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: $modal-bg-color;
    z-index: $z-index-navigation;
    animation: reveallight 300ms;
  }

  &__content {
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
    position: absolute;
    top: 0;
    width: 90%;
    min-height: 100%;
    z-index: $z-index-navigation;
    padding: 5rem 1rem;
    background-color: $bg-color;
    border-radius: 0 $border-radius $border-radius 0;
    animation: revealSlide 300ms;

    &-notification {
      color: $main-dark-color;
      font-weight: 600;
      position: absolute;
      right: 0;
      margin-right: 1rem;
    }
  }

  &__link,
  &__content a {
    display: flex;
    align-items: center;
    border-bottom: solid $border-color 1px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    min-width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &__content a,
  &__link {
    text-transform: capitalize;
  }

  &__close {
    font-size: $fs-text;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
  }

  &__visit {
    height: 12.5rem;
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
  }

  @media (min-width: $breakpoint-large) {
    display: none;
  }
}

.menu-icon {
  height: auto;
  width: 1.375rem;
  margin-right: 1rem;
  filter: invert(0.8);
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
}

.nuxt-link-active {
  color: var(--second-option-color);
  font-weight: 600;
}
</style>
