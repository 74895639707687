var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "doc",
      class: { "doc--read": _vm.doc.read },
      on: {
        click: function ($event) {
          return _vm.$emit("docClicked")
        },
      },
    },
    [
      _c("div", { staticClass: "doc__main" }, [
        _c(
          "div",
          {
            staticClass: "doc__title",
            class: { doc__notification: !_vm.doc.read },
          },
          [_vm._v("\n      " + _vm._s(_vm.doc.message) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "doc__date" }, [
          _vm._v("\n      " + _vm._s(_vm.doc.created_at) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }