var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "documents" }, [
    _c("h2", { staticClass: "documents__title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("documents.title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "documents__content" }, [
        _vm.choiceSheets && _vm.choiceSheets.length > 0
          ? _c("div", { staticClass: "documents-files" }, [
              _c("h3", { staticClass: "documents__subtitle" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("documents.files.subtitle")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lot-selector" }, [
                _c(
                  "span",
                  {
                    staticClass: "lot-selector__label",
                    attrs: { for: "lotnumber" },
                  },
                  [_vm._v(" LOT : ")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lotSelected,
                        expression: "lotSelected",
                      },
                    ],
                    staticClass: "lot-selector__select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.lotSelected = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.lotIdList, function (item, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        staticClass: "lot-selector__options",
                        domProps: { value: item },
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(item) + "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.choiceSheets &&
              _vm.choiceSheets.length > 0 &&
              _vm.selectedChoicesheet
                ? _c(
                    "ul",
                    { staticClass: "documents__list" },
                    [
                      _c(
                        "DocumentsItem",
                        {
                          staticClass: "documents__news",
                          attrs: { doc: _vm.selectedChoicesheet },
                          on: {
                            docClicked: function ($event) {
                              return _vm.setDocAsRead(
                                _vm.selectedChoicesheet.id
                              )
                            },
                          },
                        },
                        [
                          _vm.selectedChoicesheet.pdf_url &&
                          _vm.selectedChoicesheet.pdf_url.includes(".pdf")
                            ? _c(
                                "a",
                                {
                                  staticClass: "documents__link",
                                  attrs: {
                                    url: _vm.selectedChoicesheet.pdf_url,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDoc(
                                        _vm.selectedChoicesheet.id,
                                        _vm.selectedChoicesheet.pdf_url,
                                        _vm.selectedChoicesheet.read
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("documents.open")) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.program.timeline_finance && _vm.program.timeline_finance.length > 0
          ? _c("div", { staticClass: "documents-timeline" }, [
              _c("h3", { staticClass: "documents__subtitle" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("documents.timeline.title")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "timeline" }, [
                _c(
                  "ul",
                  { staticClass: "timeline__box" },
                  _vm._l(_vm.program.timeline_finance, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "timeline__item",
                        class: {
                          "timeline__item--checked": item.done === true,
                        },
                      },
                      [
                        _c("div", [
                          _c("p", { staticClass: "timeline__heading" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.title) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "timeline__subtitle" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.description) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      (!_vm.choiceSheets && !_vm.program.timeline_finance) ||
      (_vm.choiceSheets &&
        _vm.choiceSheets.length === 0 &&
        _vm.program.timeline_finance.length === 0)
        ? _c("div", [
            _c("p", { staticClass: "documents__content--empty" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("documents.files.noDocuments")) +
                  "\n      "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }