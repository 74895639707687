import axios from 'axios'
import Cookies from 'js-cookie'

export const state = () => ({
  messages: null,
  notification: 0,
  buyer: [],
})

export const actions = {
  async loadMessages ({ commit, dispatch }, { userId, commercialId, flatId, subProgramId }) {
    try {
      const token = await Cookies.get('temp-usr')
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      if (flatId && subProgramId) {
        const response = await axios.get(`/moder_1_5/message?sender_id=${userId}&receiver_id=${commercialId}&subProgramId=${subProgramId}&flatId=${flatId}&behalf=true`, { headers })
        commit('SET_MESSAGES', response.data.messages)
        commit('SET_BUYER', response.data.buyer)
        dispatch('checkMessagesNotifications', response.data.messages)
        return { messages: response.data.messages, buyer: response.data.buyer, status: response.status }
      } else {
        const response = await axios.get(`/moder_1_5/message?sender_id=${userId}&receiver_id=${commercialId}`, { headers })
        commit('SET_MESSAGES', response.data.messages)
        commit('SET_BUYER', response.data.buyer)
        dispatch('checkMessagesNotifications', response.data.messages)
        return { messages: response.data.messages, buyer: response.data.buyer, status: response.status }
      }
    } catch (error) {
      return error
    }
  },
  checkMessagesNotifications ({ commit }, conversationsByIds) {
    let notifications = 0
    if (conversationsByIds) {
      for (const conversations of Object.values(conversationsByIds)) {
        for (const msg of Object.values(conversations)) {
          const messagesUnread = Object.values(msg).filter(item => item.read === false)
          notifications = notifications + messagesUnread.length
        }
      }
    }
    commit('SET_MESSAGES_NOTIFICATION', notifications)
  },
}

export const mutations = {
  SET_MESSAGES: (state, messages) => {
    state.messages = messages
  },
  SET_MESSAGES_NOTIFICATION: (state, unreadConversations) => {
    state.notification = unreadConversations || 0
  },
  SET_BUYER: (state, list) => {
    state.buyer = list
  },
  RESET_MESSAGES: (state) => {
    state.messages = null
    state.notification = 0
    state.buyer = []
  },
}

export const getters = {
  getMessages: (state) => {
    return state.messages
  },
  getNotification: (state) => {
    return state.notification
  },
  getBuyer: (state) => {
    return state.buyer
  },
}
