var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "construction" }, [
    _c("h2", { staticClass: "construction__title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("construction.title")) + "\n  "),
    ]),
    _vm._v(" "),
    _vm.program
      ? _c("div", { staticClass: "construction__content" }, [
          _vm.news || _vm.program.constructionSiteImage
            ? _c("div", { staticClass: "construction-wrapper__left" }, [
                _c("section", { staticClass: "construction-news" }, [
                  _c("h3", { staticClass: "construction-news__heading" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("construction.news")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.news && _vm.news.postData.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "construction-news__list" },
                        [
                          _vm._l(_vm.news.postData, function (item, index) {
                            return _c("News", {
                              key: index,
                              attrs: { news: item },
                              on: {
                                newsClicked: function ($event) {
                                  return _vm.openNews(index, item.id, item.read)
                                },
                              },
                            })
                          }),
                          _vm._v(" "),
                          _vm.UI.newsOpened
                            ? _c("NewsFullscreen", {
                                attrs: {
                                  news: _vm.news.postData[_vm.newsReader.index],
                                },
                                on: {
                                  closeModalRequest: function ($event) {
                                    return _vm.closeNews()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c("p", { staticClass: "construction-news--empty" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("construction.noInformations")) +
                            "\n        "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _vm.program.constructionSiteImage &&
                _vm.program.constructionSiteImage.length > 0
                  ? _c(
                      "section",
                      { staticClass: "construction-visual" },
                      [
                        _c(
                          "h3",
                          { staticClass: "construction-visual__heading" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("construction.photos")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "construction-visual__content" },
                          _vm._l(
                            _vm.program.constructionSiteImage.map(function (
                              link
                            ) {
                              return _vm.$prefixLinks(link)
                            }),
                            function (item, index) {
                              return _c("img", {
                                key: index,
                                staticClass: "construction-visual__images",
                                attrs: {
                                  src: item,
                                  alt: "construction images",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.fullscreenGallery(index)
                                  },
                                },
                              })
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.UI.galleryFullscreen
                          ? _c("GalleryFullscreenZoom", {
                              attrs: {
                                gallery: _vm.gallery,
                                "gallery-index": _vm.galleryFullscreenIndex,
                              },
                              on: {
                                BtnCloseClicked: _vm.closeGallery,
                                clickOutside: _vm.closeGallery,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.program.timeline_construction_site &&
          _vm.program.timeline_construction_site.length > 0
            ? _c("section", { staticClass: "construction-timeline" }, [
                _c("h3", { staticClass: "construction-timeline__heading" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("construction.timeline")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "timeline" }, [
                  _c(
                    "ul",
                    { staticClass: "timeline__box" },
                    _vm._l(
                      _vm.program.timeline_construction_site,
                      function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "timeline__item",
                            class: {
                              "timeline__item--checked": item.done === true,
                            },
                          },
                          [
                            _c("div", [
                              _c("p", { staticClass: "timeline__heading" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "timeline__subtitle" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.description) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _c("div", [
          _c("p", [_vm._v(_vm._s(_vm.$t("construction.noInformations")))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }