var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apptContainer" },
    [
      _c("UiBtnClose", {
        on: {
          BtnCloseClicked: function ($event) {
            return _vm.$emit("closeRequest")
          },
        },
      }),
      _vm._v(" "),
      _vm.response.failed === true
        ? _c("div", { staticClass: "inputError" }, [
            _c("p", { staticClass: "error__text" }, [
              _vm._v("\n      Une erreur est survenue\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCalendarResLoaded === false && _vm.response.failed === false
        ? _c("Loader", { staticClass: "appointment__loader" })
        : _vm._e(),
      _vm._v(" "),
      _vm.UI.confirmation
        ? _c("p", { staticClass: "appointment-success" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("contactConfirmation.msg"))),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("contactConfirmation.thx")) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.apptSelected && _vm.isCalendarResLoaded === true
        ? _c("div", { staticClass: "appointment" }, [
            _c(
              "div",
              { staticClass: "appointment__header" },
              [
                !_vm.apptSelected
                  ? _c("UiBtnReturn", {
                      on: { emitClickBtnReturn: _vm.btnReturnApptClicked },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("h2", { staticClass: "appointment__title" }, [
                  _vm._v(
                    "\n        rendez-vous " + _vm._s(_vm.apptText) + "\n      "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "appointment__text" }, [
              _vm._v("\n      Choisir la date\n    "),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "date" }, [
              _c("div", { staticClass: "calendar" }, [
                _c("div", { staticClass: "calendar__days" }, [
                  !_vm.appt.date
                    ? _c("div", { staticClass: "daySelection" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.actualDate) +
                            "\n          "
                        ),
                      ])
                    : _c("div", { staticClass: "daySelection" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.formatedDate) +
                            "\n          "
                        ),
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "daysBox" },
                    [
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              L\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              M\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              M\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              J\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              V\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              S\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "daysBox__name" }, [
                        _vm._v("\n              D\n            "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.calendar, function (dates, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "daysBox__day",
                            class: { selectedDay: dates === _vm.appt.date },
                            attrs: { id: "daysBox_" + dates.date.slice(0, 10) },
                            on: {
                              click: function ($event) {
                                return _vm.setSelectedDay(dates, $event)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  dayInactive: _vm.weekendFilter(
                                    _vm.normalizeDate(dates.date)
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .normalizeDate(dates.date)
                                        .split(" ")
                                        .splice(1, 1)
                                        .join("")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "calendar__hours" },
                  _vm._l(_vm.appt.date.values, function (hours) {
                    return _c(
                      "div",
                      {
                        key: hours.startDate,
                        staticClass: "timeSlot",
                        class: {
                          "timeSlot-inactive": hours.isFree === false,
                          "timeSlot-active": hours.isFree === true,
                          selectedDay: hours.startDate === _vm.appt.startDate,
                        },
                        attrs: { id: "timeSlot_" + hours.hour },
                        on: {
                          click: function ($event) {
                            _vm.appt.startDate = hours.startDate
                          },
                        },
                      },
                      [
                        hours.isFree === true
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(hours.hour + " H") +
                                  "\n            "
                              ),
                            ])
                          : _c("div", [
                              _vm._v("\n              Réservé\n            "),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.apptResumeError
              ? _c("div", { staticClass: "error-alert error-alert--center" }, [
                  _vm._v(
                    "\n      Veuillez sélectionner la date et l'heure de votre rendez-vous\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "validation" }, [
              _c(
                "button",
                { staticClass: "cta", on: { click: _vm.apptSubmit } },
                [_vm._v("\n        CONTINUER\n      ")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.apptSelected && !_vm.UI.confirmation
        ? _c(
            "div",
            { staticClass: "appointment" },
            [
              _c(
                "div",
                { staticClass: "appointment__header" },
                [
                  _c("UiBtnReturn", {
                    on: { emitClickBtnReturn: _vm.apptReset },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "appointment__title" }, [
                    _vm._v(
                      "\n        rendez-vous " +
                        _vm._s(_vm.formatedTextConfirmation) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "appointment__subtitle" }, [
                _vm._v(
                  _vm._s(_vm.formatedDate) +
                    " à " +
                    _vm._s(_vm.formatedHour) +
                    " heures"
                ),
              ]),
              _vm._v(" "),
              _c("FormExternQrForm", {
                staticClass: "appointment__contactForm",
                attrs: {
                  "user-choice-location": _vm.apptType,
                  "user-choice-start-date": _vm.appt.startDate,
                },
                on: {
                  statusOK: function ($event) {
                    return _vm.setConfirmationUI()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }