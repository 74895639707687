var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.programData
    ? _c(
        "section",
        { staticClass: "landing-header" },
        [
          _c(
            "div",
            { staticClass: "landing-header__menu" },
            [
              _vm.userRole === "buyer" && _vm.buyerAccess
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-login"
                            : "lotId-login",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c("button", { staticClass: "menu__btn" }, [
                        _c("img", {
                          attrs: {
                            src: "/icon/icon-new-home.png",
                            alt: "icon",
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userRole === "admin" && _vm.buyerAccess
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clientPageRedirection()
                        },
                      },
                    },
                    [_vm._m(0)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthenticated
                ? _c("div", { staticClass: "menu__user" }, [
                    _vm.loggedInUser
                      ? _c(
                          "p",
                          {
                            staticClass: "menu__userinfos",
                            on: {
                              click: function ($event) {
                                _vm.UI.userinfos = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.loggedInUser.firstName) +
                                " " +
                                _vm._s(_vm.loggedInUser.lastName) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "menu__logout",
                        on: {
                          click: function ($event) {
                            return _vm.logout()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("ctaLogout")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthenticated && _vm.buyerAccess
                ? _c(
                    "nuxt-link",
                    {
                      staticClass: "menu__login",
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-login"
                            : "lotId-login",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.$t("ctaLogin")) + "\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.actualLanguage
                ? _c(
                    "p",
                    {
                      staticClass: "menu__link language",
                      on: {
                        click: function ($event) {
                          _vm.UI.language = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.actualLanguage.toUpperCase()) +
                          "\n    "
                      ),
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticClass: "menu__link language",
                      on: {
                        click: function ($event) {
                          _vm.UI.language = true
                        },
                      },
                    },
                    [_vm._v("\n      Lang\n    ")]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.UI.userinfos
            ? _c("UserInfos", {
                attrs: { user: _vm.loggedInUser },
                on: {
                  clickOutside: function ($event) {
                    _vm.UI.userinfos = false
                  },
                  closeModal: function ($event) {
                    _vm.UI.userinfos = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.UI.language
            ? _c("Language", {
                on: {
                  clickOutside: function ($event) {
                    _vm.UI.language = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "menu__btn" }, [
      _c("img", { attrs: { src: "/icon/icon-new-home.png", alt: "icon" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }