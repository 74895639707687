<template>
  <p v-if="UI.confirmation" class="contactForm-succes">
    {{ $t('contactConfirmation.msg') }}<br>{{ $t('contactConfirmation.thx') }}
  </p>
  <div v-else class="contactFormContainer">
    <UiBtnClose @BtnCloseClicked="closeForm()" />
    <div class="contactForm">
      <div class="contactForm__header">
        <UiBtnReturn @emitClickBtnReturn="btnReturnInfoClicked" />
        <h2 class="contactForm__title">
          {{ $t('contactFormInfo.title') }}
        </h2>
      </div>
      <p v-if="program.promoterInfo.name" class="contactForm__subTitle">
        {{ $t('contactFormInfo.subTitle') }} {{ program.promoterInfo.name }}. {{ $t('contactFormInfo.subTitle2') }}
      </p><p v-else class="contactForm__subTitle">
        {{ $t('contactFormInfo.subTitle2') }}.
      </p>
      <FormInternForm form-type="information" @formSent="setConfirmationUI" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      status: '',
      UI: {
        confirmation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
    }),
  },
  methods: {
    btnReturnInfoClicked () {
      this.$emit('btnReturnInfoClicked')
    },
    setConfirmationUI () {
      this.UI.confirmation = true
      setTimeout(() => {
        this.$emit('requestToCloseForm')
      }, 4000)
    },
    closeForm () {
      this.$emit('requestToCloseForm')
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation {
  margin: auto;
}

.contactFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background-color: $main-light-color;
  position: relative;

  @media (min-width: $breakpoint-small) {
    margin: auto;
    display: flex;
  }
  @media (min-width: $breakpoint-large) {
    width: 84%;
    height: 84%;
    margin: auto;
  }
}

.contactForm {
  margin: auto;
  border-radius: $border-radius;
  padding: 2rem 10%;
  background-color: $main-light-color;
  animation: revealSlidelight 500ms;
  font-size: $fs-text;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $main-dark-color;
    font-size: $fs-title;
    font-weight: 900;
    text-transform: uppercase;
    margin-left: 1rem;
  }
}

.contactForm-succes {
  width: auto;
  margin: auto;
  border-radius: $border-radius;
  padding: 2rem 10%;
  background-color: $main-light-color;
  animation: revealSlidelight 500ms;
  font-size: $fs-text;
}
</style>
