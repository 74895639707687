<template>
  <form class="form" @submit.prevent="handleSubmit">
    <div class="gender form__field subWrapper">
      <div class="form__field-radio">
        <input id="woman" v-model="form.gender" type="radio" value="Woman" name="gender">
        <label class="label__woman" for="woman"> {{ $t('contactForm.gender.woman') }}</label>
        <input id="man" v-model="form.gender" type="radio" value="Man" name="gender">
        <label class="label__man" for="man"> {{ $t('contactForm.gender.man') }}</label>
      </div>
      <p v-if="!$v.form.gender.required && $v.form.gender.$dirty" class="error-alert">
        {{ $t('contactForm.error.required') }}
      </p>
    </div>
    <div class="wrapper subWrapper">
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.lastname" for="lastname">{{ $t('contactForm.lastname') }}</label>
          <input v-model="form.lastname" type="text" :placeholder="$t('contactForm.lastname')" name="lastname">
        </div>
        <p v-if="!$v.form.lastname.required && $v.form.lastname.$dirty" class="error-alert">
          {{ $t('contactForm.error.lastnameRequired') }}
        </p>
        <p v-if="!$v.form.lastname.forbiddenCharactersAndDigit && $v.form.lastname.$dirty" class="error-alert">
          {{ $t('contactForm.error.lastnameValidRequired') }}
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.firstname" for="firstname">{{ $t('contactForm.firstname') }}</label>
          <input v-model="form.firstname" type="text" :placeholder="$t('contactForm.firstname')" name="firstname">
        </div>
        <p v-if="!$v.form.firstname.required && $v.form.firstname.$dirty" class="error-alert">
          {{ $t('contactForm.error.firstnameRequired') }}
        </p>
        <p v-if="!$v.form.firstname.forbiddenCharactersAndDigit && $v.form.firstname.$dirty" class="error-alert">
          {{ $t('contactForm.error.firstnameValidRequired') }}
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.email" for="email">{{ $t('contactForm.email') }}</label>
          <input v-model="form.email" type="email" :placeholder="$t('contactForm.email')" name="email">
        </div>
        <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error-alert">
          {{ $t('contactForm.error.emailRequired') }}
        </p>
        <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error-alert">
          {{ $t('contactForm.error.emailValidRequired') }}
        </p>
      </div>
    </div>
    <div class="subWrapper">
      <div class="form__field form__field-text">
        <label v-if="form.address" for="address">{{ $t('contactForm.address') }}</label>
        <input v-model="form.address" type="text" :placeholder="$t('contactForm.address')" name="address">
      </div>
      <p v-if="!$v.form.address.required && $v.form.address.$dirty" class="error-alert">
        {{ $t('contactForm.error.addressRequired') }}
      </p>
      <p v-if="!$v.form.address.forbiddenCharacters && $v.form.address.$dirty" class="error-alert">
        {{ $t('contactForm.error.addressValidRequired') }}
      </p>
    </div>
    <div class="wrapper subWrapper">
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.postalCode" for="postalCode">{{ $t('contactForm.zipCode') }}</label>
          <input v-model="form.postalCode" type="text" :placeholder="$t('contactForm.zipCode')" name="postalCode">
        </div>
        <p v-if="!$v.form.postalCode.required && $v.form.postalCode.$dirty" class="error-alert">
          {{ $t('contactForm.error.zipCodeRequired') }}
        </p>
        <p v-if="(!$v.form.postalCode.forbiddenCharacters || !$v.form.postalCode.minLength || !$v.form.postalCode.maxLength) && $v.form.postalCode.$dirty" class="error">
          {{ $t('contactForm.error.zipCodeInvalid') }}
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.city" for="city">{{ $t('contactForm.city') }}</label>
          <input v-model="form.city" type="text" :placeholder="$t('contactForm.city')" name="city">
        </div>
        <p v-if="!$v.form.city.required && $v.form.city.$dirty" class="error-alert">
          {{ $t('contactForm.error.cityRequired') }}
        </p>
        <p v-if="!$v.form.city.forbiddenCharactersAndDigit && $v.form.city.$dirty" class="error-alert">
          {{ $t('contactForm.error.cityValidRequired') }}
        </p>
      </div>
      <div>
        <div class="form__field form__field-text">
          <label v-if="form.mobilePhone" for="phone">{{ $t('contactForm.mobile') }}</label>
          <input v-model="form.mobilePhone" type="text" :placeholder="$t('contactForm.mobile')" name="phone">
        </div>
        <p v-if="!$v.form.mobilePhone.required && $v.form.mobilePhone.$dirty" class="error-alert">
          {{ $t('contactForm.error.phoneRequired') }}
        </p>
        <p v-if="!$v.form.mobilePhone.phoneRegex && $v.form.mobilePhone.$dirty" class="error-alert">
          {{ $t('contactForm.error.phoneValidRequired') }}
        </p>
        <p v-if="(!$v.form.mobilePhone.minLength || !$v.form.mobilePhone.maxLength )&& $v.form.mobilePhone.phoneRegex && $v.form.mobilePhone.$dirty" class="error">
          {{ $t('contactForm.error.phoneValidRequired') }}
        </p>
      </div>
    </div>
    <div class="project form__field">
      <p class="project__title">
        {{ $t('contactForm.project') }}
      </p>
      <div class="form__field-radio">
        <input id="live" v-model="form.projectType" type="radio" value="Inhabitant" name="projectType">
        <label class="label__live" for="live">{{ $t('contactForm.live') }} </label>
        <input id="invest" v-model="form.projectType" type="radio" value="Investor" name="projectType">
        <label class="label__invest" for="invest">{{ $t('contactForm.invest') }}</label>
      </div>
      <p v-if="!$v.form.projectType.required && $v.form.projectType.$dirty" class="error-alert">
        {{ $t('contactForm.error.required') }}
      </p>
    </div>
    <div v-if="formType === 'appointment'" class="location form__field">
      <p class="location__title">
        {{ $t('contactForm.location') }}
      </p>
      <div class="form__field-location">
        <input id="phone" v-model="form.location" type="radio" value="phone" name="location">
        <label for="phone">{{ $t('contactForm.phone') }} </label>
        <input id="visio" v-model="form.location" type="radio" value="visio" name="location">
        <label for="visio">{{ $t('contactForm.visio') }}</label>
        <input id="home" v-model="form.location" type="radio" value="home" name="location">
        <label for="home">{{ $t('contactForm.home') }}</label>
        <input id="office" v-model="form.location" type="radio" value="office" name="location">
        <label for="office">{{ $t('contactForm.office') }}</label>
      </div>
    </div>
    <div class="terms">
      <input id="" v-model="form.optin" type="checkbox" name="terms">
      <label for="terms">{{ $t('contactForm.terms') }} <a href="https://moderapp.com/espace-pro/confidentiality" target="_blank" rel="noopener">{{ $t('contactForm.privacyPolicy') }}.</a></label>
    </div>
    <p v-if="!$v.form.optin.checked && $v.form.optin.$dirty" class="error-alert">
      {{ $t('contactForm.error.required') }}
    </p>
    <div class="validation">
      <section class="captcha">
        <div>
          <label>{{ captcha.randomNbr1 }} + {{ captcha.randomNbr2 }} = </label>
          <input v-model="captcha.result" type="text" class="captcha__result"> ?
        </div>
        <div v-if="!$v.captcha.result.required && $v.form.mobilePhone.$dirty" class="error-alert">
          {{ $t('contactForm.captchaRequired') }}
        </div>
        <div v-if="!$v.captcha.result.numeric && $v.form.mobilePhone.$dirty" class="error-alert">
          {{ $t('contactForm.captchaValidRequired') }}
        </div>
        <div v-if="captcha.error === true" class="error-alert">
          {{ $t('contactForm.captchaFalse') }}
        </div>
      </section>
      <button v-if="status !== 'PENDING'" class="cta" type="submit">
        {{ $t('contactForm.cta') }}
      </button>
      <p v-if="status === 'PENDING'" class="cta cta-alert--inprogress">
        {{ $t('contactForm.sending') }}
      </p>
    </div>
    <p v-if="status === 'ERROR'" class="error-alert--center">
      {{ $t('contactForm.error.global') }}
    </p>
  </form>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

const phoneRegex = (value) => {
  return /^[^-=*<>\(\)\[\]$!/{}\"]{0,}$/.test(value)
}
const forbiddenCharacters = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}"]{0,}$/.test(value)
}
const forbiddenCharactersAndDigit = (value) => {
  return /^[^=*<>\(\)\[\]$!/{}\d"]{0,}$/.test(value)
}

export default {
  props: {
    formType: {
      type: String,
      default: '',
    },
    userDate: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      form: {
        gender: '',
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        postalCode: '',
        city: '',
        mobilePhone: '',
        projectType: '',
        location: '',
        optin: false,
      },
      captcha: {
        randomNbr1: '',
        randomNbr2: '',
        result: '',
        error: false,
      },
      status: '',
    }
  },
  validations: {
    form: {
      gender: { required },
      firstname: { required, forbiddenCharactersAndDigit },
      lastname: { required, forbiddenCharactersAndDigit },
      email: { required, email },
      address: { required, forbiddenCharacters },
      postalCode: { required, forbiddenCharacters, minLength: minLength(4), maxLength: maxLength(20) },
      city: { required, forbiddenCharactersAndDigit },
      mobilePhone: { required, phoneRegex, minLength: minLength(9), maxLength: maxLength(20) },
      projectType: { required },
      optin: { checked: value => value === true },
    },
    captcha: {
      result: { required, numeric },
    },
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    ...mapGetters({
      program: 'program/getProgramData',
    }),
  },
  created () {
    this.generateCaptcha()
  },
  methods: {
    handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sendFormData()
      }
    },
    sendDataToInformationApi (lot) {
      const phoneFormated = this.form.mobilePhone.split(/\D/).join('')
      const postalCodeFormated = this.form.postalCode.split(' ').join('')
      const fullName = `${this.form.firstname} ${this.form.lastname.toUpperCase()}`

      const body = {
        name: fullName,
        zipCode: postalCodeFormated,
        phone: phoneFormated,
        address: this.form.address,
        city: this.form.city,
        email: this.form.email,
        programId: this.program.programId,
        projectType: this.form.projectType,
        flatNumber: lot,
      }
      this.status = 'PENDING'
      axios
        .post(`/moder_1_5/emails/orbital?lang=${this.lang}`, body)
        .then((response) => {
          if (response.status === 200) {
            this.status = 'SENT'
            this.closeFormRequest()
          }
        })
        .catch(() => {
          this.status = 'ERROR'
        })
    },
    sendDataToAppointmentApi (lot) {
      const phoneFormated = this.form.mobilePhone.split(/\D/).join('')
      const postalCodeFormated = this.form.postalCode.split(' ').join('')
      const fullName = `${this.form.firstname} ${this.form.lastname.toUpperCase()}`

      const body = {
        name: fullName,
        zipCode: postalCodeFormated,
        phone: phoneFormated,
        address: this.form.address,
        city: this.form.city,
        email: this.form.email,
        programId: this.program.programId,
        projectType: this.form.projectType,
        date: this.date,
        location: this.form.location,
        flatNumber: lot,
      }

      this.status = 'PENDING'
      axios
        .post(`/moder_1_5/emails/appointment?lang=${this.lang}`, body)
        .then((response) => {
          if (response.status === 200) {
            this.status = 'SENT'
            this.closeFormRequest()
          }
        })
        .catch(() => {
          this.status = 'ERROR'
        })
    },
    sendDataToDemonstrationForm (lot) {
      const phoneFormated = this.form.mobilePhone.split(/\D/).join('')
      const postalCodeFormated = this.form.postalCode.split(' ').join('')
      const fullName = `${this.form.firstname} ${this.form.lastname.toUpperCase()}`

      const body = {
        name: fullName,
        zipCode: postalCodeFormated,
        phone: phoneFormated,
        address: this.form.address,
        city: this.form.city,
        email: this.form.email,
        programId: this.program.programId,
        projectType: this.form.projectType,
        date: this.date,
        flatNumber: lot,
      }

      this.status = 'PENDING'
      axios
        .post(`/moder_1_5/emails/demonstration?lang=${this.lang}`, body)
        .then((response) => {
          if (response.status === 200) {
            this.status = 'SENT'
            this.closeFormRequest()
          }
        })
        .catch(() => {
          this.status = 'ERROR'
        })
    },
    sendFormData () {
      if (this.isCaptchaVerificationSuccess()) {
        const lot = null

        if (this.formType === 'information') {
          this.sendDataToInformationApi(lot)
        }

        if (this.formType === 'appointment' && this.date) {
          this.sendDataToAppointmentApi(lot)
        }

        if (this.formType === 'demonstration' && this.date) {
          this.sendDataToDemonstrationForm(lot)
        }
      }
    },
    isCaptchaVerificationSuccess () {
      const resultDone = this.captcha.randomNbr1 + this.captcha.randomNbr2
      if (parseInt(this.captcha.result) === resultDone) {
        this.captcha.error = false
        return true
      } else {
        this.captcha.error = true
        this.generateCaptcha()
        return false
      }
    },
    generateCaptcha () {
      const randomNbr1 = Math.floor(Math.random() * 10)
      const randomNbr2 = Math.floor(Math.random() * 10)
      this.captcha.randomNbr1 = randomNbr1
      this.captcha.randomNbr2 = randomNbr2
    },
    closeFormRequest () {
      this.$emit('formSent')
    },
  },
}
</script>

<style lang="scss" scoped>
.validation {
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: $breakpoint-small) {
    padding-top: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.project {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-small) {
      max-width: 33%;
  }

  &__title {
    color: $main-dark-color;
    font-weight: bolder;
  }
}

.location {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &__title {
    margin-right: 2rem;
    color: $main-dark-color;
    font-weight: bolder;
  }
}

input {
  border: none;
  border-bottom: 1px solid $main-medium-color;
  color: $main-dark-color;
  font-size: $fs-text;
  padding: 2px 0;

  &[type="text"]::placeholder,
  &[type="email"]::placeholder {
    border: solid $main-dark-color 1px;
    color: $main-medium-color;
    border: 0;
    font-weight: lighter;
  }

  &[type="radio"] + label {
    color: $main-medium-color;
  }

  &[type="radio"]:checked + label {
    font-weight: 600;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

textarea {
  font-weight: bold;
  outline: none;
}
.subWrapper {
  padding-bottom: 1.6rem;
  margin-bottom: 4px;

  @media screen and (max-width: 890px) {
    padding-bottom: 1.2rem;
  }
}

.wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper > * {
  width: 27%;

  @media screen and (max-width: 890px) {
    margin: 0 0;
  }
}

.wrapper,
.form__field-text {
  display: flex;
}

.form {
  margin-top: 2rem;
  width: 100%;
  animation: revealSlidelight 400ms;

  &__field-radio {
    width: 250px;
  }

  &__field-location {
    width: auto;
  }

  &__field-location > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-radio > * {
    padding: 0 1rem 0 0.5rem;
  }

  &__field-text {
    flex-direction: column;
    position: relative;
  }

  &__field-text > label {
    color: $main-medium-color;
    font-size: $fs-text;
    position: absolute;
    top: -1rem;
  }
}

@media screen and (max-width: 892px) and (orientation: portrait) {
  .wrapper {
    display: block;
  }

  .wrapper > * {
    width: 100%;
    margin: 1rem 0;
  }
}

.form__field-text > label {
  animation: revealSlidelight 400ms;
}
</style>
