var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signInContainer" }, [
    !_vm.signInSucces
      ? _c("section", [
          _c("h2", { staticClass: "signInContainer__title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("signup.title")) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "signInForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSignInSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "signInForm__field" }, [
                _vm.form.lastname
                  ? _c("label", { attrs: { for: "lastname" } }, [
                      _vm._v(_vm._s(_vm.$t("signup.lastname"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.lastname,
                      expression: "form.lastname",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("signup.lastname"),
                  },
                  domProps: { value: _vm.form.lastname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "lastname", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.$v.form.lastname.required && _vm.$v.form.lastname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.lastnameRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.form.lastname.forbiddenCharactersAndDigit &&
              _vm.$v.form.lastname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.forbiddenCharacters")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.form.lastname.minLength && _vm.$v.form.lastname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.lastnameSize")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "signInForm__field" }, [
                _vm.form.firstname
                  ? _c("label", { attrs: { for: "firstname" } }, [
                      _vm._v(_vm._s(_vm.$t("signup.firstname"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.firstname,
                      expression: "form.firstname",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("signup.firstname"),
                  },
                  domProps: { value: _vm.form.firstname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "firstname", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.$v.form.firstname.required && _vm.$v.form.firstname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.firstnameRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.form.firstname.forbiddenCharactersAndDigit &&
              _vm.$v.form.firstname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.forbiddenCharacters")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.form.firstname.minLength && _vm.$v.form.firstname.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.firstnameSize")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "signInForm__field" }, [
                _vm.form.email
                  ? _c("label", { attrs: { for: "email" } }, [
                      _vm._v(_vm._s(_vm.$t("signup.email"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  attrs: { type: "email", placeholder: _vm.$t("signup.email") },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.$v.form.email.required && _vm.$v.form.email.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.emailRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.form.email.email && _vm.$v.form.email.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.emailValidRequired")) +
                        "}\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "signInForm__field" }, [
                _vm.form.phone
                  ? _c("label", { attrs: { for: "phone" } }, [
                      _vm._v(_vm._s(_vm.$t("signup.phone"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.phone,
                      expression: "form.phone",
                    },
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("signup.phone") },
                  domProps: { value: _vm.form.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "phone", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.$v.form.phone.required && _vm.$v.form.phone.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.phoneRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              (!_vm.$v.form.phone.phoneRegex ||
                !_vm.$v.form.phone.minLength ||
                !_vm.$v.form.phone.maxLength) &&
              _vm.$v.form.phone.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.phoneValidRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "signInForm__field" }, [
                _vm.form.zipCode
                  ? _c("label", { attrs: { for: "zipCode" } }, [
                      _vm._v(_vm._s(_vm.$t("signup.postalCode"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.zipCode,
                      expression: "form.zipCode",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("signup.postalCode"),
                  },
                  domProps: { value: _vm.form.zipCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "zipCode", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.$v.form.zipCode.required && _vm.$v.form.zipCode.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.postalCodeRequired")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              (!_vm.$v.form.zipCode.forbiddenCharacters ||
                !_vm.$v.form.zipCode.minLength ||
                !_vm.$v.form.zipCode.maxLength) &&
              _vm.$v.form.zipCode.$dirty
                ? _c("p", { staticClass: "error" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.postalCodeInvalid")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.errorStatus
                ? _c("p", { staticClass: "error-alert error-alert--center" }, [
                    _vm._v("\n        " + _vm._s(_vm.errorStatus) + "\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "terms" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.optin,
                      expression: "form.optin",
                    },
                  ],
                  attrs: { id: "", type: "checkbox", name: "terms" },
                  domProps: {
                    checked: Array.isArray(_vm.form.optin)
                      ? _vm._i(_vm.form.optin, null) > -1
                      : _vm.form.optin,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.optin,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "optin", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "optin",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "optin", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "terms" } }, [
                  _vm._v(_vm._s(_vm.$t("signup.terms")) + " "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://moderapp.com/espace-pro/confidentiality",
                        target: "_blank",
                        rel: "noopener",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("signup.privacyPolicy")))]
                  ),
                  _vm._v(" ."),
                ]),
              ]),
              _vm._v(" "),
              !_vm.$v.form.optin.checked && _vm.$v.form.optin.$dirty
                ? _c("p", { staticClass: "error-alert" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("signup.error.required")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.signInProgress
                ? _c(
                    "button",
                    {
                      staticClass: "cta--light cta--light--inProgress",
                      attrs: { type: "submit", disabled: "" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("signup.inProgress")) +
                          "\n      "
                      ),
                    ]
                  )
                : _c(
                    "button",
                    { staticClass: "cta--light", attrs: { type: "submit" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("signup.signup")) +
                          "\n      "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c("p", { staticClass: "loginForm__subText" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("signup.or")) + "\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cta--light cta--option",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.sendUiRequest("LOGIN")
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("ctaLogin")) + "\n      ")]
              ),
            ]
          ),
        ])
      : _c("section", { staticClass: "signInSucces" }, [
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.$t("signup.signinSucces"))),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("signup.thxCheckYourEmails")) +
                "\n    "
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }