
export const state = () => ({
  linkTargetOption: '_self',
})

export const mutations = {
  SET_LINK_TARGET_OPTION: (state, target) => {
    state.linkTargetOption = target
  },
}

export const getters = {
  getLinkTargetOption: (state) => {
    return state.linkTargetOption
  },
}
