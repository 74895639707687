var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.appNotReady && _vm.isDomReady
    ? _c(
        "div",
        {
          staticClass: "app-template",
          style: { display: _vm.appNotReady ? "none" : "" },
          attrs: { id: "app" },
        },
        [
          _c("app-header", {
            staticClass: "app-header",
            on: {
              displayContact: function ($event) {
                return _vm.toggleContactUI()
              },
              displayUserCard: function ($event) {
                return _vm.toggleUserCardUI()
              },
            },
          }),
          _vm._v(" "),
          _c("m-page", { staticClass: "app-main" }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "reveal-light" } },
            [
              _vm.UI.contact || _vm.UI.userCard
                ? _c(
                    "mlab-modal-container",
                    {
                      staticClass: "app-modal",
                      nativeOn: {
                        mousedown: function ($event) {
                          return _vm.closeModals({
                            event: $event,
                            expetion: null,
                          })
                        },
                        touchdown: function ($event) {
                          return _vm.closeModals({
                            event: $event,
                            exeption: null,
                          })
                        },
                      },
                    },
                    [
                      _vm.UI.contact
                        ? _c("m-contact")
                        : _vm.UI.userCard
                        ? _c("m-user-card")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("PoweredBy"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }