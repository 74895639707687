<template>
  <div id="map">
    <gmap-map
      :center="{ lat: Number(`${center.lat}`), lng: Number(`${center.lng}`),}"
      :zoom="15"
      :options="{
        mapTypeControl: true,
        mapTypeControlOptions: { style: 3, position: 6, },}"
      class="gMap"
    >
      <gmap-marker :position="{ lat: Number(`${center.lat}`), lng: Number(`${center.lng}`),}" class="gMap__marker" />
      <gmap-info-window :position="{ lat: Number(`${center.lat}`), lng: Number(`${center.lng}`),}" :opened="true" class="gMap__info-window">
        <img :src="$prefixLinks(program.promoterInfo.logo.main)" class="gMap__logo" alt="logo">
        <p class="gMap__info">
          {{ program.title }}
        </p>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    center: {
      type: Object,
      default () {
        return {
          lat: 0,
          lng: 0,
        }
      },
    },
  },
  data () {
    return {
      ftp: process.env.FTP,
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
    }),
  },
  methods: {
    closeWindowRequest () {
      this.$emit('closeWindowRequest')
    },
  },
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  margin: auto;
  height: 300px;

  @media (min-width: $breakpoint-medium) {
    height: 600px;
    width: 80%;
  }
}

.gMap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  text-align: center;

  &__info {
    font-size: 0.9vmax;
    font-weight: 700;
    color: var(--second-option-color);
  }

  &__logo {
    height: 3vmax;
    width: auto;
  }
}
</style>
