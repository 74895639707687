var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "languages" },
        [
          _c("UiBtnClose", {
            staticClass: "languages__btnClose",
            on: {
              BtnCloseClicked: function ($event) {
                return _vm.$emit("clickOutside")
              },
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "languages__heading" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("language")) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.availableLocales, function (lang, index) {
              return _c(
                "button",
                {
                  key: index,
                  staticClass: "languages__item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.setLanguage(lang.code)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(lang.name) + "\n      ")]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }