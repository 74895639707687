var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _vm.gallery
        ? _c("section", { staticClass: "carousel-wrapper-fullscreen" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "swiper",
                    rawName: "v-swiper",
                    value: _vm.swiperOption,
                    expression: "swiperOption",
                  },
                ],
                ref: "mySwiper",
              },
              [
                _c("UiBtnClose", {
                  staticClass: "swiper-close-btn",
                  on: {
                    BtnCloseClicked: function ($event) {
                      return _vm.$emit("BtnCloseClicked")
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.gallery, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "swiper-slide" },
                      [
                        _c("div", { staticClass: "swiper-zoom-container" }, [
                          _c("img", { key: index, attrs: { src: item } }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.gallery.length > 1
                  ? _c("div", { staticClass: "swiper-pagination" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.gallery.length > 1
                  ? _c("div", {
                      staticClass: "swiper-button-prev",
                      attrs: { "aria-label": "prev" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.gallery.length > 1
                  ? _c("div", {
                      staticClass: "swiper-button-next",
                      attrs: { "aria-label": "next" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }