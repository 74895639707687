<template>
  <div class="projects">
    <h2 class="projects__title">
      {{ $t('projects.title') }}
    </h2>
    <div class="card-container">
      <ProjectsItem v-for="(project, index) in projects" :key="index" class="project-item" :index="index" :project="project" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectsItem from '~/components/Projects/ProjectsItem'

export default {
  components: {
    ProjectsItem,
  },
  computed: {
    ...mapGetters({
      projects: 'projects/projects',
    }),
  },
}
</script>

<style lang="scss" scoped>
.projects {
  padding: min(2rem, 6%);
  background-color: $main-light-color;
  animation: reveallight 600ms;

  @media (min-width: $breakpoint-medium) {
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 3rem 3rem;
  }

  &__title {
    @extend %ext-title;
  }
}
.card-container {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-bottom: 0.5rem;
    @media (min-width: $breakpoint-small) {
        margin-right: 0.5rem;
    }
  }
}

.project-card {
  border: solid $border-color 1px;
  padding: 1.4rem;
  border-radius: $border-radius;
  width: 92%;
  margin: auto;
  background-color: $bg-color;

  @media (min-width: $breakpoint-small) {
    margin: 0;
    width: 22rem;
  }
  &__cta {
    font-size: 0.7rem;
    border: solid black 1px;
    border-radius: $border-radius;
    padding: 0.3rem 0.4rem;
    background-color: var(--main-option-color);
    color: $main-light-color;
    text-transform: uppercase;
    font-weight: 700;
    transition: background-color 200ms, border 200ms, color 200ms;

    &--ghost {
      background-color: $bg-color;
      color: $main-dark-color;
    }

    &:hover {
      background-color: var(--second-option-color);
      border: solid var(--second-option-color) 1px;
      color: $main-light-color;
      cursor: pointer;
    }
  }
}
</style>
