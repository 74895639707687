var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.programData
    ? _c(
        "section",
        { staticClass: "theHeader" },
        [
          _c(
            "div",
            { staticClass: "theHeader__menu" },
            [
              _vm.userStatus === "acquirer"
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-home"
                            : "lotId-home",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "menu__btn menu__btn--active" },
                        [
                          _c("img", {
                            attrs: {
                              src: "/icon/icon-new-home.png",
                              alt: "icon",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "menu__btn menu__btn--disabled",
                      attrs: { disabled: "" },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/icon/icon-new-home.png", alt: "icon" },
                      }),
                    ]
                  ),
              _vm._v(" "),
              _vm.isAuthenticated
                ? _c("div", { staticClass: "menu__user" }, [
                    _vm.loggedInUser
                      ? _c(
                          "p",
                          {
                            staticClass: "menu__userinfos",
                            on: {
                              click: function ($event) {
                                _vm.UI.userinfos = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.loggedInUser.firstName) +
                                " " +
                                _vm._s(_vm.loggedInUser.lastName) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "menu__logout",
                        on: {
                          click: function ($event) {
                            return _vm.logout()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("ctaLogout")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "nuxt-link",
                    {
                      staticClass: "menu__link",
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-login"
                            : "lotId-login",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.$t("ctaLogin")) + "\n    ")]
                  ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu__link",
                  on: {
                    click: function ($event) {
                      _vm.UI.language = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.actualLanguage.toUpperCase()) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("img", {
            staticClass: "theHeader__logo",
            attrs: { src: _vm.programLogoFiltered, alt: "logo" },
          }),
          _vm._v(" "),
          _c("h1", { staticClass: "theHeader__title" }, [
            _vm.programData.title
              ? _c("span", { staticClass: "theHeader__title--bold" }, [
                  _vm._v(_vm._s(_vm.programData.title) + " "),
                ])
              : _vm._e(),
            _vm._v(" - " + _vm._s(_vm.programData.programCity) + "\n  "),
          ]),
          _vm._v(" "),
          _vm.UI.userinfos
            ? _c("UserInfos", {
                attrs: { user: _vm.loggedInUser },
                on: {
                  clickOutside: function ($event) {
                    _vm.UI.userinfos = false
                  },
                  closeModal: function ($event) {
                    _vm.UI.userinfos = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.UI.language
            ? _c("Language", {
                on: {
                  clickOutside: function ($event) {
                    _vm.UI.language = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }