<template>
  <section v-if="programData" class="landing-header">
    <div class="landing-header__menu">
      <nuxt-link v-if="userRole === 'buyer' && buyerAccess" :to="{ name: clientParams ? 'client-lotId-login' : 'lotId-login', params: { lotId: lotIdParams } }">
        <button class="menu__btn">
          <img src="/icon/icon-new-home.png" alt="icon">
        </button>
      </nuxt-link>
      <div v-if="userRole === 'admin' && buyerAccess" @click="clientPageRedirection()">
        <button class="menu__btn">
          <img src="/icon/icon-new-home.png" alt="icon">
        </button>
      </div>
      <div v-if="isAuthenticated" class="menu__user">
        <p v-if="loggedInUser" class="menu__userinfos" @click="UI.userinfos = true">
          {{ loggedInUser.firstName }} {{ loggedInUser.lastName }}
        </p>
        <button class="menu__logout" @click="logout()">
          {{ $t('ctaLogout') }}
        </button>
      </div>
      <nuxt-link v-if="!isAuthenticated && buyerAccess" :to="{ name: clientParams ? 'client-lotId-login' : 'lotId-login', params: { lotId: lotIdParams } }" class="menu__login">
        {{ $t('ctaLogin') }}
      </nuxt-link>
      <p v-if="actualLanguage" class="menu__link language" @click="UI.language = true">
        {{ actualLanguage.toUpperCase() }}
      </p>
      <p v-else class="menu__link language" @click="UI.language = true">
        Lang
      </p>
    </div>
    <UserInfos v-if="UI.userinfos" :user="loggedInUser" @clickOutside="UI.userinfos = false" @closeModal="UI.userinfos = false" />
    <Language v-if="UI.language" @clickOutside="UI.language = false" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      UI: {
        userinfos: false,
        language: false,
      },
      buyerAccess: false,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
    }
  },
  computed: {
    ...mapGetters({
      programData: 'program/getProgramData',
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
      programNameForUrl: 'program/getProgramNameForUrl',
      userStatus: 'user/getUserStatus',
      userRole: 'user/getUserRole',
    }),
    actualLanguage () {
      return this.$i18n.locale
    },
  },
  mounted () {
    this.buyerAccess = this.programData.buyer_access
  },
  methods: {
    logout () {
      this.$resetApp(this.$route.name)
    },
    clientPageRedirection () {
      this.$router.push({ name: 'client-lotId-index', params: { lotId: this.lotIdParams } })
      setTimeout(() => {
        window.location.reload()
      }, 120)
    },
  },
}
</script>

<style lang="scss" scoped>
.landing-header {
  display: block;
  text-align: center;
  padding: 1.4rem;

  @media (min-width: $breakpoint-large) {
    height: $header-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;
    z-index: $z-index-navigation;

    @media (min-width: $breakpoint-large) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: $fs-main-title;
    margin: 1rem auto 0;

    @media (min-width: $breakpoint-large) {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 1.5rem;
      margin: auto;
    }

    &--bold {
      font-weight: 700;
      font-size: $fs-main-title;
    }
  }
}

.menu {
  &__login,
  &__link {
    font-size: $fs-link;
    font-weight: 500;
    text-align: left;
    margin-left: 1.6rem;
    transition: text-decoration 200ms, color 200ms;

    &:last-child {
      margin-left: 3.2rem;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: var(--second-option-color);
    }

    &--link-login-desactivated {
      &:last-child {
        margin-left: 0;
        margin-top: -1.8rem;
      }
    }
  }

  &__user {
    font-size: $fs-link;
    font-weight: 600;
    text-align: left;
    margin-left: 1.6rem;
  }

  &__logout {
    font-weight: 300;
  }

  &__link,
  &__logout,
  &__userinfos {
    transition: text-decoration 200ms, color 200ms;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: var(--second-option-color);
    }
  }

  &__btn {
    border-radius: 50%;
    background-color: $transparent-light-color;
    width: 4.25rem;
    height: 4.25rem;
    padding: 1.2rem;
    transition: box-shadow 200ms, background-color 200ms;

    img {
      width: 30px;
      height: auto;
    }

    &:hover {
      background-color: $transparent-medium-color;
    }
  }
}
</style>
