<template>
  <div class="modal-container" @click="closeOnClickOutside('modal-container')">
    <div v-if="UI.formIndex" class="info-index">
      <UiBtnClose @BtnCloseClicked="closeForm()" />
      <section class="info-form">
        <button class="cta info-form__btn" @click="openFormInfo()">
          {{ $t('contactIndex.btn.info') }}
        </button>
        <button class="cta info-form__btn" @click="openFormAppointment()">
          {{ $t('contactIndex.btn.appointment') }}
        </button>
      </section>
      <section class="demonstration" :style="{backgroundImage: 'url(' + programBackground + ')'}">
        <div class="demonstration__img">
          <img src="/images/immersive_white.png" alt="logo">
        </div>
        <button class="demonstration-btn" @click="openFormDemo()">
          {{ $t('contactIndex.btn.multiplayerDemo') }}
        </button>
        <header class="demonstration-header">
          <h2 class="demonstration-header__title">
            {{ $t('contactIndex.demonstration.title') }}<p class="demonstration-header__subtitle">
              {{ $t('contactIndex.demonstration.title2') }}
            </p>
          </h2>
          <div class="demonstration-header__description">
            <ol>
              <li>1. <span class="header-link">{{ $t('contactIndex.demonstration.footer.here') }}</span>{{ $t('contactIndex.demonstration.footer.step1') }} </li>
              <li>{{ $t('contactIndex.demonstration.footer.step2') }}</li>
              <li>{{ $t('contactIndex.demonstration.footer.step3') }}</li>
            </ol>
          </div>
        </header>
      </section>
    </div>
    <FormInternInfo v-if="UI.formInfo" @btnReturnInfoClicked="resetFormUi" @requestToCloseForm="closeForm()" />
    <FormInternAppointment v-if="UI.formAppointment" @btnReturnInfoClicked="resetFormUi" @requestToCloseForm="closeForm()" />
    <FormInternDemo v-if="UI.formDemo" @btnReturnInfoClicked="resetFormUi" @requestToCloseForm="closeForm()" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      ftp: process.env.FTP,
      UI: {
        formIndex: true,
        formInfo: false,
        formAppointment: false,
        formDemo: false,
      },
    }
  },
  computed: {
    programBackground () {
      return `${this.ftp}${this.$store.state.program.programData.background}`
    },
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList.value === containerClass) {
        this.$emit('closeFormRequest')
        document.body.classList.remove('modal-open')
      }
    },
    closeForm () {
      this.$emit('closeFormRequest')
      document.body.classList.remove('modal-open')
    },
    openFormInfo () {
      this.UI.formInfo = true
      this.UI.formIndex = false
      document.body.classList.add('modal-open')
    },
    openFormAppointment () {
      this.UI.formAppointment = true
      this.UI.formIndex = false
      document.body.classList.add('modal-open')
    },
    openFormDemo () {
      this.UI.formDemo = true
      this.UI.formIndex = false
      document.body.classList.add('modal-open')
    },
    resetFormUi () {
      this.UI.formDemo = false
      this.UI.formAppointment = false
      this.UI.formInfo = false
      this.UI.formIndex = true
    },
  },
}
</script>

<style lang="scss" scoped>
.info-index {
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: $breakpoint-small) {
    display: flex;
    margin: auto;
  }
  @media (min-width: $breakpoint-large) {
    width: 84%;
    height: 84%;
    margin: auto;
  }
}

.info-form,
.demonstration {
  height: 50%;
  width: auto;

  @media (min-width: $breakpoint-small) {
    height: 100%;
    min-height: 100%;
    width: 50%;
  }
}

.info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main-light-color;

  &__btn {
    width: 280px;
    margin: 1.2rem auto;
    animation: revealSlidelight 500ms;
  }
}

.demonstration-header,
.demonstration-btn {
  animation: revealSlidelight 500ms;
}

.demonstration {
  &-header__description {
    @media (max-width: $breakpoint-small) {
      display: none;
    }
  }

  background-color: #595858ec;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__img {
    max-height: 29%;
    text-align: center;
    object-fit: contain;
    margin: auto;
  }

  &-btn {
    background-color: var(--second-option-color);
    color: $main-light-color;
    font-size: $fs-small;
    font-weight: 700;
    border-radius: $border-radius;
    text-align: center;
    text-transform: uppercase;
    transition: background-color linear 100ms;
    margin: 2rem auto;
    padding: 0.5rem 1rem;

    @media (min-width: $breakpoint-small) {
      padding: 0.2rem;
      border: solid $main-light-color 0.5rem;
      width: 30vmin;
      height: 30vmin;
      min-height: 20vmin;
      min-width: 20vmin;
      border-radius: 50%;
    }

    &:hover {
      background-color: var(--main-option-color);
      color: $main-light-color;
    }
  }

  &-header {
    background-color: var(--main-option-color);
    width: 90%;
    margin: auto;
    text-align: center;

    &__title {
      color: $main-light-color;
      padding: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: $fs-small;
    }

    &__subtitle {
      font-size: $fs-small;
    }

    &__description {
      text-align: left;
      padding: 1rem;
      background-color: $main-light-color;

      li,
      a {
        font-size: $fs-small;
      }
    }
  }
}

.header-link {
  font-size: $fs-link;
  text-decoration: underline;
  cursor: pointer;
}
</style>
