<template>
  <button class="cta-active" :class="{'cta-active--hover' : stayHovered, 'cta-active--important': important, 'cta-active--mobile': isMobile}" @click="emitAction()">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'Cta',
  props: {
    text: {
      type: String,
      default: '',
    },
    stayHovered: {
      type: Boolean,
    },
    important: {
      type: Boolean,
    },
  },
  data () {
    return {
      isMobile: false,
    }
  },
  mounted () {
    this.isMobile = this.$globalValue
  },
  methods: {
    emitAction () {
      this.$emit('emittedAction')
    },
  },
}
</script>

<style lang="scss" scoped>
.cta-active {
  min-width: 8rem;
  padding: 0.65rem 1.7rem;
  margin: 0.5rem auto;
  background-color: var(--main-option-color);
  color: $main-light-color;
  border: 0;
  border-radius: $border-radius;
  font-size: $fs-btn;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: background-color linear 100ms;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: var(--second-option-color);
    }
  }

  &--important {
    background-color: $important-color;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: $important-color;
        filter: brightness(105%);
      }
    }
  }

  &:active {
    filter: brightness(92%);
  }

  &--hover {
    background-color: var(--second-option-color);
  }

  &--mobile {
    padding: 0.2rem 1rem;
  }
}
</style>
