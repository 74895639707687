var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          return _vm.closeOnClickOutside("modal-container")
        },
      },
    },
    [
      _c(
        "section",
        { staticClass: "user-infos-container" },
        [
          _c("UiBtnClose", {
            on: {
              BtnCloseClicked: function ($event) {
                return _vm.$emit("clickOutside")
              },
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "user-infos__title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("userInfos.title")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "user-infos__cards" }, [
            _c("div", { staticClass: "user-card" }, [
              _c("h3", { staticClass: "user-card__heading" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("userInfos.acquirer")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-card__content" }, [
                _c("p", { staticClass: "user-card__name" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.user.firstName) +
                      " " +
                      _vm._s(_vm.user.lastName) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user-card-contact" }, [
                  _c("p", { staticClass: "user-card-contact__item" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("userInfos.phone")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.user.phone
                    ? _c("p", { staticClass: "user-card-contact__value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.user.phone) +
                            "\n            "
                        ),
                      ])
                    : _c("p", { staticClass: "user-card-contact__value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("userInfos.noInfos")) +
                            "\n            "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user-card-contact" }, [
                  _c("p", { staticClass: "user-card-contact__item" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("userInfos.email")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.user.email
                    ? _c("p", { staticClass: "user-card-contact__value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.user.email) +
                            "\n            "
                        ),
                      ])
                    : _c("p", { staticClass: "user-card-contact__value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("userInfos.noInfos")) +
                            "\n            "
                        ),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.userStatus !== "prospect" && _vm.buyerAccess
            ? _c(
                "nuxt-link",
                {
                  staticClass: "user-infos__link",
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-messages"
                        : "lotId-messages",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeModal.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("userInfos.action")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }