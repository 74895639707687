import axios from 'axios'
import RouteMixin from '~/mixins/routeMixin'

export async function getStreamingLink ({ programId = '', flatId = '', token = '' }) {
  const link = await axios.get(
    RouteMixin.methods.$_getRouteForContext('streaming', [programId, flatId, token])
  )

  return { link: link.data.link }
}
