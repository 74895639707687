var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visit-link",
      style: { backgroundImage: "url(" + _vm.programBackground + ")" },
    },
    [
      _c("div", { staticClass: "circle-ext" }),
      _vm._v(" "),
      (_vm.userFlats && _vm.userFlats.length > 1) ||
      (_vm.userFlats && _vm.userFlats.length === 0)
        ? _c(
            "div",
            {
              staticClass: "circle-int",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.openStreamingLinkProgram()
                },
              },
            },
            [
              _c("p", { staticClass: "circle-int__text" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("cta.visitProgram")) + "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.userFlats && _vm.userFlats.length === 1
        ? _c(
            "div",
            {
              staticClass: "circle-int",
              on: {
                click: function ($event) {
                  return _vm.openStreamingLinkLot()
                },
              },
            },
            [
              _vm.getLotConfigurationsStatus({
                programData: _vm.program,
                lotVisitedInfos: _vm.$store.state.lot.lots[0],
              }).isOneConfigEnabled
                ? _c("p", { staticClass: "circle-int__text" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("cta.visitLotPioActivated")) +
                        "\n    "
                    ),
                  ])
                : _c("p", { staticClass: "circle-int__text" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("cta.visitLot")) + "\n    "
                    ),
                  ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }