<template>
  <section v-if="UI && UI.userLogin" class="loginContainer">
    <h2 class="loginContainer__title">
      {{ $t('login.title') }}
    </h2>
    <form class="loginForm" @submit.prevent="handleSubmit">
      <div class="loginForm__field">
        <label v-if="form.email" for="email">{{ $t('login.username') }}</label>
        <input
          id="email"
          v-model="form.email"
          type="email"
          name="email"
          autocomplete="on"
          :placeholder="$t('login.username')"
        >
      </div>
      <p v-if="!$v.form.email.required && $v.form.email.$dirty" class="error-alert">
        {{ $t('login.usernameRequired') }}
      </p>
      <p v-if="!$v.form.email.email && $v.form.email.$dirty" class="error-alert">
        {{ $t('login.usernameValidRequired') }}
      </p>
      <div class="loginForm__field">
        <label v-if="form.password" for="password">{{ $t('login.password') }}</label>
        <img
          v-if="form.password && passwordFieldType === 'text'"
          srcset="/icon/eyeOpened.svg"
          src="/icon/eyeOpened.png"
          alt="eye"
          class="togglePassword"
          @click="passwordToggle"
        >
        <img
          v-if="form.password && passwordFieldType === 'password'"
          srcset="/icon/eyeClosed.svg"
          src="/icon/eyeClosed.png"
          alt="eye"
          class="togglePassword"
          @click="passwordToggle"
        >
        <input
          id="password"
          v-model="form.password"
          :type="passwordFieldType"
          autocapitalize="off"
          autocomplete="off"
          :placeholder="$t('login.password')"
          class="password"
        >
      </div>
      <p v-if="!$v.form.password.required && $v.form.password.$dirty" class="error-alert">
        {{ $t('login.passwordRequired') }}
      </p>
      <div class="loginForm__footer">
        <p class="loginForm__recoveryLink" @click="recoveryLinkClicked">
          {{ $t('login.passwordRecovery') }}
        </p>
      </div>
      <button v-if="loginInProgress" class="cta--light cta--light--inProgress" type="submit" disabled>
        {{ $t('login.inProgress') }}
      </button>
      <button v-else class="loginForm__cta cta--light" type="submit">
        {{ $t('ctaLogin') }}
      </button>
      <p class="loginForm__subText">
        {{ $t('login.or') }}
      </p>
      <div class="cta--light cta--option" type="submit" @click="newAccountClicked">
        {{ $t('login.ctaSignin') }}
      </div>
      <p v-if="loginError" class="error-alert error-alert--center">
        {{ loginError }}
      </p>
      <p v-if="error.role" class="error-alert error-alert--center">
        {{ $t('login.incorrectRole') }}
      </p>
    </form>
  </section>
  <Loader v-else />
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import Cookies from 'js-cookie'
import routeMixin from '~/mixins/routeMixin'
import { getHeaders } from '~/plugins/headers'
import { getTokenInUrlParams } from '~/plugins/helpers'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path && this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      passwordFieldType: 'password',
      form: {
        email: '',
        password: '',
      },
      submitStatus: '',
      loginError: false,
      loginSucces: false,
      loginInProgress: false,
      error: {
        role: false,
      },
      UI: {
        userLogin: false,
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      programNameForUrl: 'program/getProgramNameForUrl',
      loggedInUser: 'loggedInUser',
      userStatus: 'user/getUserStatus',
      program: 'program/getProgramData',
    }),
  },
  async mounted () {
    const tokenParams = await getTokenInUrlParams()
    if (tokenParams.hasTokenParam && tokenParams.tokenValue) {
      Cookies.remove('temp-usr')
    }
    const tempUser = Cookies.get('temp-usr')
    if (tokenParams.tokenValue || tempUser) {
      this.loginProcess(tokenParams.tokenValue || tempUser)
    } else {
      this.$emit('identificationPending')
      this.UI.userLogin = true
    }
  },
  methods: {
    ...mapActions({
      loadSubprogram: 'lot/loadSubprogram',
      loadProjects: 'projects/loadProjects',
      loadProgramNews: 'news/loadProgramNews',
      loadMessages: 'messages/loadMessages',
    }),
    async tokenInUrlParams () {
      const url = await window.location.search
      const params = await new URLSearchParams(url)
      const token = await params.get('token')
      return token
    },
    async handleSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR'
        return
      }

      this.loginInProgress = true
      this.loginError = false
      const userResponse = await this.logUser()
      if (userResponse.status !== 200) {
        this.loginInProgress = false
        this.loginError = this.$t('login.error')
        if (userResponse.status === 401) {
          this.loginError = this.$t('login.loginError')
        }
        return
      }
      const token = userResponse.data.user
      this.loginProcess(token)
    },
    async logUser () {
      const data = {
        email: this.form.email,
        password: this.form.password,
      }
      const login = await this.login(data)
      return login
    },
    async loginProcess (token) {
      const userResponse = await this.loadUser(token, null, null)
      if (userResponse.status !== 200) {
        this.loginInProgress = false
        this.loginError = this.$t('login.error')
        this.UI.userLogin = true
        return
      }
      this.storeUserToken(token)
      const user = userResponse.data.user
      this.$store.commit('SET_USER_INFOS', user)

      const cache = await axios.get(routeMixin.methods.$_getRouteForContext('cache', [this.program.programId]))
      if (cache?.data?.program?.packcom) {
        this.$store.commit('packcom/SET_PACKCOM', cache.data.program.packcom)
      }

      if (user.accountType !== 'buyer' && this.clientParams && this.lotIdParams) {
        this.setUserStatus('acquirer')
        this.setUserRole('admin')
        const userFlats = []
        userFlats.push(this.lotIdParams)

        const subProgramResponse = await this.loadSubprogram({ lotsList: userFlats, programId: this.program.programId })
        let subProgramId
        if (subProgramResponse.status === 200) {
          const userFlatRequested = subProgramResponse.userLots.filter(flat => flat.flatName === this.lotIdParams)
          if (userFlatRequested.length > 0) {
            subProgramId = userFlatRequested[0].subProgramId
          }
          const userResponse = await this.loadUser(token, subProgramId, this.lotIdParams, true)
          if (userResponse.status !== 200) {
            this.$nuxt.error({ statusCode: 404, message: this.$t('error.userLot'), path: `/${this.promoterParams}/${this.programParams}/${this.clientParams}`, customPath: true })
            return
          }
          const user = userResponse.data.user
          this.$store.commit('SET_USER_INFOS', user)

          this.loadProjects({ headers: getHeaders(token), programId: this.program.programId, flatId: this.lotIdParams, subProgramId })
          if (this.program.commercials && this.program.commercials.length > 0) {
            this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.program.commercials[0].commercial_id, flatId: this.lotIdParams, subProgramId })
          }
          this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token), flatId: this.lotIdParams, subProgramId })
          const choiceSheetsResponse = await this.getChoiceSheetNotifications(this.lotIdParams, subProgramId)
          const choiceSheets = choiceSheetsResponse.data.choicesheets
          const choicesheetNotifications = this.getNotificationsLength(choiceSheets, user.flats)

          if (choiceSheetsResponse.status === 200) {
            this.storeChoiceSheets(choiceSheets)
            this.storeChoiceSheetsNotifications(choicesheetNotifications)
          }
          this.setNotificationsLoops(subProgramId, user.flats)
        }
      }

      if (user.accountType === 'buyer') {
        if (user.flats && user.flats.length === 0) {
          this.loadProjects({ headers: getHeaders(token), programId: this.program.programId })
        }

        if (user.flats && user.flats.length > 0) {
          this.setUserStatus('acquirer')
          await this.loadSubprogram({ lotsList: user.flats, programId: this.program.programId })

          this.loadProjects({ headers: getHeaders(token), programId: this.program.programId })
          this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          if (this.program.commercials && this.program.commercials.length > 0) {
            this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.program.commercials[0].commercial_id })
          }
          const choiceSheetsResponse = await this.getChoiceSheetNotifications()
          const choiceSheets = choiceSheetsResponse.data.choicesheets
          const choicesheetNotifications = this.getNotificationsLength(choiceSheets, user.flats)

          if (choiceSheetsResponse.status === 200) {
            this.storeChoiceSheets(choiceSheets)
            this.storeChoiceSheetsNotifications(choicesheetNotifications)
          }

          this.setNotificationsLoops(null, user.flats)
        }
      } else {
        this.setUserRole('admin')
        this.setUserStatus('acquirer')
      }
      if (user.accountType !== 'buyer' && this.clientParams && this.lotIdParams) {
        this.$router.push({ name: this.clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: this.lotIdParams } })
        return
      }

      if (this.program.buyer_access) {
        this.$store.commit('SET_CLIENT_SPACE_ACCESS_STATUS', true)
      }

      if (user.accountType === 'buyer') {
        if (!this.program.buyer_access) {
          this.$router.push({ name: 'lotId', params: { lotId: this.lotIdParams } })
          return
        }

        let routeBackup = Cookies.get('temp-route')
        if (routeBackup) {
          routeBackup = JSON.parse(routeBackup)
        }

        routeBackup && routeBackup.name
          ? this.$router.push({ name: routeBackup.name, params: { lotId: this.lotIdParams } })
          : this.$router.push({ name: this.clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: this.lotIdParams } })
      } else {
        this.lotIdParams ? this.$router.push(`/${this.$route.params.promoter}/${this.$route.params.program}/${this.lotIdParams}`) : this.$router.push(`/${this.$route.params.promoter}/${this.$route.params.program}`)
      }
    },
    storeUserToken (token) {
      const inOneHour = 1 / 24
      Cookies.set('temp-usr', token, { expires: inOneHour })
      this.$store.commit('SET_USER_TOKEN', token)
    },
    login (data) {
      // axios post can't access local files
      const action = localStorage.getItem('isOnline') === 'true' ? 'post' : 'get'
      return axios[action](routeMixin.methods.$_getRouteForContext('login', []), data)
        .then((response) => {
          return response
        })
        .catch((e) => {
          return e.response
        })
    },
    loadUser (token, subProgramId, flatId) {
      if (subProgramId && flatId) {
        return axios.get(`/moder_1_5/user?programId=${this.program.programId}&subProgramId=${subProgramId}&flatId=${flatId}&behalf=true`, { headers: getHeaders(token) })
          .then((response) => {
            return response
          })
          .catch((e) => {
            return e.response
          })
      } else {
        return axios.get(routeMixin.methods.$_getRouteForContext('user', [this.program.programId]), { headers: getHeaders(token) })
          .then((response) => {
            return response
          })
          .catch((e) => {
            return e.response
          })
      }
    },
    passwordToggle () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    recoveryLinkClicked () {
      this.$emit('recoveryRequest')
    },
    newAccountClicked () {
      this.$emit('signinRequest')
    },
    setUserStatus (status) {
      this.$store.commit('user/SET_USER_STATUS', status)
    },
    setUserRole (role) {
      this.$store.commit('user/SET_USER_ROLE', role)
      const inOneHour = 1 / 24
      Cookies.set('temp-usr-rl', role, { expires: inOneHour })
    },
    setNotificationsLoops (subProgramId, userFlats) {
      if (this.clientParams && this.lotIdParams && subProgramId) {
        const loop = setInterval(async () => {
          const token = await Cookies.get('temp-usr')

          let responseloadMessages
          if (this.program.commercials && this.program.commercials.length > 0) {
            responseloadMessages = await this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.program.commercials[0].commercial_id, flatId: this.lotIdParams, subProgramId })
          }
          const responseloadProgramNews = await this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token), flatId: this.lotIdParams, subProgramId })
          const responseChoiceSheets = await this.getChoiceSheetNotifications(this.lotIdParams, subProgramId)
          const choiceSheets = responseChoiceSheets.data.choicesheets
          const choicesheetNotifications = this.getNotificationsLength(choiceSheets, userFlats)

          if (responseloadMessages.status !== 200 || responseloadProgramNews.status !== 200 || responseChoiceSheets.status !== 200) {
            clearInterval(loop)
            return
          }
          this.storeChoiceSheetsNotifications(choicesheetNotifications)
          this.storeChoiceSheets(responseChoiceSheets.data.choicesheets)
        }, 120000)
      } else {
        const loop = setInterval(async () => {
          const token = await Cookies.get('temp-usr')

          const responseloadProgramNews = await this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          let responseloadMessages
          if (this.program.commercials && this.program.commercials.length > 0) {
            responseloadMessages = await this.loadMessages({ userId: this.$store.state.auth.user.userId, commercialId: this.program.commercials[0].commercial_id })
          }

          const responseChoiceSheets = await this.getChoiceSheetNotifications()
          const choiceSheets = responseChoiceSheets.data.choicesheets
          const choicesheetNotifications = this.getNotificationsLength(choiceSheets, userFlats)

          if (responseloadMessages.status !== 200 || responseloadProgramNews.status !== 200 || responseChoiceSheets.status !== 200) {
            clearInterval(loop)
            return
          }
          this.storeChoiceSheetsNotifications(choicesheetNotifications)
          this.storeChoiceSheets(responseChoiceSheets.data.choicesheets)
        }, 120000)
      }
    },
    async getChoiceSheetNotifications (flatId, subProgramId) {
      const token = await Cookies.get('temp-usr')

      if (subProgramId && flatId) {
        return axios.get(`/moder_1_5/choice-sheet-notifications?subProgramId=${subProgramId}&flatId=${flatId}&behalf=true`, { headers: getHeaders(token) })
          .then((response) => {
            return response
          })
          .catch((e) => {
            return e.response
          })
      } else {
        return axios.get('/moder_1_5/choice-sheet-notifications', { headers: getHeaders(token) })
          .then((response) => {
            return response
          })
          .catch((e) => {
            return e.response
          })
      }
    },
    storeChoiceSheets (choicesheets) {
      if (choicesheets && choicesheets.length > 0) {
        this.$store.commit('choiceSheet/SET_CHOICE_SHEETS', choicesheets)
      }
    },
    getNotificationsLength (choicesheets, userFlats) {
      if (!choicesheets) { return [] }

      let notifications = 0
      for (const flat of userFlats) {
        const lastFlatNotification = choicesheets.filter(sheet => sheet.flat_id === flat)
        if (lastFlatNotification[0] && lastFlatNotification[0].read === false) {
          notifications++
        }
      }
      return notifications
    },
    storeChoiceSheetsNotifications (notifications) {
      this.$store.commit('choiceSheet/SET_CHOICE_SHEETS_NOTIFICATIONS', notifications)
    },
  },
}
</script>
