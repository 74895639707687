export default ({ store }) => {
  if (process.client) {
    const customViewportCorrectionVariable = 'vh'
    let prevClientHeight
    const element = document.querySelector('html')
    const customVar = '--' + (customViewportCorrectionVariable || 'vh')
    function handleResize () {
      const clientHeight = element.clientHeight
      if (clientHeight === prevClientHeight) { return }
      requestAnimationFrame(function updateViewportHeight () {
        element.style.setProperty(customVar, (clientHeight * 0.01) + 'px')
        prevClientHeight = clientHeight
      })
    }
    handleResize()
    return handleResize
  }
}
