<template>
  <li class="news" :class="{'news--read' : news.read}" @click="$emit('newsClicked')">
    <div class="news__title" :class="{'news__notification' : !news.read}">
      {{ news.title }}
    </div>
    <div class="news__date">
      {{ news.created_at }}
    </div>
  </li>
</template>

<script>
export default {
  props: {
    news: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.news {
  color: $main-dark-color;
  font-weight: 600;
  margin: 0.2rem 0;
  padding: 0.4rem 0;
  border: $border-radius;
  border-top: solid $border-color 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &--read {
    color: $main-medium-color;
    font-weight: 500;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1rem;
  }

  &__date {
    font-size: 0.9rem;
  }

  &__notification::before {
    content: " ";
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--main-option-color);
  }
}
</style>
